import React, {FC, useEffect} from 'react';
import {useParams, useSearchParams} from 'react-router-dom';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import {useInitialiseRelevantCheckoutIdsMap} from '../hooks/queries/frontend';

/**
 * This wrapper ensures relevant Ids needed for the hosted checkout to function correctly
 * are placed into the react-query cache
 */
const RelevantCheckoutIdsContext: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const queryClient = useQueryClient();
  const {groupPaymentId, checkoutId} = useParams<Record<string, string>>();
  const [searchParams] = useSearchParams();
  const customerId = searchParams.get('cid') ?? undefined;
  const merchantId = searchParams.get('mid') ?? undefined;

  // !!Important - top-level queries must stay initialised and updated
  const {data: currentCustomerId} = useQuery(
    ['currentCustomerId'],
    () => customerId,
    {
      enabled: !!customerId,
    }
  );

  const {data: ids, isInitialLoading: isCheckoutIdsLoading} =
    useInitialiseRelevantCheckoutIdsMap({
      groupPaymentId,
      checkoutId,
      merchantId,
      customerId: currentCustomerId,
    });

  useEffect(() => {
    queryClient.setQueryData(['currentCustomerId'], () => customerId);
  }, [queryClient, customerId]);

  useEffect(() => {
    if (ids && currentCustomerId) {
      queryClient.setQueryData(['ids'], () => ({
        ...ids,
        customerId: currentCustomerId,
      }));
    }
  }, [queryClient, ids, currentCustomerId]);

  if (isCheckoutIdsLoading) {
    return null;
  }

  return <>{children}</>;
};

export default RelevantCheckoutIdsContext;
