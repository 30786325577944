/* eslint-disable @typescript-eslint/ban-ts-comment */
import {InputAdornment, Stack, TextField} from '@mui/material';
import React, {FC, useEffect, useRef, useState} from 'react';
import {
  FileCopy as FileCopyIcon,
  Reply as ReplyIcon,
} from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import {useMutation} from '@tanstack/react-query';
import {MAXIMUM_TIMEOUT_DELAY} from '@local/frontend/constants';
import {useNotification} from '@local/frontend/hooks/useNotification';
import {usePostHog} from 'posthog-js/react';
import CustomTooltip from '@local/frontend/components/atoms/CustomTooltip';
import {InviteProps} from './@types/InviteProps';
import SmartSocialButtons from './SmartSocialButtons';

interface ClipboardInviteProps extends InviteProps {
  text: string;
  timeout?: number;
  onCopy?: () => void;
  showSocialButtons?: boolean;
}

const ClipboardInvite: FC<React.PropsWithChildren<ClipboardInviteProps>> = ({
  timeout = 2000,
  text,
  linkToCopy,
  onCopy,
  showSocialButtons = true,
}) => {
  const posthog = usePostHog();
  const {open: openNotification} = useNotification();

  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  const textFieldRef = useRef<HTMLInputElement>(null);

  const handleCopyClick = () => {
    posthog.capture('Shared link', {via: 'window.clipboard'});
    if (textFieldRef.current) {
      textFieldRef.current.select();
      document.execCommand('copy');

      if (onCopy) {
        onCopy();
      }
    }

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    setIsCopied(true);
    const newTimeoutId = setTimeout(
      () => {
        setIsCopied(false);
      },
      Math.min(timeout, MAXIMUM_TIMEOUT_DELAY)
    );
    setTimeoutId(newTimeoutId);

    return () => {
      // safely clear newTimeoutId if component unmounts before next state update
      clearTimeout(newTimeoutId);
    };
  };

  useEffect(
    () => () => {
      if (timeoutId) {
        // clear the timeout on unmount
        clearTimeout(timeoutId);
      }
    },
    [timeoutId]
  );
  const canShare =
    navigator.canShare && navigator.canShare({text, url: linkToCopy});

  const selectAllText = () => {
    textFieldRef.current?.select();
  };

  const handleShareClick = async () => {
    posthog.capture('Attempted to share invite link');
    await navigator.share({
      text,
      url: linkToCopy,
    });
  };

  const shareMutation = useMutation(handleShareClick, {
    onError: () => {
      openNotification({
        message: 'Could not open share navigation',
        severity: 'error',
      });
    },
  });

  return (
    <Stack direction="column" spacing={1} useFlexGap>
      <TextField
        inputRef={textFieldRef}
        size="small"
        variant="outlined"
        value={linkToCopy}
        fullWidth
        onClick={selectAllText}
        hiddenLabel
        InputProps={{
          readOnly: true,
          sx: {
            fontSize: 14,
            color: 'primary.main',
            borderRadius: 0,
            py: 1,
            pr: 1,
            borderWidth: 2,
          },
          endAdornment: (
            <InputAdornment position="end">
              <Stack direction="row" spacing={1} alignItems="center">
                <CustomTooltip
                  enabled
                  arrow
                  title={isCopied ? 'Copied!' : 'Click to copy'}
                >
                  <IconButton
                    sx={{
                      backgroundColor: 'grey.200',
                      borderRadius: 1,
                    }}
                    size="small"
                    color={isCopied ? 'success' : undefined}
                    onClick={handleCopyClick}
                  >
                    {isCopied ? <CheckIcon /> : <FileCopyIcon />}
                  </IconButton>
                </CustomTooltip>
                {canShare && (
                  <CustomTooltip enabled arrow title="Click to share">
                    <IconButton
                      sx={{
                        backgroundColor: 'grey.200',
                        borderRadius: 1,
                      }}
                      size="small"
                      onClick={() => shareMutation.mutate()}
                    >
                      <ReplyIcon sx={{transform: 'rotateY(180deg)'}} />
                    </IconButton>
                  </CustomTooltip>
                )}
              </Stack>
            </InputAdornment>
          ),
        }}
      />
      {showSocialButtons && (
        <SmartSocialButtons link={linkToCopy} text={text} timeout={timeout} />
      )}
    </Stack>
  );
};

export default ClipboardInvite;
