import {Currency} from '@handsin/money';
import {Country} from '@handsin/api-node';
import type {PublicConnectDTO} from '@local/backend/@types/updated-api-types/merchants/PublicConnectDTO';
import axios from '../../config/backend';

export interface FindPaymentReadyGatewayParams {
  currency?: Currency;
  country?: Country;
}

// Get a list of connected payment services for a merchant
export const findPaymentReadyGateway = async (
  params: FindPaymentReadyGatewayParams
): Promise<PublicConnectDTO> => {
  const res = await axios.get('/connect/find', {params});
  return res.data;
};
