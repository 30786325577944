import {AxiosError} from 'axios';
import {QueryClient, UseMutationResult} from '@tanstack/react-query';
import type {Namespace, TFunction} from 'i18next';
import type {GroupPaymentRecord} from '@local/backend/@types/updated-api-types/group-payments/GroupPaymentRecord';
import type {DefaultResponseError} from '@local/backend/@types/updated-api-types/DefaultResponseError';
import {UseTRPCMutationResult} from '@trpc/react-query/shared';
import {TrpcError} from '@local/frontend/libs/trpc/trpc';
import {handleKick} from '../handle-kick';
import {
  UpdateGroupPaymentMutationParams,
  KickFromGroupPaymentMutationParams,
  RemoveFromGroupPaymentMutationParams,
} from '../../../../../hooks/mutations/@types';
import {Customer} from '../../../../../@types/updated-api-types/customer/Customer';
import {ConfirmationParams} from '../../@types/ConfirmationParams';

export const handleOnRemove = (
  queryClient: QueryClient,
  groupPayment: GroupPaymentRecord,
  updateGroupPaymentMutation: UseMutationResult<
    GroupPaymentRecord,
    AxiosError<DefaultResponseError>,
    UpdateGroupPaymentMutationParams,
    unknown
  >,
  kickFromGroupPaymentMutation: UseMutationResult<
    GroupPaymentRecord,
    AxiosError<DefaultResponseError>,
    KickFromGroupPaymentMutationParams,
    unknown
  >,
  removeFromGroupPaymentMutation: UseTRPCMutationResult<
    GroupPaymentRecord,
    TrpcError,
    RemoveFromGroupPaymentMutationParams,
    unknown
  >,
  customerToKick: Customer,
  setShowCancelGroupDialog: (showCancel: boolean) => void,
  setCancelConfirmationParams: (
    cancelConfirmationParams: ConfirmationParams
  ) => void,
  isDecreasingGroupSize: boolean | undefined,
  t: TFunction<Namespace>
): void => {
  const hasApprovedMoney =
    groupPayment.approvedMoney && groupPayment.approvedMoney.amount > 0;

  // check if the group has approved money and user is trying to decrease the group size. doesn't apply to fixed groups
  if (
    hasApprovedMoney &&
    isDecreasingGroupSize &&
    groupPayment.splitType !== 'FIXED_PRICE' // fixedPrice flow is unaffected by group size changes so no confirmation needed
  ) {
    // if so, show confirmation as this could cancel payments
    setCancelConfirmationParams({
      queryClient,
      updateGroupPaymentMutation,
      kickFromGroupPaymentMutation,
      removeFromGroupPaymentMutation,
      groupPayment,
      customerToKick,
      isDecreasingGroupSize,
    });
    setShowCancelGroupDialog(true);
  } else {
    // remove the user and possibly reduce the group size
    handleKick(
      queryClient,
      updateGroupPaymentMutation,
      kickFromGroupPaymentMutation,
      removeFromGroupPaymentMutation,
      groupPayment,
      customerToKick,
      isDecreasingGroupSize,
      t
    );
  }
};
