import React, {FC, useState} from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import GroupPaymentHelper from '@local/frontend/util/GroupPaymentHelper';
import NoticeAlert from '@local/frontend/components/atoms/NoticeAlert';
import type {GroupPaymentRecord} from '@local/backend/@types/updated-api-types/group-payments/GroupPaymentRecord';
import type MultiCardRecord from '@local/backend/@types/updated-api-types/multi-cards/MultiCardRecord';
import {SplitType} from '@local/frontend/@types/updated-api-types/group-payments/SplitType';
import {ModalName} from '@local/frontend/libs/modals/ModalName';
import LineItemsList from '../../LineItemsList';
import LineItemSummarySelector from './LineItemSummarySelector';
import useCustomer from '../../../../hooks/data/customers';
import {useCurrentCustomerId} from '../../../../hooks/data/ids';
import DynamicPricingAlert from '../../alerts/DynamicPricingAlert';
import ModalHeader from '../ModalHeader';

interface LineItemSummaryModalProps {
  groupPayment?: GroupPaymentRecord;
  multiCardPayment?: MultiCardRecord;
}

const LineItemSummaryModal: FC<
  React.PropsWithChildren<LineItemSummaryModalProps>
> = ({groupPayment, multiCardPayment}) => {
  const {t} = useTranslation(['modals', 'glossary']);
  const currentCustomerId = useCurrentCustomerId();

  const [selectedCustomerId, setSelectedCustomerId] = useState<
    string | undefined
  >(currentCustomerId);

  const selectedCustomer = useCustomer(selectedCustomerId);

  if (!groupPayment?.lineItems && !multiCardPayment?.lineItems) {
    return (
      <Stack
        direction="row"
        flex="auto"
        alignItems="center"
        justifyContent="center"
      >
        <Typography>
          {t('orderSummaryModal.info.noLineItems', {ns: 'modals'})}
        </Typography>
      </Stack>
    );
  }

  const selectedCustomersAllocatedLineItems =
    groupPayment &&
    groupPayment.splitType === SplitType.BY_ITEM &&
    selectedCustomerId
      ? new GroupPaymentHelper(groupPayment).getItemsAllocatedTo(
          selectedCustomerId
        )
      : undefined;

  const lineItemsToDisplay =
    selectedCustomersAllocatedLineItems ??
    groupPayment?.lineItems ??
    multiCardPayment?.lineItems ??
    [];

  return (
    <Stack spacing={2}>
      <ModalHeader
        title={t('orderSummaryModal.title', {ns: 'modals'})}
        modalName={ModalName.LINEITEM_SUMMARY}
      />

      {groupPayment?.splitType === SplitType.BY_ITEM && (
        <LineItemSummarySelector
          selectedCustomerId={selectedCustomerId}
          chosenCustomer={selectedCustomer}
          setSelectedCustomerId={setSelectedCustomerId}
          currentUserId={currentCustomerId}
        />
      )}

      {!selectedCustomer || lineItemsToDisplay.length > 0 ? (
        <LineItemsList lineItems={lineItemsToDisplay} />
      ) : (
        <NoticeAlert
          severity="info"
          message={t('orderSummaryModal.info.noItemAllocation', {
            ns: 'modals',
            customerName: selectedCustomer.firstName,
          })}
        />
      )}

      {groupPayment?.isDynamicPricingEnabled && (
        <DynamicPricingAlert
          customerId={currentCustomerId}
          groupPayment={groupPayment}
        />
      )}
    </Stack>
  );
};

export default LineItemSummaryModal;
