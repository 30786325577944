import React, {FC, useEffect} from 'react';
import {useChangeLanguage} from '../components/atoms/inputs/LanguageSelect/useChangeLanguage';
import {useInitializeCustomer} from '../hooks/queries/customers';
import useCustomerId from '../hooks/useCustomerId';

const LanguageWrapper: FC<React.PropsWithChildren<unknown>> = ({children}) => {
  const customerId = useCustomerId();
  const {changeLanguage} = useChangeLanguage();
  const {data: customer} = useInitializeCustomer(customerId);

  useEffect(() => {
    if (customer?.language) {
      changeLanguage(customer.language);
    }
  }, [customer]);

  return <>{children}</>;
};

export default LanguageWrapper;
