import {AxiosResponse} from 'axios';
import {PaymentRecord} from '@local/frontend/@types/updated-api-types/payments/PaymentRecord';
import axios from '../../config/backend';
import {CancelPaymentMutationParams} from '../../hooks/mutations/@types';

// Get a payment
export const getPayment = async (paymentId: string): Promise<PaymentRecord> => {
  const res: AxiosResponse<PaymentRecord> = await axios.get(
    `/payments/${paymentId}`
  );
  return res.data;
};

// Cancel a payment
export const cancelPayment = async ({
  paymentId,
  groupPaymentId,
  customerFirstName,
  participantFirstName,
}: CancelPaymentMutationParams): Promise<PaymentRecord> => {
  const res: AxiosResponse<PaymentRecord> = await axios.post(
    `/payments/${paymentId}/cancel`,
    {
      groupPaymentId,
      customerFirstName,
      participantFirstName,
    }
  );
  return res.data;
};
