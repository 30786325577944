import {Stack, Typography, IconButton} from '@mui/material';
import React, {FC} from 'react';
import MessageIcon from '@mui/icons-material/Message';
import {useTranslation} from 'react-i18next';
import {Country} from '@handsin/api-node';
import {Controller, useFieldArray, useForm} from 'react-hook-form';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {MoneyUtils} from '@handsin/money';
import {Remove} from '@mui/icons-material';
import type {GroupPaymentRecord} from '@local/backend/@types/updated-api-types/group-payments/GroupPaymentRecord';
import {phoneValidation} from '@local/frontend/validators/custom-validation';
import {calcGroupSize} from '@local/frontend/util/group-calculators';
import {sendSms} from '@local/frontend/libs/api/customer.actions';
import {useNotification} from '@local/frontend/hooks/useNotification';
import {formatSmsText} from '@local/frontend/util/stringFormatters';
import useMerchant from '@local/frontend/hooks/data/merchants';
import useCustomer from '@local/frontend/hooks/data/customers';
import {useGroupPaymentCustomerShares} from '@local/frontend/hooks/queries/group-payments';
import {useCurrentCustomerId} from '@local/frontend/hooks/data/ids';
import CustomTooltip from '@local/frontend/components/atoms/CustomTooltip';
import LoadingButton from '@local/frontend/components/atoms/buttons/LoadingButton';
import FormFieldError from '@local/frontend/components/forms/FormFieldError';
import PhoneInput from '@local/frontend/components/atoms/inputs/PhoneInput/PhoneInput';
import {InviteProps} from './@types/InviteProps';

interface SmsInviteProps extends InviteProps {
  groupPayment: GroupPaymentRecord;
}

const SmsInvite: FC<React.PropsWithChildren<SmsInviteProps>> = ({
  linkToCopy,
  groupPayment,
}) => {
  const {t} = useTranslation([
    'common',
    'validation',
    'glossary',
    'sms-invite',
  ]);
  const {open} = useNotification();
  const maxInvites = Math.max(
    1,
    calcGroupSize(groupPayment) - groupPayment.memberIds.length
  );

  const merchant = useMerchant();
  const currentCustomerId = useCurrentCustomerId();
  const currentCustomer = useCustomer(currentCustomerId);
  const owner = useCustomer(groupPayment.ownerId);
  const {data: customerSharePrices} = useGroupPaymentCustomerShares(
    groupPayment.id
  );

  const smsInviteSchema = Yup.object({
    phoneNumbers: Yup.array(
      Yup.object({
        id: Yup.string(),
        phoneNumber: phoneValidation(t).required(
          t('form.phoneNumber.required', {ns: 'common'})
        ),
      }).required(t('smsInviteSchema.required', {ns: 'validation'}))
    )
      .test(
        'unique',
        t('smsInviteSchema.duplicate', {ns: 'validation'}),
        function validatePhoneNumber(values) {
          if (!values) {
            return true; // Validation passes if the array is empty or undefined
          }

          const uniqueSet = new Set(values);
          if (uniqueSet.size !== values.length) {
            // eslint-disable-next-line react/no-this-in-sfc
            return this.createError({
              // eslint-disable-next-line react/no-this-in-sfc
              path: this.path,
              message: t('smsInviteSchema.duplicate', {ns: 'validation'}),
            });
          }

          return true;
        }
      )
      .required(),
  });

  const {
    formState: {isValid, isDirty, isSubmitted, isSubmitting, errors},
    ...formMethods
  } = useForm<Yup.InferType<typeof smsInviteSchema>>({
    mode: 'all',
    resolver: yupResolver(smsInviteSchema),
    defaultValues: {
      phoneNumbers: [{id: '', phoneNumber: ''}],
    },
  });

  const phoneNumbersFieldArray = useFieldArray({
    name: 'phoneNumbers',
    control: formMethods.control,
  });

  const onSubmit = async (
    formValues: Yup.InferType<typeof smsInviteSchema>
  ) => {
    if (!owner || !currentCustomerId) {
      return;
    }

    const sharePrice = customerSharePrices
      ? customerSharePrices[currentCustomerId]
      : undefined;

    if (owner && sharePrice) {
      await Promise.all(
        formValues.phoneNumbers.map(async (phoneNumberObject) => {
          try {
            await sendSms({
              ownerId: groupPayment.ownerId,
              smsDetails: {
                to: phoneNumberObject.phoneNumber,
                text: formatSmsText(
                  {
                    ownerFirstName: owner.firstName,
                    ownerLastName: owner.lastName,
                    merchantName: merchant?.name ?? '',
                    extraInfo: MoneyUtils.formatMoney(sharePrice),
                    linkToJoin: linkToCopy,
                  },
                  'invite',
                  t
                ),
              },
            });

            open({
              message: t('notifications.smsSent', {ns: 'sms-invite'}),
              severity: 'success',
            });
          } catch (err) {
            open({
              message: `${t('notifications.smsFail', {ns: 'sms-invite'})} ${
                phoneNumberObject.phoneNumber
              }`,
              severity: 'error',
            });
          }
        })
      );
    }
  };

  return (
    <form onSubmit={formMethods.handleSubmit(onSubmit)}>
      <Stack
        direction="column"
        spacing={2}
        flex="auto"
        maxHeight="300px"
        sx={{overflowY: 'auto', pt: 1}}
      >
        {phoneNumbersFieldArray.fields.map((phoneNumberObject, index) => (
          <Controller
            key={phoneNumberObject.id}
            name={`phoneNumbers.${index}.phoneNumber`}
            control={formMethods.control}
            render={({field, fieldState}) => (
              <Stack direction="column">
                <Stack direction="row" alignItems="center" spacing={1}>
                  <PhoneInput
                    {...field}
                    error={Boolean(fieldState.error)}
                    variant="outlined"
                    fullWidth
                    label={t('form.phoneNumber.label', {ns: 'common'})}
                    placeholder={t('form.phoneNumber.placeholder', {
                      ns: 'common',
                    })}
                    disabled={isSubmitting || isSubmitted}
                    onNumberChange={(value) => {
                      field.onChange(value);
                    }}
                    defaultCountry={
                      currentCustomer?.address?.country ?? Country.GB
                    }
                  />
                  {phoneNumbersFieldArray.fields.length > 1 && (
                    <CustomTooltip
                      enabled
                      arrow
                      title={t('remove', {ns: 'glossary'})}
                    >
                      <IconButton
                        disabled={isSubmitting || isSubmitted}
                        onClick={() => phoneNumbersFieldArray.remove(index)}
                      >
                        <Remove />
                      </IconButton>
                    </CustomTooltip>
                  )}
                </Stack>
                <FormFieldError errors={errors} name={field.name} />
              </Stack>
            )}
          />
        ))}
        {!isSubmitted && phoneNumbersFieldArray.fields.length < maxInvites && (
          <Typography
            fontSize="small"
            component="a"
            href="#"
            width="fit-content"
            onClick={() =>
              phoneNumbersFieldArray.append({id: undefined, phoneNumber: ''})
            }
          >
            {t('addNumber', {ns: 'sms-invite'})}
          </Typography>
        )}
      </Stack>
      <Stack direction="row" flex="auto" justifyContent="center" mt={2}>
        <CustomTooltip
          enabled={!isValid || !isDirty || isSubmitting}
          title={
            isSubmitting
              ? t('tooltip.sending', {
                  ns: 'sms-invite',
                  count: phoneNumbersFieldArray.fields.length,
                })
              : errors.phoneNumbers?.message
          }
        >
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{minWidth: '50%'}}
            disabled={!isValid || !isDirty || isSubmitting || isSubmitted}
            loading={isSubmitting}
          >
            {isSubmitted
              ? t('button.sent', {
                  ns: 'sms-invite',
                  count: phoneNumbersFieldArray.fields.length,
                })
              : t('button.send', {
                  ns: 'sms-invite',
                  count: phoneNumbersFieldArray.fields.length,
                })}
            {!isSubmitted && (
              <MessageIcon sx={{color: '#ffffff', marginLeft: '10px'}} />
            )}
          </LoadingButton>
        </CustomTooltip>
      </Stack>
    </form>
  );
};

export default SmsInvite;
