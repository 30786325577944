import Grid from '@mui/material/Grid';
import React, {FC} from 'react';
import type {GroupPaymentRecord} from '@local/backend/@types/updated-api-types/group-payments/GroupPaymentRecord';
import {ModalName} from '@local/frontend/libs/modals/ModalName';
import ShareSection from '../../../sections/groupCheckout/ShareSection';
import ModalHeader from './ModalHeader';

interface ShareModalProps {
  groupPaymentRecord: GroupPaymentRecord;
}

const ShareModal: FC<React.PropsWithChildren<ShareModalProps>> = ({
  groupPaymentRecord,
}) => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <ModalHeader modalName={ModalName.SHARE_INVITE} />
    </Grid>
    <Grid item xs={12}>
      <ShareSection groupPayment={groupPaymentRecord} />
    </Grid>
  </Grid>
);

export default ShareModal;
