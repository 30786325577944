import * as Yup from 'yup';
import type {Namespace, TFunction} from 'i18next';
import {nameValidation} from './custom-validation';

// used for when an owner adds a name to the group
const getCustomerFullNameSchema = (t: TFunction<Namespace>) =>
  Yup.object({
    firstName: nameValidation(
      t('customerFullNameSchema.firstName.field', {ns: 'validation'}),
      t
    ).required(
      t('customerFullNameSchema.firstName.required', {
        ns: 'validation',
      })
    ),
    lastName: nameValidation(
      t('customerFullNameSchema.lastName.field', {ns: 'validation'}),
      t
    ).notRequired(),
  });

export default getCustomerFullNameSchema;
