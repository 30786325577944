export default {
  ENV: process.env.REACT_APP_ENV ?? 'development',
  LOG_LEVEL: process.env.REACT_APP_LOG_LEVEL ?? 'info',
  ALLOWED_HOSTS: JSON.parse(
    process.env.REACT_APP_ALLOWED_HOSTS ?? '[]'
  ) as string[],
  REACT_APP_INTERCOM_APP_ID: process.env.REACT_APP_INTERCOM_APP_ID ?? '',
  REACT_APP_PCI_PROXY_MERCHANT_ID:
    process.env.REACT_APP_PCI_PROXY_MERCHANT_ID ?? '',
  SENTRY_CLIENT_DSN: process.env.SENTRY_CLIENT_DSN ?? '',
};
