import React, {FC} from 'react';

interface CountryFlagProps {
  /**
   * ISO 3166-1 alpha-2 country code
   */
  country: string;
  height?: string;
}

const CountryFlag: FC<React.PropsWithChildren<CountryFlagProps>> = ({
  country,
  height,
}) => (
  <img
    loading="lazy"
    width="20"
    height={height ?? '13'}
    src={`https://flagcdn.com/w20/${country.toLowerCase()}.png`}
    srcSet={`https://flagcdn.com/w40/${country.toLowerCase()}.png 2x`}
    alt={`Flag ${country.toUpperCase()}`}
  />
);

export default CountryFlag;
