import {v4 as uuidv4} from 'uuid';

export const setDynamicPricingLocalStorageKey = (
  groupPaymentId: string,
  customerId: string,
  memberRole: 'OWNER' | 'JOINER'
): void => {
  localStorage.setItem(
    `DP-${memberRole}-${groupPaymentId}-${customerId}`,
    uuidv4()
  );
};

export const getDynamicPricingLocalStorageKey = (
  groupPaymentId: string,
  customerId: string,
  memberRole: 'OWNER' | 'JOINER'
): string | null => {
  const idempKey = localStorage.getItem(
    `DP-${memberRole}-${groupPaymentId}-${customerId}`
  );
  return idempKey;
};
