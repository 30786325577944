import {useParams} from 'react-router-dom';
import type {GroupPaymentRecord} from '@local/backend/@types/updated-api-types/group-payments/GroupPaymentRecord';
import useGroupPayment from './data/group-payments';

const useCachedGroupPayment = (): GroupPaymentRecord => {
  const {groupPaymentId} = useParams<Record<string, string>>();
  const groupPaymentRecord = useGroupPayment(groupPaymentId);
  if (!groupPaymentRecord) {
    throw new Error(
      'useGroupPaymentCache can only be used from within the group payment provider'
    );
  }

  return groupPaymentRecord;
};

export default useCachedGroupPayment;
