import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import {useTheme} from '@mui/material/styles';
import {useFeatureFlagEnabled, usePostHog} from 'posthog-js/react';
import {featureFlags} from '@local/frontend/feature-flags';
import {ModalName} from '@local/frontend/libs/modals/ModalName';
import {useCustomModals} from '@local/frontend/libs/modals/useCustomModals';
import PosthogEvent from '@local/frontend/@types/posthog/posthog-events';
import GetHelp from './GetHelp';
import LocaleSelectButton from '../atoms/buttons/LocaleSelectButton';
import {ClickableText} from '../atoms/ClickableText';

interface GroupPaymentFooterProps {
  onGoBack?: () => void;
  displayHowItWorks?: boolean;
}

const GroupPaymentFooter: FC<
  React.PropsWithChildren<GroupPaymentFooterProps>
> = ({onGoBack, displayHowItWorks = false}) => {
  const theme = useTheme();
  const {t} = useTranslation(['common']);
  const {openModal} = useCustomModals();
  const posthog = usePostHog();
  const isSupportEnabled = useFeatureFlagEnabled(
    featureFlags.enableSupport.key
  );

  const handleOnHowItWorkClick = () => {
    const searchParams = new URLSearchParams(window.location.search);

    openModal(ModalName.HOW_IT_WORKS, {});
    posthog?.capture(
      PosthogEvent.HOW_IT_WORKS,
      {
        currentUrl: window.location.href,
        currentStep: searchParams.get('step'),
      },
      {
        $set_once: {
          firstStep: searchParams.get('step'),
        },
      }
    );
  };

  return (
    <Stack direction="row" alignItems="center" sx={{pb: 1}}>
      {onGoBack && (
        <Button
          variant="text"
          sx={{
            fontSize: 12,
            color: 'primary.dark',
            py: 0,
            '&:hover': {
              color: 'primary.main',
              backgroundColor: 'inherit',
            },
          }}
          onClick={onGoBack}
          startIcon={<KeyboardBackspaceIcon />}
        >
          {t('button.back', {ns: 'common'})}
        </Button>
      )}
      {displayHowItWorks && (
        <ClickableText
          variant="caption"
          sx={{fontWeight: 500}}
          onClick={handleOnHowItWorkClick}
        >
          {t('footer.howItWorks', {ns: 'common'})}
        </ClickableText>
      )}
      <Stack direction="row" alignItems="center" spacing={1} sx={{ml: 'auto'}}>
        {isSupportEnabled && (
          <GetHelp
            typographyProps={{
              sx: {
                textDecoration: 'none',
                color:
                  theme.palette.mode === 'light'
                    ? theme.palette.primary.dark
                    : theme.palette.primary.light,
                '&:hover': {
                  fontWeight: 500,
                  color: theme.palette.primary.main,
                  cursor: 'pointer',
                },
              },
            }}
          />
        )}

        <ClickableText
          variant="caption"
          href="https://www.handsin.com/terms-and-conditions"
          target="_blank"
          underline="none"
        >
          {t('footer.terms', {ns: 'common'})}
        </ClickableText>
        <LocaleSelectButton />
      </Stack>
    </Stack>
  );
};

export default GroupPaymentFooter;
