import Box from '@mui/material/Box';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import React, {FC} from 'react';

const ModalSpinner: FC<React.PropsWithChildren<CircularProgressProps>> = (
  props
) => (
  <Box
    sx={{
      minHeight: 200,
      minWidth: 200,
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <CircularProgress {...props} />
  </Box>
);

export default ModalSpinner;
