import {useMemo} from 'react';
import {GroupPaymentCustomerShareMap} from '../@types/updated-api-types/group-payments/GroupPaymentCustomerShareMap';

const useCustomerShareAmount = (
  customerId: string | undefined,
  groupPaymentCustomerSharesMap: GroupPaymentCustomerShareMap | undefined
) => {
  const newSharePrice = useMemo(() => {
    if (groupPaymentCustomerSharesMap) {
      const sharePrice =
        groupPaymentCustomerSharesMap && customerId
          ? groupPaymentCustomerSharesMap[customerId]
          : undefined;

      return sharePrice;
    }
    return undefined;
  }, [groupPaymentCustomerSharesMap, customerId]);

  return newSharePrice;
};

export default useCustomerShareAmount;
