import Alert, {AlertColor, AlertProps} from '@mui/material/Alert';
import React, {FC} from 'react';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';

interface NoticeAlertProps extends AlertProps {
  severity?: AlertColor;
  message?: string;
  fullWidth?: boolean;
  type?: 'tip';
}

const NoticeAlert: FC<React.PropsWithChildren<NoticeAlertProps>> = ({
  severity = 'info',
  type,
  message,
  fullWidth,
  children,
  ...props
}) => (
  <Alert
    severity={severity}
    icon={type === 'tip' ? <LightbulbOutlinedIcon /> : undefined}
    {...props}
    sx={{width: fullWidth ? '100%' : undefined, ...props.sx}}
  >
    {message || children}
  </Alert>
);

export default NoticeAlert;
