import type {Namespace, TFunction} from 'i18next';
import {MerchantDTO} from '@handsin/api-node';
import type {GroupPaymentRecord} from '@local/backend/@types/updated-api-types/group-payments/GroupPaymentRecord';
import {Customer} from '../../../../../@types/updated-api-types/customer/Customer';
import {sendSms} from '../../../../../libs/api/customer.actions';
import {
  formatSmsText,
  formatTimeLeft,
} from '../../../../../util/stringFormatters';

interface HandleSmsReminderParams {
  customer: Customer;
  customerBeingRemindedInviteUrl: string;
  groupPayment: GroupPaymentRecord;
  owner: Customer;
  merchant: MerchantDTO;
  t: TFunction<Namespace>;
}

export const handleSmsReminder = async ({
  customer,
  customerBeingRemindedInviteUrl,
  groupPayment,
  owner,
  merchant,
  t,
}: HandleSmsReminderParams) => {
  if (!customer.phoneNumber) {
    throw new Error('Failed to send sms: no phone number for this customer');
  }

  // get time left
  const secondsLeft = groupPayment.expirationDate
    ? (new Date(groupPayment.expirationDate).getTime() - new Date().getTime()) /
      1000
    : undefined;

  const formattedTimeLeft = secondsLeft
    ? formatTimeLeft(secondsLeft, t)
    : undefined;

  await sendSms({
    ownerId: groupPayment.ownerId,
    smsDetails: {
      to: customer.phoneNumber,
      text: formatSmsText(
        {
          ownerFirstName: owner.firstName,
          ownerLastName: owner.lastName,
          merchantName: merchant.name,
          extraInfo: formattedTimeLeft,
          linkToJoin: customerBeingRemindedInviteUrl,
        },
        'reminder',
        t
      ),
    },
  });
};
