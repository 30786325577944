import {Country, Language} from '@handsin/api-node';
import {LocaleMetaData} from './@types/LocaleMetaData';

export const getSupportedLanguages = (): Record<string, LocaleMetaData> => ({
  [Language.EN]: {
    locale: Language.EN,
    language: 'English',
    countryCode: Country.GB,
  },
  [Language.FR]: {
    locale: Language.FR,
    language: 'Français',
    countryCode: Country.FR,
  },
  [Language.ES]: {
    locale: Language.ES,
    language: 'Español',
    countryCode: Country.ES,
  },
  [Language.DE]: {
    locale: Language.DE,
    language: 'Deutsch',
    countryCode: Country.DE,
  },
  [Language.NL]: {
    locale: Language.NL,
    language: 'Nederlands',
    countryCode: Country.NL,
  },
  [Language.IT]: {
    locale: Language.IT,
    language: 'Italiano',
    countryCode: Country.IT,
  },
  [Language.PT]: {
    locale: Language.PT,
    language: 'Português',
    countryCode: Country.PT,
  },
});
