import React from 'react';
import {createFilterOptions} from '@mui/material/Autocomplete';
import {Language} from '@handsin/api-node';
import {
  Box,
  Button,
  InputAdornment,
  SelectProps,
  TextField,
  Typography,
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useMerchantStyle} from '@local/frontend/hooks/useMerchantStyle';
import CountryFlag from '../../CountryFlag';
import {getSupportedLanguages} from './getSupportedLanguages';
import SearchableMenu from '../SearchableMenu/SearchableMenu';

export type LanguageSelectProps = Omit<
  SelectProps,
  'value' | 'defaultValue' | 'onChange'
> & {
  value?: Language; // LanguageCode
  defaultValue?: Language; // LanguageCode
  onChange: (LanguageCode: Language) => void;
};

const LanguageSelect = React.forwardRef<HTMLDivElement, LanguageSelectProps>(
  ({value, defaultValue, onChange, ...props}, ref) => {
    const merchantStyles = useMerchantStyle();
    const supportedLanguagesMap = getSupportedLanguages();
    const defaultSelected = defaultValue
      ? supportedLanguagesMap[defaultValue]
      : undefined;
    const selected = value ? supportedLanguagesMap[value] : undefined;
    const [menuAnchorEl, setmenuAnchorEl] = React.useState<null | HTMLElement>(
      null
    );
    const handleToggleMenu = (event: React.MouseEvent<HTMLElement>) => {
      setmenuAnchorEl(menuAnchorEl ? null : event.currentTarget);
    };
    const {t} = useTranslation('country-language-popper');

    return (
      <>
        <TextField
          ref={ref}
          select
          label={props.label}
          onClick={handleToggleMenu}
          defaultValue={defaultValue}
          value={value}
          InputProps={{
            sx: {fontFamily: merchantStyles?.fontFamily},
            readOnly: true,
            startAdornment: selected ? (
              <InputAdornment position="start">
                <CountryFlag country={selected?.countryCode} />
              </InputAdornment>
            ) : undefined,
          }}
        >
          <option value={value}>{selected?.language}</option>
        </TextField>
        <SearchableMenu
          anchorEl={menuAnchorEl}
          open={!!menuAnchorEl}
          onClose={() => setmenuAnchorEl(null)}
          searcherProps={{
            noOptionsText: t('languageSelect.notFound', 'No language found'),
            multiple: false,
            freeSolo: false,
            disableClearable: true,
            autoComplete: true,
            defaultValue: defaultSelected,
            value: selected,
            options: Object.values(supportedLanguagesMap),
            getOptionLabel: (option) => option.language,
            isOptionEqualToValue: (a, b) => a.locale === b.locale,
            filterOptions: createFilterOptions({
              ignoreCase: true,
              trim: true,
              matchFrom: 'any',
              stringify: (option) => `${option.language} ${option.locale}`,
            }),
            onChange: (_e: React.SyntheticEvent, option) => {
              onChange(option.locale);
              setmenuAnchorEl(null);
            },
            renderOption: (renderProps, option) => (
              <Box component="li" {...renderProps}>
                <Button
                  variant="text"
                  color="inherit"
                  fullWidth
                  size="medium"
                  style={{
                    justifyContent: 'flex-start',
                    textAlign: 'left',
                    textTransform: 'none',
                    fontFamily: merchantStyles?.fontFamily,
                  }}
                  startIcon={<CountryFlag country={option.countryCode} />}
                >
                  <Typography sx={{fontFamily: 'inherit'}}>
                    {option.language}
                  </Typography>
                </Button>
              </Box>
            ),
          }}
        />
      </>
    );
  }
);

LanguageSelect.displayName = 'LanguageSelect';

export default LanguageSelect;
