import {MerchantDTO} from '@handsin/api-node';
import {QueryClient} from '@tanstack/react-query';
import type {Namespace, TFunction} from 'i18next';
import type {GroupPaymentRecord} from '@local/backend/@types/updated-api-types/group-payments/GroupPaymentRecord';
import {Customer} from '../../../../../@types/updated-api-types/customer/Customer';
import {handleSmsReminder} from './handle-sms-reminder';
import {handleEmailReminder} from './handle-email-reminder';

interface HandleRemindMemberParams {
  queryClient: QueryClient;
  groupPayment: GroupPaymentRecord;
  customer: Customer;
  customerBeingRemindedInviteUrl: string;
  remindMethod: 'EMAIL' | 'SMS';
  t: TFunction<Namespace>;
}

const showSuccessNotification = (
  queryClient: QueryClient,
  customerName: string,
  remindMethod: 'SMS' | 'EMAIL',
  t: TFunction<Namespace>
): void => {
  queryClient.setQueryData(['notificationAlert'], {
    isShowing: true,
    message: t('body.components.participantsCard.button.sendReminder.success', {
      customerName,
      remindMethod,
      ns: 'dashboard',
    }),
    severity: 'success',
    timeToShow: 5000,
  });
};

const showErrorNotification = (
  queryClient: QueryClient,
  customerName: string,
  remindMethod: 'SMS' | 'EMAIL',
  t: TFunction<Namespace>
): void => {
  queryClient.setQueryData(['notificationAlert'], {
    isShowing: true,
    message: t('body.components.participantsCard.button.sendReminder.failed', {
      customerName,
      remindMethod,
      ns: 'dashboard',
    }),
    severity: 'error',
    timeToShow: 5000,
  });
};

const trySendEmailReminder = async (
  queryClient: QueryClient,
  customer: Customer,
  groupPayment: GroupPaymentRecord,
  t: TFunction<Namespace>
): Promise<void> =>
  handleEmailReminder({
    customer,
    groupPayment,
  })
    .then(() => {
      showSuccessNotification(queryClient, customer.firstName, 'EMAIL', t);
    })
    .catch(() => {
      showErrorNotification(queryClient, customer.firstName, 'EMAIL', t);
    });

export const handleRemindMember = async ({
  queryClient,
  groupPayment,
  customer,
  customerBeingRemindedInviteUrl,
  remindMethod = 'SMS',
  t,
}: HandleRemindMemberParams): Promise<void> => {
  const merchant = queryClient.getQueryData<MerchantDTO>(['merchant']);
  const owner = queryClient.getQueryData<Customer>([
    'customer',
    groupPayment.ownerId,
  ]);

  if (!owner) {
    throw new Error('Could not send reminder, owner could not be found!');
  }

  if (!merchant) {
    throw new Error('failed to retrieve merchant data');
  }

  if (remindMethod === 'SMS') {
    await handleSmsReminder({
      customer,
      customerBeingRemindedInviteUrl,
      groupPayment,
      owner,
      merchant,
      t,
    })
      .then(() => {
        showSuccessNotification(queryClient, customer.firstName, 'SMS', t);
      })
      .catch(async () => {
        // failed to send sms
        showErrorNotification(queryClient, customer.firstName, 'SMS', t);

        if (customer.email) {
          // if SMS failed try remind by email
          await trySendEmailReminder(queryClient, customer, groupPayment, t);
        }
      });
  } else if (customer.email) {
    await trySendEmailReminder(queryClient, customer, groupPayment, t);
  }
};
