import React, {FC, Suspense} from 'react';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import {HelmetProvider} from 'react-helmet-async';
import {HandsInThemeProvider} from '@hands-in/theme';
import Routes from './Routes';
import {trpc, trpcRootQueryClient} from './libs/trpc/trpc';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // note: request retires happen via axios retry, if a query requires a retry, you can set this option local to the query
      retry: false,
    },
  },
});

export const App: FC<React.PropsWithChildren<unknown>> = () => (
  <Suspense fallback={null}>
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <trpc.Provider client={trpcRootQueryClient} queryClient={queryClient}>
          <HandsInThemeProvider>
            <Routes />
            <ReactQueryDevtools initialIsOpen={false} />
          </HandsInThemeProvider>
        </trpc.Provider>
      </QueryClientProvider>
    </HelmetProvider>
  </Suspense>
);
