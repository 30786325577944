import Grid from '@mui/material/Grid';
import React, {FC} from 'react';
import Divider from '@mui/material/Divider';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {useTranslation} from 'react-i18next';
import {useMutation} from '@tanstack/react-query';
import {Email} from '@handsin/api-node';
import {AxiosError} from 'axios';
import useCachedGroupPayment from '@local/frontend/hooks/useCachedGroupPayment';
import {
  formatFullName,
  maskEmailString,
} from '@local/frontend/util/stringFormatters';
import {
  ReInviteFormValues,
  getResendInviteValidationSchema,
} from '@local/frontend/validators/resend-invite-schema';
import {Customer} from '@local/frontend/@types/updated-api-types/customer/Customer';
import {SendEmailMutationParams} from '@local/frontend/hooks/mutations/@types/emails';
import {useNotification} from '@local/frontend/hooks/useNotification';
import {EmailCreationParams} from '@local/frontend/@types/updated-api-types/emails/EmailCreationParams';
import {ModalName} from '@local/frontend/libs/modals/ModalName';
import * as Yup from 'yup';
import type {DefaultResponseError} from '@local/backend/@types/updated-api-types/DefaultResponseError';
import {sendEmail} from '@local/frontend/libs/api/group-payments.actions';
import ReInviteForm from '../forms/ReInviteForm';
import GroupPaymentFooter from '../GroupPaymentFooter';
import ModalHeader from './ModalHeader';

interface RequestGroupInviteLinkModalProps {
  customer: Customer;
}

const RequestGroupInviteLinkModal: FC<
  React.PropsWithChildren<RequestGroupInviteLinkModalProps>
> = ({customer}) => {
  const {t} = useTranslation(['request-invite-link']);
  const {open: openNotification} = useNotification();
  const groupPayment = useCachedGroupPayment();

  const resendInviteValidationSchema = getResendInviteValidationSchema(t);

  const formMethods = useForm<
    Yup.InferType<typeof resendInviteValidationSchema>
  >({
    mode: 'all',
    resolver: yupResolver(resendInviteValidationSchema),
    defaultValues: {
      email: '',
    },
  });

  const {
    formState: {isValid},
  } = formMethods;

  const sendEmailMutation = useMutation<
    Email,
    AxiosError<DefaultResponseError>,
    SendEmailMutationParams
  >(sendEmail, {
    onSuccess: () => {
      openNotification({
        message: t('alerts.success.emailSent', {ns: 'request-invite-link'}),
        severity: 'success',
        timeToShow: 5000,
      });
    },
    onError: (error: AxiosError<DefaultResponseError>) => {
      openNotification({
        message:
          error.response?.data?.detail ??
          t('alerts.errors.failedToSend', {ns: 'request-invite-link'}),
        severity: 'error',
        timeToShow: 8000,
      });
    },
  });

  const handleResendInvite = ({email}: ReInviteFormValues) => {
    // check if the email provided does not match the email of the requesting customer
    if (
      customer.email &&
      email.toLowerCase() === customer.email.toLowerCase()
    ) {
      const emailDetails: EmailCreationParams = {
        to: [email],
        customerId: customer.id,
        subject: '', // gets replaced on API
        templateName: 'group-invite',
        templateVersion: 'request-access',
      };

      sendEmailMutation.mutate({
        emailDetails,
        groupPaymentId: groupPayment.id,
        ownerId: groupPayment.ownerId,
      });
    } else {
      openNotification({
        message: t('alerts.errors.invalidEmail', {
          ns: 'request-invite-link',
          customerName: customer.firstName,
          maskedEmail: customer.email ? maskEmailString(customer.email) : '',
        }),
        severity: 'error',
        timeToShow: 5000,
      });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ModalHeader
          modalName={ModalName.REQUEST_GROUP_INVITE_MODAL}
          title={t('title', {ns: 'request-invite-link'})}
          subtitle={t('subtitle', {
            ns: 'request-invite-link',
            customerName: formatFullName(customer.firstName, customer.lastName),
          })}
          subtitleTypographyProps={{fontWeight: 400, fontSize: 14}}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <FormProvider {...formMethods}>
          <ReInviteForm
            onSubmit={handleResendInvite}
            loading={sendEmailMutation.isLoading}
            disabled={!isValid || sendEmailMutation.isLoading}
          />
        </FormProvider>
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <GroupPaymentFooter />
      </Grid>
    </Grid>
  );
};

export default RequestGroupInviteLinkModal;
