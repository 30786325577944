import React, {FC, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import dayjs from '../config/dayjsConfig';
import useCachedCheckout from '../hooks/useCachedCheckout';
import {useDateTime} from '../hooks/useDateTime';
import {handleExternalRedirect} from '../util';
import {CheckoutStatus} from '../@types/updated-api-types/checkouts/CheckoutStatus';

const CheckoutRedirectWrapper: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const checkout = useCachedCheckout();
  const navigate = useNavigate();
  const {currentDateTime} = useDateTime();

  const isTimeExpired =
    dayjs(checkout.expirationDate).diff(currentDateTime, 'seconds', true) <= 0;

  useEffect(() => {
    const queryOptions = {cid: checkout.id};
    // check whether to redirect them to a url specified in the checkoutPageOptions
    if (
      checkout.checkoutPageOptions?.approvedUrl &&
      checkout.status === 'APPROVED'
    ) {
      handleExternalRedirect(
        checkout.checkoutPageOptions.approvedUrl,
        queryOptions
      );
    } else if (
      checkout.checkoutPageOptions?.successUrl &&
      checkout.status === 'COMPLETED'
    ) {
      handleExternalRedirect(
        checkout.checkoutPageOptions.successUrl,
        queryOptions
      );
    } else if (
      checkout.checkoutPageOptions?.cancelUrl &&
      checkout.status === 'CANCELLED'
    ) {
      handleExternalRedirect(
        checkout.checkoutPageOptions.cancelUrl,
        queryOptions
      );
    } else if (checkout.checkoutPageOptions?.expireUrl && isTimeExpired) {
      handleExternalRedirect(
        checkout.checkoutPageOptions.expireUrl,
        queryOptions
      );
    } else if (
      [
        CheckoutStatus.CANCELLED,
        CheckoutStatus.EXPIRED,
        CheckoutStatus.COMPLETED,
      ].includes(checkout.status) ||
      isTimeExpired
    ) {
      // if no checkoutPageOption urls were provided, redirect to checkout terminal page if necessary
      navigate(`/c/${checkout.id}/t?mid=${checkout.merchantId}`, {
        replace: true,
      });
    }
  }, [checkout, isTimeExpired]);

  return <>{children}</>;
};

export default CheckoutRedirectWrapper;
