import React, {FC} from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {useMutation, useQuery} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {Email} from '@handsin/api-node';
import {useTranslation} from 'react-i18next';
import type {GroupPaymentRecord} from '@local/backend/@types/updated-api-types/group-payments/GroupPaymentRecord';
import {useNotification} from '@local/frontend/hooks/useNotification';
import * as Yup from 'yup';
import type {DefaultResponseError} from '@local/backend/@types/updated-api-types/DefaultResponseError';
import {sendEmail} from '@local/frontend/libs/api/group-payments.actions';
import {SendEmailMutationParams} from '../../../../hooks/mutations/@types/emails';
import {
  ReInviteFormValues,
  getResendInviteValidationSchema,
} from '../../../../validators/resend-invite-schema';
import {getBatchCustomers} from '../../../../libs/api/customer.actions';
import Loading from '../../../atoms/Loading';
import NoticeAlert from '../../../atoms/NoticeAlert';
import {EmailCreationParams} from '../../../../@types/updated-api-types/emails/EmailCreationParams';
import ReInviteForm from '../../forms/ReInviteForm';

interface ReInviteGroupMemberProps {
  groupPayment: GroupPaymentRecord;
}

const ReInviteGroupMember: FC<
  React.PropsWithChildren<ReInviteGroupMemberProps>
> = ({groupPayment}) => {
  const {t} = useTranslation(['modals']);
  const {open: openNotification} = useNotification();

  const sendEmailMutation = useMutation<
    Email,
    AxiosError<DefaultResponseError>,
    SendEmailMutationParams
  >(sendEmail, {
    onSuccess: () => {
      openNotification({
        message: t(
          'joinGroupModal.components.reInviteGroupMember.alerts.emailSent',
          {ns: 'modals'}
        ),
        severity: 'success',
        timeToShow: 5000,
      });
    },
    onError: (error: AxiosError<DefaultResponseError>) => {
      openNotification({
        message:
          error.response?.data?.detail ??
          t(
            'joinGroupModal.components.reInviteGroupMember.errors.failedToSend',
            {ns: 'modals'}
          ),
        severity: 'error',
        timeToShow: 8000,
      });
    },
  });

  const {data: allCustomersInGroup, isLoading: isCustomersLoading} = useQuery(
    ['allGroupCustomers'],
    () => getBatchCustomers(groupPayment.memberIds)
  );

  const resendInviteValidationSchema = getResendInviteValidationSchema(t);

  const formMethods = useForm<
    Yup.InferType<typeof resendInviteValidationSchema>
  >({
    mode: 'all',
    resolver: yupResolver(resendInviteValidationSchema),
    defaultValues: {
      email: '',
    },
  });

  const {
    formState: {isValid},
  } = formMethods;

  if (isCustomersLoading) {
    return <Loading />;
  }

  if (!allCustomersInGroup) {
    return (
      <NoticeAlert
        message={t(
          'joinGroupModal.components.reInviteGroupMember.errors.noCustomersInGroup',
          {ns: 'modals'}
        )}
      />
    );
  }

  const handleResendInvite = ({email}: ReInviteFormValues) => {
    // find customer in the group via provided email
    const requestedCustomer = allCustomersInGroup.find(
      (customer) => customer.email === email
    );

    // check if the email does not exist in the group
    if (!requestedCustomer) {
      openNotification({
        message: t(
          'joinGroupModal.components.reInviteGroupMember.errors.noEmail',
          {ns: 'modals'}
        ),
        severity: 'error',
        timeToShow: 8000,
      });
      return;
    }

    const emailDetails: EmailCreationParams = {
      to: [email],
      customerId: requestedCustomer.id,
      subject: '', // gets replaced on API
      templateName: 'group-invite',
      templateVersion: 'request-access',
    };

    sendEmailMutation.mutate({
      emailDetails,
      groupPaymentId: groupPayment.id,
      ownerId: groupPayment.ownerId,
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <Typography color="primary" variant="subtitle2" sx={{fontSize: 16}}>
              {t('joinGroupModal.components.reInviteGroupMember.title', {
                ns: 'modals',
              })}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{fontSize: 12, fontWeight: 500}}>
              {t(
                'joinGroupModal.components.reInviteGroupMember.body.checkEmail',
                {
                  ns: 'modals',
                }
              )}
              <br />
              <Typography component="span" sx={{fontSize: 12, fontWeight: 300}}>
                {t(
                  'joinGroupModal.components.reInviteGroupMember.body.checkSpam',
                  {ns: 'modals'}
                )}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <Typography color="primary" variant="subtitle2" sx={{fontSize: 16}}>
              {t('joinGroupModal.components.reInviteGroupMember.body.noLink', {
                ns: 'modals',
              })}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{fontSize: 12, fontWeight: 500}}>
              {t(
                'joinGroupModal.components.reInviteGroupMember.body.enterEmail',
                {
                  ns: 'modals',
                }
              )}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <FormProvider {...formMethods}>
          <ReInviteForm
            onSubmit={handleResendInvite}
            disabled={!isValid || sendEmailMutation.isLoading}
            loading={sendEmailMutation.isLoading}
          />
        </FormProvider>
      </Grid>
    </Grid>
  );
};

export default ReInviteGroupMember;
