import {AxiosError} from 'axios';
import {
  QueryKey,
  useQueries,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import {PaymentRecord} from '@local/frontend/@types/updated-api-types/payments/PaymentRecord';
import type {DefaultResponseError} from '@local/backend/@types/updated-api-types/DefaultResponseError';
import {getPayment} from '../../../libs/api/payment.actions';

export const useInitialisePayment = (
  paymentId: string | undefined
): UseQueryResult<PaymentRecord | undefined, unknown> =>
  useQuery<PaymentRecord | undefined, AxiosError<DefaultResponseError>>(
    ['payment', paymentId],
    () => (paymentId ? getPayment(paymentId) : undefined),
    {
      enabled: !!paymentId,
    }
  );

export const useInitialisePayments = (
  paymentIds: string[]
): UseQueryResult<
  PaymentRecord | undefined,
  AxiosError<DefaultResponseError>
>[] =>
  useQueries<
    UseQueryOptions<
      PaymentRecord | undefined,
      AxiosError<DefaultResponseError>,
      unknown,
      QueryKey
    >[]
  >({
    queries: paymentIds.map((paymentId: string) => ({
      queryKey: ['payment', paymentId],
      queryFn: () => (paymentId ? getPayment(paymentId) : undefined),
      enabled: !!paymentId,
    })),
  });
