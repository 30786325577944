import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import React, {FC} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {ReInviteFormValues} from '@local/frontend/validators/resend-invite-schema';
import LoadingButton from '../../atoms/buttons/LoadingButton';

interface ReInviteFormProps {
  onSubmit: (submitProps: ReInviteFormValues) => void;
  disabled?: boolean;
  loading?: boolean;
}

const ReInviteForm: FC<React.PropsWithChildren<ReInviteFormProps>> = ({
  onSubmit,
  loading = false,
  disabled = false,
}) => {
  const {t} = useTranslation(['modals', 'common']);
  const formMethods = useFormContext<ReInviteFormValues>();

  return (
    <form onSubmit={formMethods.handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            name="email"
            control={formMethods.control}
            render={({field}) => (
              <TextField
                {...field}
                fullWidth
                label={t('form.email.label', {ns: 'common'})}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            type="submit"
            variant="contained"
            fullWidth
            disabled={disabled}
            loading={loading}
          >
            {t(
              'joinGroupModal.components.reInviteGroupMember.form.button.submit',
              {ns: 'modals'}
            )}
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default ReInviteForm;
