import {v4 as uuidv4} from 'uuid';

export const getIdempKey = (
  groupPaymentId: string,
  customerId: string
): string | undefined =>
  localStorage.getItem(`${groupPaymentId}-${customerId}`) ?? undefined;

export const createOrResetIdempKey = (
  groupPaymentId: string,
  customerId: string
): string => {
  const idempKey = uuidv4();
  localStorage.setItem(`${groupPaymentId}-${customerId}`, idempKey);
  return idempKey;
};

export const removeIdempKey = (
  groupPaymentId: string,
  customerId: string
): void => {
  localStorage.removeItem(`${groupPaymentId}-${customerId}`);
};

// used to prevent sending duplicate create groups payment requests
export const getCheckoutIdempKey = (checkoutId: string): string | undefined =>
  localStorage.getItem(`idempKey${checkoutId}`) ?? undefined;

export const createOrResetCheckoutIdempKey = (checkoutId: string): string => {
  const idempKey = uuidv4();
  localStorage.setItem(`idempKey${checkoutId}`, idempKey);
  return idempKey;
};
