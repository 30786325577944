import {QueryClient} from '@tanstack/react-query';
import {RelevantCheckoutIdsMap} from '../hooks/queries/@types';
import {getCustomerById} from '../libs/api/customer.actions';

const prefetchQueriesForSbiRoute = async (
  queryClient: QueryClient
): Promise<void> => {
  const ids = queryClient.getQueryData<RelevantCheckoutIdsMap>(['ids']);

  if (ids && ids.customerId) {
    await queryClient.prefetchQuery(['customer', ids.customerId], () =>
      ids.customerId ? getCustomerById(ids.customerId) : undefined
    );
  }
};

export default prefetchQueriesForSbiRoute;
