import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, {FC} from 'react';
import LockIcon from '@mui/icons-material/Lock';
import {yupResolver} from '@hookform/resolvers/yup';
import {Controller, useForm} from 'react-hook-form';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Skeleton from '@mui/material/Skeleton';
import {useTranslation} from 'react-i18next';
import {useNotification} from '@local/frontend/hooks/useNotification';
import type {GroupPaymentRecord} from '@local/backend/@types/updated-api-types/group-payments/GroupPaymentRecord';
import {useCustomModals} from '@local/frontend/libs/modals/useCustomModals';
import {ModalName} from '@local/frontend/libs/modals/ModalName';
import type {Money} from '@handsin/money';
import * as Yup from 'yup';
import dynamicPricingSchema from '../../../../../validators/dynamicPricingSchema';
import NoticeAlert from '../../../../atoms/NoticeAlert';
import {
  getDynamicPricingLocalStorageKey,
  setDynamicPricingLocalStorageKey,
} from '../../../../../util/dynamic-pricing-key-handlers';
import {getDynamicPricingButtonText} from './helpers';
import useCustomerShareAmount from '../../../../../hooks/useCustomerShareAmount';
import {useGroupPaymentCustomerShares} from '../../../../../hooks/queries/group-payments';
import ModalHeader from '../../ModalHeader';

interface DynamicPricingConfirmationModalProps {
  merchantName: string | undefined;
  sharePrice: Money | undefined;
  groupPayment: GroupPaymentRecord | undefined;
  customerId: string | undefined;
}

const DynamicPricingConfirmationModal: FC<
  React.PropsWithChildren<DynamicPricingConfirmationModalProps>
> = ({merchantName, groupPayment, customerId}) => {
  const {t} = useTranslation(['modals']);
  const {open: openNotification} = useNotification();
  const {openModal, closeModal} = useCustomModals();

  const {
    data: groupPaymentCustomerSharesMap,
    isLoading: isGroupCustomerSharesLoading,
  } = useGroupPaymentCustomerShares(groupPayment?.id);

  const formMethods = useForm<Yup.InferType<typeof dynamicPricingSchema>>({
    mode: 'all',
    resolver: yupResolver(dynamicPricingSchema),
    defaultValues: {
      enableDynamicPricing: groupPayment?.isDynamicPricingEnabled ?? true,
    },
  });

  const {enableDynamicPricing: isDynamicPricingCheckboxTicked} =
    formMethods.watch();

  const handleEnableDynamicPricingButtonClick = () => {
    if (!groupPayment) {
      openNotification({
        message: t('dynamicPricingConfirmationModal.errors.noGroup', {
          ns: 'modals',
        }),
        severity: 'error',
      });
      return;
    }

    if (!customerId) {
      openNotification({
        message: t('dynamicPricingConfirmationModal.errors.noCustomerId', {
          ns: 'modals',
        }),
        severity: 'error',
      });
      return;
    }

    // check the customerID requesting this is not the ownerID of the group
    if (customerId !== groupPayment.ownerId) {
      openNotification({
        message: t('dynamicPricingConfirmationModal.errors.notOwner', {
          ns: 'modals',
        }),
        severity: 'error',
      });
      return;
    }

    const hasOwnerSeenDynamicPricing = !!getDynamicPricingLocalStorageKey(
      groupPayment.id,
      customerId,
      'OWNER'
    );

    // check if the owner does not have a dp key
    if (!hasOwnerSeenDynamicPricing) {
      // if they do not have one, then set a dp key
      setDynamicPricingLocalStorageKey(groupPayment.id, customerId, 'OWNER');
    }

    closeModal(ModalName.DYNAMIC_PRICING_OWNER_INFORMATION);
  };

  const handleDynamicPricingOptInOrOutSubmit = () => {
    // check on submission if they chose to enable dynamic pricing
    if (isDynamicPricingCheckboxTicked) {
      handleEnableDynamicPricingButtonClick();
    } else {
      openModal(ModalName.DYNAMIC_PRICING_OWNER_OPT_OUT, {
        groupPayment,
        customerId,
      });
    }
  };

  const customerShareAmount = useCustomerShareAmount(
    customerId,
    groupPaymentCustomerSharesMap
  );

  const dynamicPricingButtonText = getDynamicPricingButtonText({
    groupPayment,
    sharePrice: customerShareAmount,
    isDynamicPricingCheckboxTicked,
    t,
  });

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <ModalHeader
          title={t('dynamicPricingConfirmationModal.title', {ns: 'modals'})}
          modalName={ModalName.DYNAMIC_PRICING_OWNER_INFORMATION}
          canClose={false}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1" sx={{fontSize: '14px'}}>
          <Typography component="span" sx={{fontSize: '14px', fontWeight: 600}}>
            {merchantName ??
              t('dynamicPricingConfirmationModal.body.merchant', {
                ns: 'modals',
              })}
          </Typography>{' '}
          {t('dynamicPricingConfirmationModal.body.header', {ns: 'modals'})}{' '}
          <Typography component="span" sx={{fontSize: '14px', fontWeight: 600}}>
            {t('dynamicPricingConfirmationModal.body.increaseInPrice', {
              ns: 'modals',
            })}
          </Typography>
        </Typography>
        <br />
        <Typography variant="subtitle1" sx={{fontSize: '14px'}}>
          {t('dynamicPricingConfirmationModal.body.content', {ns: 'modals'})}
        </Typography>
      </Grid>
      <form
        onSubmit={formMethods.handleSubmit(
          handleDynamicPricingOptInOrOutSubmit
        )}
        style={{width: '100%'}}
      >
        <Grid container justifyContent="center">
          <Controller
            name="enableDynamicPricing"
            control={formMethods.control}
            render={({field}) => (
              <FormControlLabel
                label={t(
                  'dynamicPricingConfirmationModal.form.enableDynamicPricing',
                  {ns: 'modals'}
                )}
                control={
                  <Checkbox
                    {...field}
                    checked={field.value}
                    value={isDynamicPricingCheckboxTicked}
                  />
                }
              />
            )}
          />
        </Grid>

        {!isDynamicPricingCheckboxTicked && (
          <Grid container justifyContent="center" sx={{mt: 1, mb: 2}}>
            <NoticeAlert
              message={t(
                'dynamicPricingConfirmationModal.form.warnings.notify',
                {ns: 'modals'}
              )}
              severity="warning"
            />
          </Grid>
        )}

        <Grid container justifyContent="center">
          <Grid item xs={7}>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              fullWidth
              sx={{flexWrap: 'wrap', alignSelf: 'center', height: '48px'}}
              disabled={isGroupCustomerSharesLoading}
              startIcon={
                isDynamicPricingCheckboxTicked ? (
                  <LockIcon />
                ) : (
                  <WarningAmberIcon color="warning" />
                )
              }
            >
              <Typography variant="button" sx={{fontSize: '12px'}}>
                {isGroupCustomerSharesLoading ? (
                  <Skeleton />
                ) : (
                  dynamicPricingButtonText
                )}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default DynamicPricingConfirmationModal;
