import {useQueryClient} from '@tanstack/react-query';
import {RelevantCheckoutIdsMap} from '../queries/@types';

export const useCurrentCustomerId = (): string | undefined => {
  const queryClient = useQueryClient();
  const currentCustomerId = queryClient.getQueryData<string | undefined>([
    'currentCustomerId',
  ]);
  return currentCustomerId;
};

export const useRelevantCheckoutIdsMap = () => {
  const queryClient = useQueryClient();
  const relevantCheckoutIdsMap =
    queryClient.getQueryData<RelevantCheckoutIdsMap>(['ids']);
  return relevantCheckoutIdsMap;
};
