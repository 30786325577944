import {Stack, Typography, IconButton, TextField} from '@mui/material';
import React, {FC} from 'react';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next';
import {Controller, useFieldArray, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {Remove} from '@mui/icons-material';
import type {GroupPaymentRecord} from '@local/backend/@types/updated-api-types/group-payments/GroupPaymentRecord';
import {emailValidation} from '@local/frontend/validators/custom-validation';
import {calcGroupSize} from '@local/frontend/util/group-calculators';
import {useNotification} from '@local/frontend/hooks/useNotification';
import useCustomer from '@local/frontend/hooks/data/customers';
import {useGroupPaymentCustomerShares} from '@local/frontend/hooks/queries/group-payments';
import {useCurrentCustomerId} from '@local/frontend/hooks/data/ids';
import CustomTooltip from '@local/frontend/components/atoms/CustomTooltip';
import LoadingButton from '@local/frontend/components/atoms/buttons/LoadingButton';
import FormFieldError from '@local/frontend/components/forms/FormFieldError';
import {sendEmail} from '@local/frontend/libs/api/group-payments.actions';

interface EmailInviteProps {
  groupPayment: GroupPaymentRecord;
}

const EmailInvite: FC<React.PropsWithChildren<EmailInviteProps>> = ({
  groupPayment,
}) => {
  const {t} = useTranslation([
    'common',
    'glossary',
    'validation',
    'email-invite',
  ]);
  const {open} = useNotification();
  const maxInvites = Math.max(
    1,
    calcGroupSize(groupPayment) - groupPayment.memberIds.length
  );
  const currentCustomerId = useCurrentCustomerId();
  const currentCustomer = useCustomer(currentCustomerId);
  const owner = useCustomer(groupPayment.ownerId);
  const {data: customerSharePrices} = useGroupPaymentCustomerShares(
    groupPayment.id
  );

  const emailInviteSchema = Yup.object({
    emails: Yup.array(
      Yup.object({
        id: Yup.string(),
        email: emailValidation(t).required(
          t('form.email.required', {ns: 'common'})
        ),
      }).required(t('emailInviteSchema.required', {ns: 'validation'}))
    )
      .min(1)
      .test(
        'unique',
        t('emailInviteSchema.duplicate', {ns: 'validation'}),
        function validateEmail(values) {
          if (!values) {
            return true; // Validation passes if the array is empty or undefined
          }

          const uniqueSet = new Set(values);
          if (uniqueSet.size !== values.length) {
            // eslint-disable-next-line react/no-this-in-sfc
            return this.createError({
              // eslint-disable-next-line react/no-this-in-sfc
              path: this.path,
              message: 'Emails must be unique',
            });
          }

          return true;
        }
      )
      .required(),
  });

  const {
    formState: {isDirty, isSubmitted, isSubmitting, isValid, errors},
    ...formMethods
  } = useForm<Yup.InferType<typeof emailInviteSchema>>({
    mode: 'all',
    resolver: yupResolver(emailInviteSchema),
    defaultValues: {
      emails: [{id: '', email: ''}],
    },
  });

  const emailsFieldArray = useFieldArray({
    name: 'emails',
    control: formMethods.control,
  });

  const onSubmit = async (
    formValues: Yup.InferType<typeof emailInviteSchema>
  ) => {
    if (!owner || !currentCustomerId || !currentCustomer) {
      return;
    }

    const sharePrice = customerSharePrices
      ? customerSharePrices[currentCustomerId]
      : undefined;

    if (owner && sharePrice) {
      await Promise.all(
        formValues.emails.map(async (emailObject) => {
          if (!emailObject.email) {
            return;
          }

          try {
            await sendEmail({
              ownerId: groupPayment.ownerId,
              groupPaymentId: groupPayment.id,
              emailDetails: {
                to: [emailObject.email],
                customerId: currentCustomer.id,
                subject: '', // gets replaced on API
                templateName: 'group-invite',
              },
            });

            open({
              message: t('notifications.emailSent', {
                ns: 'email-invite',
              }),
              severity: 'success',
            });
          } catch (err) {
            open({
              message: `${t('notifications.emailFail', {
                ns: 'email-invite',
              })} ${emailObject.email}`,
              severity: 'error',
            });
          }
        })
      );
    }
  };

  return (
    <form onSubmit={formMethods.handleSubmit(onSubmit)}>
      <Stack
        direction="column"
        spacing={1}
        flex="auto"
        maxHeight="200px"
        sx={{overflowY: 'auto', pt: 1}}
      >
        {emailsFieldArray.fields.map((emailObject, index) => (
          <Controller
            key={emailObject.id}
            name={`emails.${index}.email`}
            control={formMethods.control}
            render={({field, fieldState}) => (
              <Stack direction="column">
                <Stack direction="row" alignItems="center" spacing={1}>
                  <TextField
                    {...field}
                    error={Boolean(fieldState.error)}
                    type="email"
                    variant="outlined"
                    placeholder={t('field.placeholder', {
                      ns: 'email-invite',
                    })}
                    fullWidth
                    label={t('form.email.label', {ns: 'common'})}
                    disabled={isSubmitting || isSubmitted}
                  />
                  {emailsFieldArray.fields.length > 1 && (
                    <CustomTooltip
                      enabled
                      arrow
                      title={t('remove', {ns: 'glossary'})}
                    >
                      <IconButton
                        disabled={isSubmitting || isSubmitted}
                        onClick={() => emailsFieldArray.remove(index)}
                      >
                        <Remove />
                      </IconButton>
                    </CustomTooltip>
                  )}
                </Stack>
                <FormFieldError errors={errors} name={field.name} />
              </Stack>
            )}
          />
        ))}
        {!isSubmitted && emailsFieldArray.fields.length < maxInvites && (
          <Typography
            fontSize="small"
            component="a"
            href="#"
            width="fit-content"
            onClick={() => emailsFieldArray.append({id: undefined, email: ''})}
          >
            {t('addEmail', {
              ns: 'email-invite',
            })}
          </Typography>
        )}
      </Stack>
      <Stack direction="row" flex="auto" justifyContent="center" mt={2}>
        <CustomTooltip
          enabled={!isValid || !isDirty || isSubmitting}
          title={
            isSubmitting
              ? t('tooltip.sending', {
                  ns: 'email-invite',
                  count: emailsFieldArray.fields.length,
                })
              : errors.emails?.message
          }
        >
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{minWidth: '50%'}}
            disabled={!isValid || !isDirty || isSubmitting || isSubmitted}
            loading={isSubmitting}
          >
            {isSubmitted
              ? t('button.sent', {
                  ns: 'email-invite',
                  count: emailsFieldArray.fields.length,
                })
              : t('button.send', {
                  ns: 'email-invite',
                  count: emailsFieldArray.fields.length,
                })}
            {!isSubmitted && (
              <ForwardToInboxIcon sx={{color: '#ffffff', marginLeft: '10px'}} />
            )}
          </LoadingButton>
        </CustomTooltip>
      </Stack>
    </form>
  );
};

export default EmailInvite;
