import Close from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Typography, {TypographyProps} from '@mui/material/Typography';
import React, {FC} from 'react';
import {useCustomModals} from '@local/frontend/libs/modals/useCustomModals';
import {ModalName} from '@local/frontend/libs/modals/ModalName';
import {Stack} from '@mui/material';

interface ModalHeaderProps {
  modalName?: ModalName;
  title?: React.ReactNode;
  titleTypographyProps?: TypographyProps;
  subtitle?: React.ReactNode;
  subtitleTypographyProps?: TypographyProps;
  titlePlacement?: 'left' | 'center' | 'right';
  canClose?: boolean;
  onModalClose?: () => void;
}

const ModalHeader: FC<React.PropsWithChildren<ModalHeaderProps>> = ({
  title,
  modalName,
  subtitle,
  subtitleTypographyProps,
  titlePlacement = 'center',
  canClose = true,
  onModalClose,
  titleTypographyProps,
}) => {
  const {closeModal} = useCustomModals();

  if (!modalName && canClose) {
    throw new Error(
      'Need to provide a modal name to be able to close this modal, else set canClose to false'
    );
  }

  return (
    <Stack sx={{position: 'relative'}} width="100%">
      {canClose && modalName && (
        <IconButton
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            transition: 'all 0.3s ease-in-out',
            '&:hover': {
              transform: 'rotate(180deg)',
            },
          }}
          size="small"
          onClick={() => {
            if (onModalClose) {
              onModalClose();
            }

            closeModal(modalName);
          }}
        >
          <Close />
        </IconButton>
      )}

      {title && (
        <Typography
          variant="h5"
          align={titlePlacement}
          color="primary"
          {...titleTypographyProps}
          sx={{
            mt: canClose && modalName ? 5 : undefined,
            ...titleTypographyProps?.sx,
          }}
        >
          {title}
        </Typography>
      )}
      {subtitle && (
        <Typography
          variant="subtitle1"
          align={titlePlacement}
          {...subtitleTypographyProps}
        >
          {subtitle}
        </Typography>
      )}
    </Stack>
  );
};

export default ModalHeader;
