import {useNotification} from '@local/frontend/hooks/useNotification';
import {useCustomModals} from '@local/frontend/libs/modals/useCustomModals';
import {
  emailValidation,
  getCustomerValidation,
  phoneValidation,
} from '@local/frontend/validators/custom-validation';
import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import useCustomerCountry from '@local/frontend/hooks/useCustomerCountry';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {useUpdateCustomer} from '@local/frontend/hooks/mutations/customers';
import Grid from '@mui/material/Grid';
import {Customer} from '@local/backend/@types/updated-api-types/customers/Customer';
import {ModalName} from '@local/frontend/libs/modals/ModalName';
import {formatFullName} from '@local/frontend/util/stringFormatters';
import {useQueryClient} from '@tanstack/react-query';
import TextField from '@mui/material/TextField';
import useCustomerId from '@local/frontend/hooks/useCustomerId';
import {GroupPaymentRecord} from '@local/backend/@types/updated-api-types/group-payments/GroupPaymentRecord';
import {getSupportedLanguages} from '../../atoms/inputs/LanguageSelect/getSupportedLanguages';
import {useCurrentLanguage} from '../../atoms/inputs/LanguageSelect/useCurrentLanguage';
import {GroupMemberFormValues} from '../forms/@types/group-member-form';
import ModalHeader from './ModalHeader';
import ParticipantAvatar from '../../atoms/avatars/ParticipantAvatar';
import PhoneInput from '../../atoms/inputs/PhoneInput/PhoneInput';
import LoadingButton from '../../atoms/buttons/LoadingButton';

export interface EditMemberDetailsModalProps {
  customer: Customer;
  groupPayment: GroupPaymentRecord;
}

const EditMemberDetailsModal: FC<
  React.PropsWithChildren<EditMemberDetailsModalProps>
> = ({customer, groupPayment}) => {
  const currentCustomerId = useCustomerId();
  const {t} = useTranslation([
    'edit-member-details-modal',
    'group-member-form',
  ]);

  const {open: openNotification} = useNotification();
  const {closeModal} = useCustomModals();
  const queryClient = useQueryClient();
  const customerValidation = getCustomerValidation(t);
  const currentLanguage = useCurrentLanguage();
  const supportedLanguagesMap = getSupportedLanguages();
  const currentLanguageMetaData = supportedLanguagesMap[currentLanguage];

  const editGroupMemberFormValidationSchema = Yup.object({
    ...customerValidation,
    email: emailValidation(t).required(),
    phoneNumber: phoneValidation(t).required(),
  });

  const customerCountry = useCustomerCountry(customer.id);

  const formMethods = useForm<
    Yup.InferType<typeof editGroupMemberFormValidationSchema>
  >({
    mode: 'all',
    resolver: yupResolver(editGroupMemberFormValidationSchema),
    defaultValues: {
      firstName: customer.firstName ?? '',
      lastName: customer.lastName ?? '',
      email: customer.email ?? '',
      phoneNumber: customer.phoneNumber ?? '',
      phoneNumberCountry: customerCountry,
      language: customer.language ?? currentLanguageMetaData?.locale,
      country: customerCountry,
    },
  });

  const {
    formState: {isValid, isDirty},
  } = formMethods;

  const updateCustomerMutation = useUpdateCustomer();

  const handleEditMemberSubmit = async (
    customerFormValues: GroupMemberFormValues
  ) => {
    updateCustomerMutation.mutate(
      {
        customerId: customer.id,
        customerUpdateParams: {
          firstName: customerFormValues.firstName ?? customer.firstName,
          lastName: customerFormValues.lastName ?? '',
          email:
            customerFormValues.email && customerFormValues.email.length > 0
              ? customerFormValues.email
              : customer.email, // never allow a user to take an email away
          phoneNumber:
            customerFormValues.phoneNumber &&
            customerFormValues.phoneNumber.length > 0
              ? customerFormValues.phoneNumber
              : customer.phoneNumber, // never allow a user to take a phone number away
          address: {
            country: customerFormValues.country ?? customer.address?.country,
          },
          language: customerFormValues.language ?? customer.language,
        },
      },
      {
        onSuccess: (updatedCustomer) => {
          queryClient.setQueryData(
            ['customer', updatedCustomer.id],
            updatedCustomer
          );
          openNotification({
            severity: 'success',
            message: t('alerts.updateCustomer', {
              ns: 'edit-member-details-modal',
            }),
          });
          closeModal(ModalName.EDIT_MEMBER_DETAILS);
        },
        onError: (error) => {
          openNotification({
            severity: 'error',
            message: t('alerts.updateCustomerFailed', {
              ns: 'edit-member-details-modal',
            }),
          });
          closeModal(ModalName.EDIT_MEMBER_DETAILS);
          console.error(error);
        },
      }
    );
  };

  const isViewingThemselves = currentCustomerId === customer.id;

  const canUpdateCustomer =
    currentCustomerId === groupPayment.ownerId || isViewingThemselves;

  if (canUpdateCustomer) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ModalHeader
            modalName={ModalName.EDIT_MEMBER_DETAILS}
            title={
              isViewingThemselves
                ? t('titleForSelf', {
                    ns: 'edit-member-details-modal',
                  })
                : t('title', {
                    ns: 'edit-member-details-modal',
                    memberName: customer.firstName,
                  })
            }
            subtitle={
              isViewingThemselves
                ? t('subtitleForSelf', {
                    ns: 'edit-member-details-modal',
                  })
                : t('subtitle', {
                    ns: 'edit-member-details-modal',
                    memberName: formatFullName(
                      customer.firstName,
                      customer.lastName
                    ),
                  })
            }
            subtitleTypographyProps={{fontWeight: 400, fontSize: 14}}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs="auto">
              <ParticipantAvatar
                firstName={customer.firstName}
                lastName={customer.lastName}
                sx={{width: 100, height: 100, fontSize: 42}}
              />
            </Grid>
            <Grid item xs={12}>
              <form
                onSubmit={formMethods.handleSubmit(handleEditMemberSubmit)}
                style={{width: '100%'}}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container spacing={2} justifyContent="center">
                      <Grid item xs={12} sm={6}>
                        <Controller
                          name="firstName"
                          control={formMethods.control}
                          render={({field, fieldState}) => (
                            <TextField
                              {...field}
                              variant="outlined"
                              required
                              label={t('form.firstName.label', {
                                ns: 'common',
                              })}
                              placeholder={t('form.firstName.placeholder', {
                                ns: 'common',
                              })}
                              fullWidth
                              error={Boolean(fieldState.error)}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Controller
                          name="lastName"
                          control={formMethods.control}
                          render={({field, fieldState}) => (
                            <TextField
                              {...field}
                              variant="outlined"
                              label={t('form.lastName.label', {
                                ns: 'common',
                              })}
                              placeholder={t('form.lastName.placeholder', {
                                ns: 'common',
                              })}
                              fullWidth
                              error={Boolean(fieldState.error)}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="email"
                      control={formMethods.control}
                      render={({field, fieldState}) => (
                        <TextField
                          {...field}
                          variant="outlined"
                          label={t('form.email.label', {
                            ns: 'common',
                          })}
                          required
                          placeholder={t('form.email.placeholder', {
                            ns: 'common',
                          })}
                          fullWidth
                          error={Boolean(fieldState.error)}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="phoneNumber"
                      control={formMethods.control}
                      render={({field, fieldState}) => (
                        <PhoneInput
                          {...field}
                          required
                          onNumberChange={(value) => {
                            field.onChange(value);
                          }}
                          onCountryChange={(value) => {
                            if (value) {
                              formMethods.setValue('phoneNumberCountry', value);
                            }
                          }}
                          defaultCountry={formMethods.watch(
                            'phoneNumberCountry'
                          )}
                          variant="outlined"
                          fullWidth
                          label={t('form.phoneNumber.label', {
                            ns: 'common',
                          })}
                          placeholder={t('form.phoneNumber.placeholder', {
                            ns: 'common',
                          })}
                          error={Boolean(fieldState.error)}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <LoadingButton
                      type="submit"
                      fullWidth
                      loading={updateCustomerMutation.isLoading}
                      disabled={
                        !isValid || !isDirty || updateCustomerMutation.isLoading
                      }
                    >
                      {t('buttonText', {ns: 'edit-member-details-modal'})}
                    </LoadingButton>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ModalHeader
          modalName={ModalName.EDIT_MEMBER_DETAILS}
          title={t('title', {
            ns: 'edit-member-details-modal',
            memberName: customer.firstName,
          })}
          subtitle={t('viewingSubtitle', {
            ns: 'edit-member-details-modal',
            memberName: formatFullName(customer.firstName, customer.lastName),
          })}
          subtitleTypographyProps={{fontWeight: 400, fontSize: 14}}
        />
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs="auto">
            <ParticipantAvatar
              firstName={customer.firstName}
              lastName={customer.lastName}
              sx={{width: 100, height: 100, fontSize: 42}}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12} sm={6}>
                    <TextField
                      value={customer.firstName}
                      disabled
                      variant="outlined"
                      required
                      label={t('form.firstName.label', {
                        ns: 'common',
                      })}
                      placeholder={t('form.firstName.placeholder', {
                        ns: 'common',
                      })}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      value={customer.lastName}
                      disabled
                      variant="outlined"
                      label={t('form.lastName.label', {
                        ns: 'common',
                      })}
                      placeholder={t('form.lastName.placeholder', {
                        ns: 'common',
                      })}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled
                  value={customer.email}
                  variant="outlined"
                  label={t('form.email.label', {
                    ns: 'common',
                  })}
                  placeholder={t('form.email.placeholder', {
                    ns: 'common',
                  })}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <PhoneInput
                  value={customer.phoneNumber}
                  disabled
                  onNumberChange={() => {}}
                  onCountryChange={() => {}}
                  defaultCountry={formMethods.watch('phoneNumberCountry')}
                  variant="outlined"
                  fullWidth
                  label={t('form.phoneNumber.label', {
                    ns: 'common',
                  })}
                  placeholder={t('form.phoneNumber.placeholder', {
                    ns: 'common',
                  })}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EditMemberDetailsModal;
