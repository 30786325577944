// utility helper functions
import _ from 'lodash';
import type {GroupPaymentRecord} from '@local/backend/@types/updated-api-types/group-payments/GroupPaymentRecord';
import {Country, Language} from '@handsin/api-node';
import {Customer} from '../@types/updated-api-types/customer/Customer';
import {getAllGroupMemberIds} from '../helpers/group-payments/getAllGroupMemberIds';

const areArraysEqualUnordered = (arr1: unknown[], arr2: unknown[]) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const sortedArr1 = _.sortBy(arr1, (item) => JSON.stringify(item));
  const sortedArr2 = _.sortBy(arr2, (item) => JSON.stringify(item));

  return _.isEqual(sortedArr1, sortedArr2);
};

export const areObjectsEqual = (
  objectA: Record<string, unknown>,
  objectB: Record<string, unknown>
): boolean =>
  _.isEqualWith(objectA, objectB, (valueA, valueB) => {
    if (_.isArray(valueA) && _.isArray(valueB)) {
      return areArraysEqualUnordered(valueA, valueB);
    }

    return undefined;
  });

// sort customers in order they were created in
export const sortCustomersByCreatedAt = (customers: Customer[]): Customer[] =>
  customers.sort((a: Customer, b: Customer) =>
    !a.createdAt || !b.createdAt || a.createdAt < b.createdAt ? -1 : 1
  );

export const sortGroupMembers = (groupMembers: Customer[]): Customer[] =>
  groupMembers.sort(
    (a: Customer, b: Customer) =>
      !a.createdAt || !b.createdAt || a.createdAt < b.createdAt ? -1 : 1 // sort customers in order they were created in
  );

export const handleExternalRedirect = (
  redirectUrl: string,
  queryParams?: Record<string, string>
): void => {
  const url = new URL(redirectUrl);
  const urlSearchParams = new URLSearchParams(url.search);

  Object.entries(queryParams ?? {}).forEach(([queryKey, queryValue]) => {
    urlSearchParams.set(queryKey, queryValue);
  });

  url.search = urlSearchParams.toString();

  window.location.href = url.href;
};

export const generatePseudoMemberList = (
  groupPayment: GroupPaymentRecord
): (string | null)[] => {
  const allGroupMemberIds = getAllGroupMemberIds(groupPayment);

  let end = 0;
  if (
    groupPayment.splitType === 'EQUAL' ||
    groupPayment.splitType === 'FIXED_PRICE'
  ) {
    end = groupPayment.splitAllocation
      ? groupPayment.splitAllocation - allGroupMemberIds.length
      : 0;
  }

  if (groupPayment.splitType === 'BY_ITEM') {
    end = 1;
  }

  return [...allGroupMemberIds, ..._.range(0, end).map(() => null)];
};

export const groupPaymentKickUrl = (
  groupPaymentId: string,
  customerId: string,
  merchantId: string
): string =>
  `https://${window.location.host}/g/${groupPaymentId}/group-kicked?mid=${merchantId}&cid=${customerId}`;

export const getGroupPaymentCustomerJoinUrl = (
  gpr: GroupPaymentRecord,
  customerId: string
): string =>
  `https://${window.location.host}/g/${gpr.id}/group-dashboard?mid=${gpr.merchantId}&cid=${customerId}`;

export const getCountryByLanguage = (language: Language): Country => {
  switch (language) {
    case Language.ES:
      return Country.ES;
    case Language.DE:
      return Country.DE;
    case Language.FR:
      return Country.FR;
    case Language.NL:
      return Country.NL;
    case Language.PT:
      return Country.PT;
    case Language.IT:
      return Country.IT;
    case Language.EN:
    default:
      return Country.GB;
  }
};
