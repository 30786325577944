import {useContext} from 'react';
import {IntercomLauncherContext} from '../providers/IntercomLauncherProvider';
import {IntercomLauncherContextType} from '../providers/@types/IntercomLauncherContextType';

const useIntercomLauncher = (): IntercomLauncherContextType => {
  const launcherContext = useContext<IntercomLauncherContextType | undefined>(
    IntercomLauncherContext
  );

  if (!launcherContext) {
    throw new Error(
      'No intercom launcher context. hook must be used within intercom launcher context provider'
    );
  }

  return launcherContext;
};

export default useIntercomLauncher;
