import Typography from '@mui/material/Typography';
import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import type {GroupPaymentRecord} from '@local/backend/@types/updated-api-types/group-payments/GroupPaymentRecord';
import {useCustomModals} from '@local/frontend/libs/modals/useCustomModals';
import {ModalName} from '@local/frontend/libs/modals/ModalName';
import useCustomer from '../../../hooks/data/customers';
import {formatFullName} from '../../../util/stringFormatters';
import NoticeAlert from '../../atoms/NoticeAlert';

interface DynamicPricingAlertProps {
  customerId: string | undefined;
  groupPayment: GroupPaymentRecord | undefined;
}

const DynamicPricingAlert: FC<
  React.PropsWithChildren<DynamicPricingAlertProps>
> = ({groupPayment, customerId}) => {
  const {t} = useTranslation(['dynamic-pricing-alert']);
  const owner = useCustomer(customerId);
  const isOwner = groupPayment ? customerId === groupPayment.ownerId : false; // if theres no group payment, there is no owner, this can happen in cases of the checkout (sidebar)
  const {openModal} = useCustomModals();

  const handleLearnMore = () => {
    const isCheckout = !groupPayment;

    if (isCheckout || groupPayment.isDynamicPricingEnabled) {
      openModal(ModalName.DYNAMIC_PRICING_JOINER_INFORMATION, {
        ownerName: owner
          ? formatFullName(owner.firstName, owner.lastName)
          : undefined,
        groupPayment,
        customerId,
      });
    }
  };

  const handleOptOut = () => {
    openModal(ModalName.DYNAMIC_PRICING_OWNER_OPT_OUT, {
      groupPayment,
      customerId,
    });
  };

  return (
    <NoticeAlert severity="warning" fullWidth>
      {t('title', {ns: 'dynamic-pricing-alert'})}{' '}
      <Typography
        component="span"
        color="primary"
        variant="subtitle2"
        sx={{
          fontStyle: 'italic',
          fontSize: '0.875rem',
          '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
          },
        }}
        onClick={handleLearnMore}
      >
        {t('learnMore', {ns: 'dynamic-pricing-alert'})}
      </Typography>
      <br />
      {isOwner && (
        <Typography
          variant="caption"
          color="primary"
          fontStyle="italic"
          sx={{
            '&:hover': {
              cursor: 'pointer',
              textDecoration: 'underline',
            },
          }}
          onClick={handleOptOut}
        >
          {t('optOut', {ns: 'dynamic-pricing-alert'})}
        </Typography>
      )}
    </NoticeAlert>
  );
};

export default DynamicPricingAlert;
