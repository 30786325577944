import {useContext} from 'react';
import {CountryContextType} from '../providers/@types/CountryContext';
import {CountryContext} from '../providers/CountryContextProvider';

const useCountry = (): CountryContextType => {
  const countryContext = useContext<CountryContextType | undefined>(
    CountryContext
  );

  if (!countryContext) {
    throw new Error(
      'No Country context. hook must be used within country context provider'
    );
  }

  return countryContext;
};

export default useCountry;
