import React, {FC, createContext, useMemo, useState} from 'react';
import {Country} from '@handsin/api-node';
import {CountryContextType} from './@types/CountryContext';
import useCustomerCountry from '../hooks/useCustomerCountry';
import useCustomerId from '../hooks/useCustomerId';

export const CountryContext = createContext<CountryContextType | undefined>(
  undefined
);

interface CountryContextProviderProps {
  children: React.ReactNode;
}

const CountryContextProvider: FC<
  React.PropsWithChildren<CountryContextProviderProps>
> = ({children}) => {
  const cid = useCustomerId();
  const initialCountry = useCustomerCountry(cid);

  const [currentCountry, setCurrentCountry] = useState<Country>(initialCountry);

  const countryContextValues = useMemo(
    () => ({country: currentCountry, setCurrentCountry}),
    [currentCountry, setCurrentCountry]
  );

  return (
    <CountryContext.Provider value={countryContextValues}>
      {children}
    </CountryContext.Provider>
  );
};

export default CountryContextProvider;
