import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import ListItemText from '@mui/material/ListItemText';
import React, {Dispatch, FC, SetStateAction} from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import {useTranslation} from 'react-i18next';
import type {GroupPaymentRecord} from '@local/backend/@types/updated-api-types/group-payments/GroupPaymentRecord';
import {useInitializeMerchant} from '@local/frontend/hooks/queries/merchants';
import NoticeAlert from '@local/frontend/components/atoms/NoticeAlert';
import {useInitializeCustomer} from '@local/frontend/hooks/queries/customers';
import {useCustomModals} from '@local/frontend/libs/modals/useCustomModals';
import {
  JoinerGroupModalStep,
  ModalName,
} from '@local/frontend/libs/modals/ModalName';
import {Customer} from '../../../../../@types/updated-api-types/customer/Customer';
import GroupPaymentInfo from './GroupPaymentInfo';
import MerchantInfo from './MerchantInfo';

interface JoinGroupOverviewProps {
  setModalStep: Dispatch<SetStateAction<JoinerGroupModalStep>>;
  groupPayment: GroupPaymentRecord;
  selectedCustomer: Customer | null;
}

const JoinGroupOverview: FC<
  React.PropsWithChildren<JoinGroupOverviewProps>
> = ({setModalStep, groupPayment, selectedCustomer}) => {
  const {t} = useTranslation(['modals']);
  const {openModal} = useCustomModals();

  const {data: merchant, isInitialLoading: isMerchantLoading} =
    useInitializeMerchant();

  const {data: groupOwner, isInitialLoading: isCustomerLoading} =
    useInitializeCustomer(groupPayment.ownerId);

  const handleOpenOrderSummary = () => {
    if (groupPayment.lineItems) {
      openModal(ModalName.LINEITEM_SUMMARY, {groupPayment});
    }
  };

  if (isMerchantLoading || isCustomerLoading) {
    return null;
  }

  if (!merchant) {
    return (
      <NoticeAlert
        severity="error"
        message={t(
          'joinGroupModal.components.joinGroupOverview.errors.noMerchant',
          {ns: 'modals'}
        )}
      />
    );
  }

  if (!groupOwner) {
    return (
      <NoticeAlert
        severity="error"
        message={t(
          'joinGroupModal.components.joinGroupOverview.errors.noOwner',
          {ns: 'modals'}
        )}
      />
    );
  }

  return (
    <Grid container justifyContent="center">
      <Grid item xs={11}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MerchantInfo merchant={merchant} />
          </Grid>
          <Grid item xs={12}>
            <Divider>
              <Typography fontSize={14} fontWeight={500} color="primary">
                {t('joinGroupModal.components.joinGroupOverview.title', {
                  ns: 'modals',
                })}
              </Typography>
            </Divider>
          </Grid>
          <Grid item xs={12}>
            <GroupPaymentInfo
              groupPayment={groupPayment}
              groupOwner={groupOwner}
              selectedCustomer={selectedCustomer}
            />
          </Grid>
          {groupPayment.lineItems && (
            <Grid item xs={12}>
              <ListItemButton dense selected onClick={handleOpenOrderSummary}>
                <ListItemText
                  secondary={
                    <Typography
                      variant="body2"
                      color="primary.dark"
                      align="center"
                    >
                      {t(
                        'joinGroupModal.components.joinGroupOverview.summary',
                        {ns: 'modals'}
                      )}
                    </Typography>
                  }
                />
              </ListItemButton>
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              onClick={() => setModalStep(JoinerGroupModalStep.JOINER_FORM)}
            >
              {t('joinGroupModal.components.joinGroupOverview.button.text', {
                ns: 'modals',
              })}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default JoinGroupOverview;
