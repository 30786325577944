import * as Yup from 'yup';
import type {Namespace, TFunction} from 'i18next';
import {Customer} from '../../../../@types/updated-api-types/customer/Customer';
import {nameValidation} from '../../../../validators/custom-validation';

const getJoinByItemGroupSchema = (t: TFunction<Namespace>) =>
  Yup.object({
    newAddedCustomers: Yup.array(
      Yup.object({
        name: nameValidation(
          t('joinByItemGroupSchema.name.field', {ns: 'validation'}),
          t
        ).required(
          t('joinByItemGroupSchema.name.required', {
            ns: 'validation',
          })
        ),
      })
    ).required(t('joinByItemGroupSchema.required', {ns: 'validation'})), // these constraints are shown if and only if inner constraints are satisfied
    existingGroupCustomers: Yup.array(
      Yup.mixed<Customer>().required()
    ).required(),
    addedCustomerName: nameValidation('name', t),
  });

export default getJoinByItemGroupSchema;
