import React, {type FC, createContext, useMemo, useState} from 'react';
import type {IntercomLauncherContextType} from './@types/IntercomLauncherContextType';

export const IntercomLauncherContext = createContext<
  IntercomLauncherContextType | undefined
>(undefined);

interface IntercomLauncherProviderProps {
  children: React.ReactNode;
}

const IntercomLauncherProvider: FC<
  React.PropsWithChildren<IntercomLauncherProviderProps>
> = ({children}) => {
  const [isLauncherOpen, setIsLauncherOpen] = useState<boolean>(false);
  const [hasBooted, setHasBooted] = useState<boolean>(false);

  const intercomLauncherContextValues = useMemo<IntercomLauncherContextType>(
    () => ({isLauncherOpen, setIsLauncherOpen, hasBooted, setHasBooted}),
    [isLauncherOpen, setIsLauncherOpen, hasBooted, setHasBooted]
  );

  return (
    <IntercomLauncherContext.Provider value={intercomLauncherContextValues}>
      {children}
    </IntercomLauncherContext.Provider>
  );
};

export default IntercomLauncherProvider;
