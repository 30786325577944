import React, {FC, useEffect, useState} from 'react';
import {usePostHog} from 'posthog-js/react';
import {useParams, useSearchParams} from 'react-router-dom';
import useCustomerId from '../hooks/useCustomerId';
import {useInitializeCustomer} from '../hooks/queries/customers';
import {useMultiCardPayment} from '../libs/trpc/trpc';

const PostHogIdentifyWrapper: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const {groupPaymentId, multiCardId} = useParams();
  const [searchParams] = useSearchParams();
  const merchantId = searchParams.get('mid');
  const customerId = useCustomerId();
  const {data: multiCardPayment} = useMultiCardPayment(
    {merchantId: merchantId ?? '', multiCardId: multiCardId ?? ''},
    {
      enabled: !!merchantId && !!multiCardId,
    }
  );

  const [customerIdToUse, setCustomerIdToUse] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    if (multiCardId && multiCardPayment?.customerId) {
      setCustomerIdToUse(multiCardPayment.customerId);
    } else if (groupPaymentId && customerId) {
      setCustomerIdToUse(customerId);
    }
  }, [multiCardId, multiCardPayment, groupPaymentId, customerId]);

  const {data: customer} = useInitializeCustomer(customerIdToUse, {
    enabled: !!customerIdToUse,
  });
  const posthog = usePostHog();

  useEffect(() => {
    if (posthog && customer) {
      posthog.identify(customer.id, {
        merchantId,
        firstName: customer.firstName,
        lastName: customer.lastName,
        email: customer.email,
        phoneNumber: customer.phoneNumber,
        language: customer.language,
        country: customer.address?.country ?? null,
        createdAt: customer.createdAt,
      });
    }
  }, [posthog, customer]);

  return <>{children}</>;
};

export default PostHogIdentifyWrapper;
