import type {MerchantDTO} from '@local/backend/@types/updated-api-types/merchants/MerchantDTO';
import {useQueryClient} from '@tanstack/react-query';

const useMerchant = (): MerchantDTO => {
  const queryClient = useQueryClient();
  const merchantRecord = queryClient.getQueryData<MerchantDTO>(['merchant']);
  if (!merchantRecord) {
    throw new Error('Merchant should be defined in context');
  }
  return merchantRecord;
};

export default useMerchant;
