import {usePostHog} from 'posthog-js/react';
import React, {FC, useEffect} from 'react';
import {useParams} from 'react-router-dom';

const PostHogGroupWrapper: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const {groupPaymentId, multiCardId} = useParams();
  const posthog = usePostHog();

  useEffect(() => {
    if (groupPaymentId) {
      posthog.group('group-payment', groupPaymentId, {});
    }

    if (multiCardId) {
      posthog.group('multi-card', multiCardId, {});
    }
  }, [groupPaymentId, multiCardId]);
  return <>{children}</>;
};

export default PostHogGroupWrapper;
