/* eslint-disable react/jsx-no-useless-fragment */
import {
  Button,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Stack from '@mui/material/Stack';
import React, {FC} from 'react';
import useCustomerId from '@local/frontend/hooks/useCustomerId';
import useCachedGroupPayment from '@local/frontend/hooks/useCachedGroupPayment';
import {useTranslation} from 'react-i18next';
import {
  PeopleIcon,
  SliceIcon,
  CardIcon,
  TimeIcon,
  ArrowIcon,
} from '@local/frontend/icons';
import {ModalName} from '@local/frontend/libs/modals/ModalName';
import {useCustomModals} from '@local/frontend/libs/modals/useCustomModals';
import GroupPaymentHelper from '@local/frontend/util/GroupPaymentHelper';
import GroupPaymentFooter from '../../GroupPaymentFooter';

interface HowItWorksElementProps {
  icon: React.JSX.Element;
  title: string;
}

const HowItWorksElement: FC<HowItWorksElementProps> = ({icon, title}) => (
  <Stack direction={{xs: 'column', sm: 'row'}} spacing={1} alignItems="center">
    {icon}
    <Stack sx={{px: 1}} justifyContent="center">
      <Typography
        variant="subtitle1"
        sx={{
          fontSize: {xs: 18, sm: 22},
          textAlign: {xs: 'center', sm: 'left'},
        }}
      >
        {title}
      </Typography>
    </Stack>
  </Stack>
);

const SbiHowItWorksModal: FC<React.PropsWithChildren<unknown>> = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const currentCustomerId = useCustomerId();

  const groupPayment = useCachedGroupPayment();

  const {closeModal} = useCustomModals();
  const {t} = useTranslation(['sbi-how-it-works']);

  const isOwner =
    !!currentCustomerId && groupPayment.ownerId === currentCustomerId;

  const hasAllItemsBeenAssigned = new GroupPaymentHelper(
    groupPayment
  ).hasAllItemsBeenAllocated();

  const defaultIconStyleProps = {
    width: isMobile ? '42' : '52',
    height: isMobile ? '42' : '52',
  };

  const ownerSteps = [
    {
      icon: <PeopleIcon {...defaultIconStyleProps} />,
      title: t('steps.setupGroup.title', {ns: 'sbi-how-it-works'}),
    },
    hasAllItemsBeenAssigned
      ? undefined
      : {
          icon: <SliceIcon {...defaultIconStyleProps} />,
          title: t('steps.splitTotal.title', {ns: 'sbi-how-it-works'}),
        },
  ];

  const joinerSteps = [
    {
      icon: <CardIcon {...defaultIconStyleProps} />,
      title: t('steps.payShare.title', {ns: 'sbi-how-it-works'}),
    },
    {
      icon: <ArrowIcon {...defaultIconStyleProps} />,
      title: t('steps.shareLink.title', {ns: 'sbi-how-it-works'}),
    },
    {
      icon: <TimeIcon {...defaultIconStyleProps} />,
      title: t('steps.paymentExpire.title', {ns: 'sbi-how-it-works'}),
    },
  ];

  const steps = isOwner ? [...ownerSteps, ...joinerSteps] : joinerSteps;

  const handleButtonClick = () => {
    closeModal(ModalName.HOW_IT_WORKS);
  };

  return (
    <Stack spacing={2}>
      <Typography variant="h2" align="center">
        {t('title', {ns: 'sbi-how-it-works'})}
      </Typography>
      <Stack spacing={3} divider={<Divider />}>
        {steps.map((step) =>
          step ? (
            <HowItWorksElement
              key={step.title}
              icon={step.icon}
              title={step.title}
            />
          ) : null
        )}
      </Stack>
      <Stack spacing={1}>
        <Button variant="contained" fullWidth onClick={handleButtonClick}>
          {t('buttonText', {ns: 'sbi-how-it-works'})}
        </Button>
        <GroupPaymentFooter />
      </Stack>
    </Stack>
  );
};

export default SbiHowItWorksModal;
