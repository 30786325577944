import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';
import {useNavigate} from 'react-router-dom';
import {GroupPaymentStatus} from '@handsin/api-node';
import {AxiosError} from 'axios';
import {useTranslation} from 'react-i18next';
import type {GroupPaymentRecord} from '@local/backend/@types/updated-api-types/group-payments/GroupPaymentRecord';
import {PaymentRecord} from '@local/frontend/@types/updated-api-types/payments/PaymentRecord';
import {SplitType} from '@local/frontend/@types/updated-api-types/group-payments/SplitType';
import {useCustomModals} from '@local/frontend/libs/modals/useCustomModals';
import {ModalName} from '@local/frontend/libs/modals/ModalName';
import type {DefaultResponseError} from '@local/backend/@types/updated-api-types/DefaultResponseError';
import {LocalMoneyCalculator} from '@handsin/money';
import {usePostHog} from 'posthog-js/react';
import PosthogEvent from '@local/frontend/@types/posthog/posthog-events';
import {
  cancelGroupPayment,
  createGroupPayment,
  joinGroupPayment,
  kickFromGroupPayment,
  leaveGroupPayment,
  payIntoGroupPayment,
  updateGroupPayment,
} from '../../../libs/api/group-payments.actions';
import {
  CancelGroupPaymentMutationParams,
  CreateGroupPaymentMutationParams,
  JoinGroupPaymentMutationParams,
  KickFromGroupPaymentMutationParams,
  LeaveGroupPaymentMutationParams,
  PayIntoGroupPaymentMutationParams,
  UpdateGroupPaymentMutationContext,
  UpdateGroupPaymentMutationParams,
} from '../@types';
import {removeIdempKey} from '../../../util/idemp-key-handlers';
import {useNotification} from '../../useNotification';
import useSbiContext from '../../useSbiContext';

export const useUpdateGroupPayment = (): UseMutationResult<
  GroupPaymentRecord,
  AxiosError<DefaultResponseError>,
  UpdateGroupPaymentMutationParams,
  UpdateGroupPaymentMutationContext
> => {
  const queryClient = useQueryClient();
  const {t} = useTranslation(['mutations']);
  const {open: openNotification} = useNotification();
  const {setItemAlloc} = useSbiContext();

  const updateGroupPaymentMutation = useMutation<
    GroupPaymentRecord,
    AxiosError<DefaultResponseError>,
    UpdateGroupPaymentMutationParams,
    UpdateGroupPaymentMutationContext
  >(updateGroupPayment, {
    onMutate: async ({groupPayment, mutationOptions}) => {
      if (mutationOptions?.onMutate) {
        mutationOptions.onMutate();
      }
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(['groupPayment', groupPayment.id]);
      // Snapshot the previous value
      const previousGroupPayment = queryClient.getQueryData<GroupPaymentRecord>(
        ['groupPayment', groupPayment.id]
      );
      // // Return a context object with the snapshotted value
      return {previousGroupPayment, mutationOptions};
    },
    onSuccess: async (
      updatedGroupPayment,
      _variables,
      updateGroupPaymentMutationContext
    ) => {
      if (updateGroupPaymentMutationContext?.mutationOptions?.onSuccess) {
        updateGroupPaymentMutationContext.mutationOptions.onSuccess();
      }

      await queryClient.invalidateQueries([
        'groupPayment',
        updatedGroupPayment.id,
      ]);

      await queryClient.invalidateQueries(
        ['group-payment-shares', updatedGroupPayment.id],
        {
          type: 'all',
        }
      );

      if (
        updatedGroupPayment.splitType === SplitType.BY_ITEM &&
        updatedGroupPayment.itemAllocation
      ) {
        setItemAlloc(updatedGroupPayment.itemAllocation);
      }
    },
    onError: (err, _updateVariables, updateGroupPaymentMutationContext) => {
      if (updateGroupPaymentMutationContext?.mutationOptions?.onError) {
        updateGroupPaymentMutationContext.mutationOptions.onError();
      }
      openNotification({
        message:
          err?.response?.data?.detail ??
          t('errors.failedToUpdateGroup', {ns: 'mutations'}),
        severity: 'error',
      });
    },
    onSettled: (
      _gpr,
      _error,
      _updateVariables,
      updateGroupPaymentMutationContext
    ) => {
      if (updateGroupPaymentMutationContext?.mutationOptions?.onSettled) {
        updateGroupPaymentMutationContext.mutationOptions.onSettled();
      }
    },
  });
  return updateGroupPaymentMutation;
};

export const useJoinGroupPayment = (): UseMutationResult<
  GroupPaymentRecord,
  AxiosError<DefaultResponseError>,
  JoinGroupPaymentMutationParams,
  unknown
> => {
  const {t} = useTranslation(['mutations']);
  const {open: openNotification} = useNotification();

  const joinGroupPaymentMutation = useMutation<
    GroupPaymentRecord,
    AxiosError<DefaultResponseError>,
    JoinGroupPaymentMutationParams
  >(joinGroupPayment, {
    onError: (err) => {
      openNotification({
        message:
          err?.response?.data?.detail ??
          t('errors.failedToJoinGroup', {ns: 'mutations'}),
        severity: 'error',
      });
    },
  });
  return joinGroupPaymentMutation;
};

export const useCreateGroupPayment = (): UseMutationResult<
  GroupPaymentRecord,
  AxiosError<DefaultResponseError>,
  CreateGroupPaymentMutationParams,
  unknown
> => {
  const queryClient = useQueryClient();
  const {t} = useTranslation(['mutations']);
  const {open: openNotification} = useNotification();

  const navigate = useNavigate();

  const createGroupPaymentMutation = useMutation<
    GroupPaymentRecord,
    AxiosError<DefaultResponseError>,
    CreateGroupPaymentMutationParams,
    unknown
  >(createGroupPayment, {
    onSuccess: async (groupPayment: GroupPaymentRecord) => {
      queryClient.setQueryData(['groupPayment', groupPayment.id], groupPayment);
      await queryClient.invalidateQueries(['groupPayment', groupPayment.id]);

      navigate(
        `/g/${groupPayment.id}/group-setup?mid=${groupPayment.merchantId}&cid=${groupPayment.ownerId}`
      );
    },
    onError: (err) => {
      openNotification({
        message:
          err?.response?.data?.detail ??
          t('errors.failedToCreateGroup', {ns: 'mutations'}),
        severity: 'error',
      });
    },
  });
  return createGroupPaymentMutation;
};

export const useKickFromGroup = (): UseMutationResult<
  GroupPaymentRecord,
  AxiosError<DefaultResponseError>,
  KickFromGroupPaymentMutationParams,
  unknown
> => {
  const queryClient = useQueryClient();
  const {t} = useTranslation(['mutations']);
  const {open: openNotification} = useNotification();

  const {setItemAlloc} = useSbiContext();
  const updateGroupPaymentMutation = useUpdateGroupPayment();

  const kickFromGroupPaymentMutation = useMutation(kickFromGroupPayment, {
    onSuccess: async (
      groupPayment: GroupPaymentRecord,
      {
        customerToKick,
        isDecreasingGroupSize,
      }: KickFromGroupPaymentMutationParams
    ) => {
      // check if we should also reduce the group size when removing the owner
      if (isDecreasingGroupSize && groupPayment.splitAllocation) {
        const newGroupSize = groupPayment.splitAllocation - 1;
        updateGroupPaymentMutation.mutate({
          groupPayment,
          updateGroupPaymentParams: {
            splitType: groupPayment.splitType,
            splitAllocation: newGroupSize,
          },
        });
      }

      await queryClient.invalidateQueries(['groupPayment', groupPayment.id]);

      if (
        groupPayment.splitType === SplitType.BY_ITEM &&
        groupPayment.itemAllocation
      ) {
        setItemAlloc(groupPayment.itemAllocation);
      }

      // show notification to indicate remove was successful
      openNotification({
        message: t('alerts.removedFromGroup', {
          ns: 'mutations',
          firstName: customerToKick.firstName,
        }),
        severity: 'success',
      });
    },
    onError: (error: AxiosError<DefaultResponseError>) => {
      const errMessage =
        error.response?.data?.detail ??
        t('errors.failedToKick', {ns: 'mutations'});
      openNotification({
        message: errMessage,
        severity: 'error',
      });
    },
  });
  return kickFromGroupPaymentMutation;
};

export const useLeaveGroupPayment = (): UseMutationResult<
  GroupPaymentRecord,
  unknown,
  LeaveGroupPaymentMutationParams,
  unknown
> => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {open: openNotification} = useNotification();
  const {t} = useTranslation(['mutations']);

  const leaveGroupPaymentMutation = useMutation(leaveGroupPayment, {
    onSuccess: async (
      groupPayment: GroupPaymentRecord,
      {customer}: LeaveGroupPaymentMutationParams
    ) => {
      await queryClient.invalidateQueries([
        'payment',
        groupPayment.memberPayments[customer.id],
      ]);
      await queryClient.invalidateQueries(['groupPayment', groupPayment.id]);

      // remove their idempKey, since they left to try clean up their local storage
      removeIdempKey(groupPayment.id, customer.id);

      if (groupPayment.status === GroupPaymentStatus.PENDING) {
        navigate(
          `/g/${groupPayment.id}/group-leave?mid=${groupPayment.merchantId}&cid=${customer.id}`
        );
      } else {
        navigate(
          `/g/${groupPayment.id}/t?mid=${groupPayment.merchantId}&cid=${customer.id}`
        );
      }
    },
    onError: (err: AxiosError<DefaultResponseError>) => {
      openNotification({
        message:
          err?.response?.data?.detail ??
          t('errors.failedToLeaveGroup', {ns: 'mutations'}),
        severity: 'error',
      });
    },
  });

  return leaveGroupPaymentMutation;
};

export const usePayIntoGroupPayment = (): UseMutationResult<
  PaymentRecord,
  AxiosError<DefaultResponseError>,
  PayIntoGroupPaymentMutationParams,
  unknown
> => {
  const queryClient = useQueryClient();
  const {open: openNotification} = useNotification();
  const {t} = useTranslation(['mutations']);
  const {openModal} = useCustomModals();
  const posthog = usePostHog();

  return useMutation<
    PaymentRecord,
    AxiosError<DefaultResponseError>,
    PayIntoGroupPaymentMutationParams
  >(payIntoGroupPayment, {
    onSuccess: async (
      paymentRecord: PaymentRecord,
      {groupPayment}: PayIntoGroupPaymentMutationParams
    ) => {
      if (paymentRecord.approvedMoney) {
        const isLastPayment = groupPayment.approvedMoney
          ? new LocalMoneyCalculator(groupPayment.totalMoney)
              .subtract(groupPayment.approvedMoney)
              .subtract(paymentRecord.approvedMoney)
              .calculate().amount <= 0
          : false;

        // only show payment approved modal for equal & fixed price groups
        if (!isLastPayment && groupPayment.splitType !== SplitType.BY_ITEM) {
          // only show payment approved modal if not the last payment
          openModal(ModalName.PAYMENT_APPROVED, {
            approvedMoney: paymentRecord.approvedMoney,
            onBehalfOf: paymentRecord.onBehalfOf,
          });
        }
      }

      queryClient.setQueryData(['payment', paymentRecord.id], paymentRecord);
      await queryClient.invalidateQueries(['groupPayment', groupPayment.id]);
      await queryClient.invalidateQueries([
        'groupPayment-public',
        groupPayment.id,
      ]);

      // handle any payment system failures by checking the payment record status and throwing an error if the payment didn't go through at the end-system
      if (
        paymentRecord.status === 'FAILED' ||
        paymentRecord.status === 'CANCELLED'
      ) {
        throw new Error('payment failed');
      }
    },
    onError: (
      err: AxiosError<DefaultResponseError>,
      {groupPayment, customer, onBehalfOf}: PayIntoGroupPaymentMutationParams
    ) => {
      if (err.response?.data?.name === 'IDEMPOTENCY_KEY_REUSED') {
        return;
      }
      // payment failed so remove idemp key as long as the error wasn't the fact the idemp key was re-used
      if (err.response && err.response.data?.name !== 'ALREADY_PAID') {
        removeIdempKey(groupPayment.id, onBehalfOf?.id ?? customer.id);
      }

      openNotification({
        message:
          err.response?.data.detail ??
          t('errors.failedToPay', {ns: 'mutations'}),
        severity: 'error',
      });

      posthog?.capture(PosthogEvent.PAYMENT_FAILED, {
        errorMessage: err.response?.data.detail ?? 'Failed to pay',
        merchantId: groupPayment.merchantId,
        customerId: customer.id,
        groupPaymentId: groupPayment.id,
        groupTotalAmount: groupPayment.totalMoney.amount,
        currency: groupPayment.totalMoney.currency,
        market: groupPayment.airlineData?.market,
        onBehalfOf: onBehalfOf?.id ?? false,
      });
    },
  });
};

export const useCancelGroupPayment = (): UseMutationResult<
  GroupPaymentRecord,
  AxiosError<DefaultResponseError>,
  CancelGroupPaymentMutationParams,
  unknown
> => {
  const queryClient = useQueryClient();
  const {open: openNotification} = useNotification();
  const posthog = usePostHog();

  return useMutation(cancelGroupPayment, {
    onSuccess: (groupPayment, cancelGroupParams) => {
      // track the cancel group payment event in posthog
      posthog?.capture(PosthogEvent.CANCEL_GROUP_PAYMENT, {
        merchantId: groupPayment.merchantId,
        groupPaymentId: cancelGroupParams.groupPaymentId,
        customerId: cancelGroupParams.customerId,
        ownerId: groupPayment.ownerId,
        reason: cancelGroupParams.reason,
        createdAt: groupPayment.createdAt,
        cancelledAt: new Date().toISOString(),
      });
      queryClient.setQueryData(['groupPayment', groupPayment.id], groupPayment);
    },
    onError: (err) => {
      openNotification({
        message:
          err?.response?.data?.detail ?? 'Could not cancel group payment',
        severity: 'error',
      });
    },
  });
};
