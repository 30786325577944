import type {Namespace, TFunction} from 'i18next';
import * as yup from 'yup';

const getFeedbackSchema = (t: TFunction<Namespace>) =>
  yup.object({
    satisfactionRating: yup
      .number()
      .min(0.5, t('feedbackSchema.rating.min', {ns: 'validation'}))
      .max(5, t('feedbackSchema.rating.max', {ns: 'validation'}))
      .required(),
    review: yup.string().notRequired(),
  });

export default getFeedbackSchema;
