import Typography, {TypographyProps} from '@mui/material/Typography';
import React, {FC} from 'react';
import PersonIcon from '@mui/icons-material/Person';
import Avatar, {AvatarProps} from '@mui/material/Avatar';
import StarIcon from '@mui/icons-material/Star';
import Badge, {BadgeProps} from '@mui/material/Badge';
import {Skeleton, SkeletonProps, Tooltip} from '@mui/material';
import {formatFullName} from '@local/frontend/util/stringFormatters';

interface Props extends AvatarProps {
  isOwner?: boolean;
  firstName?: string | undefined;
  lastName?: string | undefined;
  badgeProps?: BadgeProps;
  typographyProps?: TypographyProps;
  isCurrentCustomer?: boolean;
  isLoading?: boolean;
  loadingProps?: SkeletonProps;
  disableTooltip?: boolean;
}

const ParticipantAvatar: FC<React.PropsWithChildren<Props>> = ({
  isOwner = false,
  disableTooltip = false,
  firstName,
  lastName,
  badgeProps,
  typographyProps,
  isCurrentCustomer,
  isLoading,
  loadingProps,
  ...props
}) => {
  if (isLoading) {
    return (
      <Skeleton variant="circular" width={30} height={30} {...loadingProps} />
    );
  }

  return (
    <Tooltip
      arrow
      title={
        !disableTooltip && firstName ? formatFullName(firstName, lastName) : ''
      }
    >
      <Badge
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        overlap="circular"
        {...badgeProps}
        badgeContent={
          badgeProps?.badgeContent ?? (
            <StarIcon sx={{fontSize: '16px', color: 'warning.main'}} />
          )
        }
        invisible={!badgeProps?.badgeContent && !isOwner}
      >
        <Avatar
          {...props}
          sx={{
            color: 'primary.dark',
            backgroundColor: 'primary.lighter',
            border: (theme) =>
              isCurrentCustomer
                ? `solid 2px ${theme.palette.primary.dark} !important`
                : undefined,
            ...props.sx,
          }}
        >
          {firstName ? (
            <Typography
              variant="subtitle2"
              fontSize="inherit"
              align="center"
              {...typographyProps}
              sx={{fontWeight: 600, color: 'inherit', ...typographyProps?.sx}}
            >
              {firstName.at(0)?.toUpperCase()}
              {lastName?.at(0)?.toUpperCase()}
            </Typography>
          ) : (
            <PersonIcon fontSize="large" />
          )}
        </Avatar>
      </Badge>
    </Tooltip>
  );
};

export default ParticipantAvatar;
