import {Typography} from '@mui/material';
import Grid from '@mui/material/Grid';
import React, {FC, useState} from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {useTranslation} from 'react-i18next';
import type {GroupPaymentRecord} from '@local/backend/@types/updated-api-types/group-payments/GroupPaymentRecord';
import {ModalName} from '@local/frontend/libs/modals/ModalName';
import {setDynamicPricingLocalStorageKey} from '@local/frontend/util/dynamic-pricing-key-handlers';
import useMerchant from '../../../../hooks/data/merchants';
import ModalHeader from '../ModalHeader';

interface DynamicPricingJoinerInfoModalProps {
  ownerName: string | undefined;
  groupPayment: GroupPaymentRecord | undefined;
  customerId: string | undefined;
}

const DynamicPricingJoinerInfoModal: FC<
  React.PropsWithChildren<DynamicPricingJoinerInfoModalProps>
> = ({ownerName, customerId, groupPayment}) => {
  const {t} = useTranslation(['modals']);
  const [isExampleShowing, setIsExampleShowing] = useState(false);
  const merchant = useMerchant();

  const handleToggleExample = () => {
    setIsExampleShowing(!isExampleShowing);
  };

  const ownerOrMerchantName = ownerName ?? merchant?.name;

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <ModalHeader
          title={
            ownerOrMerchantName
              ? t('dynamicPricingJoinerInfoModal.title.withName', {
                  ns: 'modals',
                  name: ownerOrMerchantName,
                })
              : t('dynamicPricingJoinerInfoModal.title.withoutName', {
                  ns: 'modals',
                })
          }
          modalName={ModalName.DYNAMIC_PRICING_JOINER_INFORMATION}
          onModalClose={
            // we only want to set a key for joiners when this modal is closed.
            // In the event the owner ever becomes a joiner, they still need to be presented this modal for the first time.
            groupPayment && customerId && customerId !== groupPayment.ownerId
              ? () =>
                  setDynamicPricingLocalStorageKey(
                    groupPayment.id,
                    customerId,
                    'JOINER'
                  )
              : undefined
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle2" color="primary">
              {t('dynamicPricingJoinerInfoModal.firstQuestion', {ns: 'modals'})}
            </Typography>
            <Typography variant="subtitle1">
              {t('dynamicPricingJoinerInfoModal.firstAnswer', {ns: 'modals'})}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" color="primary">
              {t('dynamicPricingJoinerInfoModal.secondQuestion', {
                ns: 'modals',
              })}
            </Typography>
            <Typography variant="subtitle1">
              {t('dynamicPricingJoinerInfoModal.secondAnswer', {ns: 'modals'})}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              color="primary"
              sx={{
                display: 'inline-flex',
                textDecoration: isExampleShowing ? 'underline' : 'none',
                '&:hover': {
                  cursor: 'pointer',
                  textDecoration: 'underline',
                },
              }}
              onClick={handleToggleExample}
            >
              {isExampleShowing
                ? t('dynamicPricingJoinerInfoModal.example.hide', {
                    ns: 'modals',
                  })
                : t('dynamicPricingJoinerInfoModal.example.show', {
                    ns: 'modals',
                  })}
              <Typography component="span">
                <ArrowDropDownIcon />
              </Typography>
            </Typography>
            {isExampleShowing && (
              <Typography variant="subtitle1">
                {t('dynamicPricingJoinerInfoModal.example.text', {
                  ns: 'modals',
                })}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DynamicPricingJoinerInfoModal;
