import type {Customer} from '@local/backend/@types/updated-api-types/customers/Customer';
import type {LineItem} from '@local/backend/@types/updated-api-types/items/LineItem';
import NoticeAlert from '@local/frontend/components/atoms/NoticeAlert';
import useCachedGroupPayment from '@local/frontend/hooks/useCachedGroupPayment';
import useCustomerId from '@local/frontend/hooks/useCustomerId';
import {formatFullName} from '@local/frontend/util/stringFormatters';
import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import _ from 'lodash';
import useCustomer from '@local/frontend/hooks/data/customers';
import SbiMergedFlowLineItemList from './SbiMergedFlowLineItemList';

interface SbiMergedFlowLineItemAssignerProps {
  selectedCustomer: Customer | undefined;
  lineItemsToDisplay: LineItem[];
}

const SbiMergedFlowLineItemAssigner: FC<
  React.PropsWithChildren<SbiMergedFlowLineItemAssignerProps>
> = ({selectedCustomer, lineItemsToDisplay}) => {
  const {t} = useTranslation(['sbi-assign-items']);
  const currentViewingCustomerId = useCustomerId();

  const groupPayment = useCachedGroupPayment();

  const isOwner = currentViewingCustomerId === groupPayment.ownerId;
  const owner = useCustomer(groupPayment.ownerId);

  if (_.isEmpty(lineItemsToDisplay)) {
    return (
      <NoticeAlert
        message={
          isOwner
            ? t('content.noticeAlert.ownerMessage', {
                ns: 'sbi-assign-items',
                context: selectedCustomer
                  ? 'selectedCustomer'
                  : 'noSelectedCustomer',
                customerName: selectedCustomer
                  ? formatFullName(
                      selectedCustomer.firstName,
                      selectedCustomer.lastName
                    )
                  : '',
              })
            : t('content.noticeAlert.joinerMessage', {
                ns: 'sbi-assign-items',
                context: selectedCustomer
                  ? 'selectedCustomer'
                  : 'noSelectedCustomer',
                ownerName: formatFullName(owner.firstName, owner.lastName),
                customerName: selectedCustomer
                  ? formatFullName(
                      selectedCustomer.firstName,
                      selectedCustomer.lastName
                    )
                  : '',
              })
        }
      />
    );
  }

  return (
    <SbiMergedFlowLineItemList
      lineItems={lineItemsToDisplay}
      selectedCustomerId={selectedCustomer?.id}
    />
  );
};

export default SbiMergedFlowLineItemAssigner;
