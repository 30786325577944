import {useTranslation} from 'react-i18next';
import {useMutation} from '@tanstack/react-query';
import {Language} from '@handsin/api-node';
import {useNotification} from '@local/frontend/hooks/useNotification';

export const useChangeLanguage = (): {
  changeLanguage: (language: Language) => void;
  isLoading: boolean;
} => {
  const {open: openNotification} = useNotification();
  const {t, i18n} = useTranslation(['language-select']);

  const changeLanguageMutation = useMutation(
    async (language: string) => {
      await i18n.changeLanguage(language);
    },
    {
      onError: () => {
        openNotification({
          message: t('errors.failedToChangeLanguage', {ns: 'language-select'}),
          severity: 'error',
        });
      },
    }
  );

  return {
    changeLanguage: changeLanguageMutation.mutate,
    isLoading: changeLanguageMutation.isLoading,
  };
};
