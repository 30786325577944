import {useMemo} from 'react';
import {LocalMoneyCalculator, Money} from '@handsin/money';
import _ from 'lodash';
import type {GroupPaymentRecord} from '@local/backend/@types/updated-api-types/group-payments/GroupPaymentRecord';
import type {
  AllocatedItem,
  ItemAllocations,
} from '@local/backend/@types/updated-api-types/group-payments/ItemAllocations';
import {LineItem} from '@local/frontend/@types/updated-api-types/items/LineItem';
import useSbiContext from '@local/frontend/hooks/useSbiContext';

interface SplitByItemHelper {
  lineItems: LineItem[];
  lineItemStockTracker: Map<string, LineItem>;
  itemAlloc: ItemAllocations;
  quantityOfLineItemsRemaining: number;
  areAllLineItemsAllocated: boolean;
  currentAvailableLineItems: LineItem[];
  allocate: (customerId: string, itemId: string, quantity: number) => void;
  deallocate: (customerId: string, itemId: string, quantity?: number) => void;
  calculateShareOf: (customerId: string) => Money;
  getAllocatedItemCount: (customerId: string) => number;
  getAvailableLineItem: (itemId: string) => LineItem | undefined;
  getCustomerIdsForAllocatedLineItem: (lineItemId: string) => string[];
  getRemainingQuantityAvailable: (lineItemId: string) => number;
}

export const useSplitByItemHelper = (
  groupPayment: GroupPaymentRecord
): SplitByItemHelper => {
  const {itemAlloc, setItemAlloc, lineItems} = useSbiContext();

  // A Map from itemId -> LineItem + currentQuantityAvailable
  const lineItemStockTracker = useMemo<Map<string, LineItem>>(() => {
    // A Map from itemId -> LineItem + initialQuantity
    const initialStock = new Map<string, LineItem>(
      lineItems.map((lineItem) => [lineItem.item.id, lineItem])
    );

    return Object.values(itemAlloc).reduce((map, allocatedItems) => {
      allocatedItems.forEach((allocatedItem) => {
        const lineItemStock = map.get(allocatedItem.itemId);

        if (!lineItemStock) {
          throw new Error('Something went wrong');
        } else {
          map.set(allocatedItem.itemId, {
            ...lineItemStock,
            quantity: lineItemStock.quantity - allocatedItem.quantity,
          });
        }
      });

      return map;
    }, new Map<string, LineItem>(initialStock));
  }, [itemAlloc]);

  const quantityOfLineItemsRemaining = useMemo(
    () =>
      Array.from(lineItemStockTracker.values()).reduce(
        (total, allocatedLineItem) => total + allocatedLineItem.quantity,
        0
      ),

    [itemAlloc]
  );

  // all the currently available line items determined by if quantity > 0
  const currentAvailableLineItems = useMemo(
    () =>
      Array.from(lineItemStockTracker.values()).reduce<LineItem[]>(
        (acc, lineitem) => {
          const stockItem = lineItemStockTracker.get(lineitem.item.id);

          if (stockItem && stockItem.quantity > 0) {
            acc.push({
              item: stockItem.item,
              quantity: stockItem.quantity,
              subtotalMoney: new LocalMoneyCalculator(
                stockItem.item.amountMoney
              )
                .multiply(stockItem.quantity)
                .calculate(),
              totalMoney: new LocalMoneyCalculator(stockItem.item.amountMoney)
                .multiply(stockItem.quantity)
                .calculate(),
            });
          }

          return acc;
        },
        []
      ),
    [lineItemStockTracker, itemAlloc]
  );

  // calculate if all line items have been allocated
  const areAllLineItemsAllocated = useMemo(
    () =>
      // check every line item is allocated
      _.every(groupPayment.lineItems, (lineItem: LineItem) => {
        if (!groupPayment.itemAllocation) {
          return true;
        }

        // calculate the total allocated quantity for this line item
        const totalAllocatedQuantity = Object.values(
          groupPayment.itemAllocation
        )
          .flatMap((allocatedItems) => allocatedItems)
          .filter((allocatedItem) => allocatedItem.itemId === lineItem.item.id)
          .reduce((total, item) => total + item.quantity, 0);

        return totalAllocatedQuantity >= lineItem.quantity;
      }),
    [groupPayment]
  );

  const getRemainingQuantityAvailable = (lineItemId: string): number =>
    currentAvailableLineItems.find(
      (lineItem) => lineItem.item.id === lineItemId
    )?.quantity ?? 0;

  const getAvailableLineItem = (itemId: string): LineItem | undefined =>
    currentAvailableLineItems.find(
      (availableItem) => availableItem.item.id === itemId
    );

  const allocate = (customerId: string, itemId: string, quantity: number) => {
    // only allocate quantity if the item is available
    if (!getAvailableLineItem(itemId)) {
      return;
    }

    const currentCustomerItemAllocation = itemAlloc[customerId] ?? [];

    const newItem = {itemId, quantity};
    const allocatedItemsIds = currentCustomerItemAllocation.map(
      (item) => item.itemId
    );

    let updatedCustomerItemAllocation: AllocatedItem[];

    // check if the item being allocated has already been allocated to them, then update the allocation's quantity accordingly
    if (allocatedItemsIds.includes(itemId)) {
      updatedCustomerItemAllocation = currentCustomerItemAllocation.map(
        (allocatedItem) => {
          if (allocatedItem.itemId === newItem.itemId) {
            return newItem;
          }
          return allocatedItem;
        }
      );
    } else {
      // otherwise add the new item to their allocation
      updatedCustomerItemAllocation = currentCustomerItemAllocation.concat([
        newItem,
      ]);
    }

    const updatedItemAllocations = {
      ...itemAlloc,
      [customerId]: updatedCustomerItemAllocation,
    };

    setItemAlloc(updatedItemAllocations);
  };

  const deallocate = (
    customerId: string,
    itemId: string,
    quantity?: number
  ) => {
    const currentCustomerItemAllocation = itemAlloc[customerId] ?? [];

    let updatedCustomerItemAllocation: AllocatedItem[];

    if (!quantity) {
      // when no quantity is provided, we remove the item from the customer's item allocation
      updatedCustomerItemAllocation = currentCustomerItemAllocation.filter(
        (allocatedItem) => allocatedItem.itemId !== itemId
      );
    } else {
      updatedCustomerItemAllocation = currentCustomerItemAllocation.map(
        (allocatedItem) => {
          if (allocatedItem.itemId === itemId) {
            return {itemId, quantity};
          }

          return allocatedItem;
        }
      );
    }

    const updatedItemAllocations = {
      ...itemAlloc,
      [customerId]: updatedCustomerItemAllocation,
    };

    setItemAlloc(updatedItemAllocations);
  };

  const calculateShareOf = (customerId: string): Money => {
    const customerItemAllocation = itemAlloc[customerId] ?? [];

    return customerItemAllocation.reduce(
      (totalMoney, allocatedItem) => {
        const allocatedLineItem = lineItemStockTracker.get(
          allocatedItem.itemId
        );

        if (!allocatedLineItem) {
          return totalMoney;
        }

        return new LocalMoneyCalculator(allocatedLineItem.item.amountMoney)
          .multiply(allocatedItem.quantity)
          .add(totalMoney)
          .calculate();
      },
      {amount: 0, currency: groupPayment.totalMoney.currency}
    );
  };

  const getAllocatedItemCount = (customerId: string): number => {
    const items = itemAlloc[customerId];
    if (!items) {
      return 0;
    }

    return items.reduce(
      (totalAllocatedItemQuantity, allocatedItem) =>
        totalAllocatedItemQuantity + allocatedItem.quantity,
      0
    );
  };

  const getCustomerIdsForAllocatedLineItem = (lineItemId: string): string[] =>
    Object.keys(itemAlloc).filter((customerId) => {
      const items = itemAlloc[customerId];
      if (!items) {
        return false;
      }

      return items.some((allocatedItem) => allocatedItem.itemId === lineItemId);
    });

  return {
    lineItemStockTracker,
    lineItems,
    itemAlloc,
    quantityOfLineItemsRemaining,
    allocate,
    deallocate,
    calculateShareOf,
    getAllocatedItemCount,
    currentAvailableLineItems,
    getAvailableLineItem,
    areAllLineItemsAllocated,
    getCustomerIdsForAllocatedLineItem,
    getRemainingQuantityAvailable,
  };
};
