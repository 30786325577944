import {QueryClient} from '@tanstack/react-query';
import {getCustomerById} from '../libs/api/customer.actions';
import {getFeedback} from '../libs/api/feedback.actions';
import {RelevantCheckoutIdsMap} from '../hooks/queries/@types';

const prefetchQueriesForGroupPaymentTerminalRoute = async (
  queryClient: QueryClient
): Promise<void> => {
  const ids = queryClient.getQueryData<RelevantCheckoutIdsMap>(['ids']);

  if (ids && ids.customerId) {
    const referenceId = `FHC_${JSON.stringify(ids)}`;

    await queryClient.prefetchQuery(['feedback', ids.customerId], () =>
      ids.customerId ? getFeedback(referenceId, ids.customerId) : undefined
    );

    await queryClient.prefetchQuery(['customer', ids.customerId], () =>
      ids.customerId ? getCustomerById(ids.customerId) : undefined
    );
  }
};

export default prefetchQueriesForGroupPaymentTerminalRoute;
