import {Country, Email} from '@handsin/api-node';
import {AxiosResponse} from 'axios';
import type {GroupPaymentRecord} from '@local/backend/@types/updated-api-types/group-payments/GroupPaymentRecord';
import {PaymentRecord} from '@local/frontend/@types/updated-api-types/payments/PaymentRecord';
import {SendEmailMutationParams} from '@local/frontend/hooks/mutations/@types/emails';
import {GroupPaymentCustomerShareMap} from '../../@types/updated-api-types/group-payments/GroupPaymentCustomerShareMap';
import axios from '../../config/backend';
import {
  CancelGroupPaymentMutationParams,
  CreateGroupPaymentMutationParams,
  JoinGroupPaymentMutationParams,
  KickFromGroupPaymentMutationParams,
  LeaveGroupPaymentMutationParams,
  PayIntoGroupPaymentMutationParams,
  UpdateGroupPaymentMutationParams,
} from '../../hooks/mutations/@types';
import {
  createOrResetIdempKey,
  getIdempKey,
} from '../../util/idemp-key-handlers';
import {CreateGroupPaymentRequest} from '../../@types/updated-api-types/group-payments/CreateGroupPaymentRequest';

// Get a group payment
export const getGroupPayment = async (
  groupPaymentId: string
): Promise<GroupPaymentRecord> => {
  const res: AxiosResponse<GroupPaymentRecord> = await axios.get(
    `/group-payments/${groupPaymentId}`
  );
  return res.data;
};

// Get the public accessible details from a group payment
export const getGroupPaymentPublicDetails = async (
  groupPaymentId: string,
  merchantId: string
): Promise<GroupPaymentRecord> => {
  const res: AxiosResponse<GroupPaymentRecord> = await axios.get(
    `/group-payments/${groupPaymentId}/public?merchantId=${merchantId}`
  );
  return res.data;
};

// update a group payment
export const updateGroupPayment = async ({
  groupPayment,
  updateGroupPaymentParams,
}: UpdateGroupPaymentMutationParams): Promise<GroupPaymentRecord> => {
  const res: AxiosResponse<GroupPaymentRecord> = await axios.patch(
    `/group-payments/${groupPayment.id}/update`,
    {
      updateGroupPaymentParams: {
        ...updateGroupPaymentParams,
        disableNotifications:
          updateGroupPaymentParams?.disableNotifications ?? false,
      },
      ownerId: groupPayment?.ownerId,
    }
  );
  return res.data;
};

// Join a group payment
export const joinGroupPayment = async ({
  customer,
  groupPaymentId,
}: JoinGroupPaymentMutationParams): Promise<GroupPaymentRecord> => {
  const res: AxiosResponse<GroupPaymentRecord> = await axios.patch(
    `/group-payments/${groupPaymentId}/join`,
    {
      customer,
    }
  );
  return res.data;
};

// Leave a group payment
export const leaveGroupPayment = async ({
  groupPaymentId,
  customer,
}: LeaveGroupPaymentMutationParams): Promise<GroupPaymentRecord> => {
  const res: AxiosResponse<GroupPaymentRecord> = await axios.patch(
    `/group-payments/${groupPaymentId}/leave`,
    {
      customer,
    }
  );
  return res.data;
};

// Pay into a group payment
export const payIntoGroupPayment = async (
  payIntoParams: PayIntoGroupPaymentMutationParams
): Promise<PaymentRecord> => {
  const idempotencyKey =
    getIdempKey(
      payIntoParams.groupPayment.id,
      payIntoParams.onBehalfOf?.id ?? payIntoParams.customer.id
    ) ??
    createOrResetIdempKey(
      payIntoParams.groupPayment.id,
      payIntoParams.onBehalfOf?.id ?? payIntoParams.customer.id
    );

  const res: AxiosResponse<PaymentRecord> = await axios.post(
    `/group-payments/${payIntoParams.groupPayment.id}/pay`,
    {
      ...payIntoParams,
      idempotencyKey,
    }
  );
  return res.data;
};

// Create a group payment
export const createGroupPayment = async ({
  idempotencyKey,
  createGroupPaymentFormValues,
  checkoutId,
  lineItemParams,
  amountMoney,
  splitAllocation,
  expirationDate,
}: CreateGroupPaymentMutationParams): Promise<GroupPaymentRecord> => {
  const country = Object.values(Country).find(
    (value) => value === createGroupPaymentFormValues.country
  );

  if (!country) {
    throw new Error('No country found');
  }

  const createGroupPaymentRequestParams: CreateGroupPaymentRequest = {
    idempotencyKey,
    checkoutId,
    expirationDate,
    amountMoney,
    splitAllocation,
    customer: {
      firstName: createGroupPaymentFormValues.firstName,
      lastName: createGroupPaymentFormValues.lastName,
      email: createGroupPaymentFormValues.email,
      phoneNumber: createGroupPaymentFormValues.phoneNumber,
    },
    shippingAddress: {
      name: createGroupPaymentFormValues.fullName,
      addressLines: {
        addressLine1: createGroupPaymentFormValues.address,
      },
      country,
      city: createGroupPaymentFormValues.city,
      postalCode: createGroupPaymentFormValues.zipCode,
    },
    lineItemParams,
  };

  const res: AxiosResponse<GroupPaymentRecord> = await axios.post(
    '/group-payments',
    {
      createGroupPaymentRequestParams,
    }
  );

  return res.data;
};

// Kick a member from a group payment
export const kickFromGroupPayment = async ({
  groupPayment,
  ownerId,
  customerToKick,
}: KickFromGroupPaymentMutationParams) => {
  const res: AxiosResponse<GroupPaymentRecord> = await axios.patch(
    `/group-payments/${groupPayment.id}/kick`,
    {
      ownerId,
      customer: customerToKick,
      merchantId: groupPayment.merchantId,
    }
  );
  return res.data;
};

// get all customer shares from a group payment
export const getGroupPaymentCustomerShares = async (
  groupPaymentId: string
): Promise<GroupPaymentCustomerShareMap> => {
  const res: AxiosResponse<GroupPaymentCustomerShareMap> = await axios.get(
    `/group-payments/${groupPaymentId}/calculate`
  );
  return res.data;
};

export const cancelGroupPayment = async ({
  groupPaymentId,
  customerId,
  reason,
}: CancelGroupPaymentMutationParams): Promise<GroupPaymentRecord> => {
  const res: AxiosResponse<GroupPaymentRecord> = await axios.post(
    `/group-payments/${groupPaymentId}/cancel`,
    {customerId, reason}
  );
  return res.data;
};

// Send an email with hands in email service through our api
export const sendEmail = async ({
  emailDetails,
  groupPaymentId,
  ownerId,
}: SendEmailMutationParams): Promise<Email> => {
  const res: AxiosResponse<Email> = await axios.post(
    `/group-payments/${groupPaymentId}/sendEmail`,
    {
      emailDetails,
      ownerId,
    }
  );
  return res.data;
};
