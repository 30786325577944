import React, {lazy} from 'react';
import {Outlet, RouteObject} from 'react-router-dom';

const RedirectPage = lazy(
  () => import(/* webpackChunkName: "redirect-page" */ '../RedirectPage')
);

export const redirectRoutes: RouteObject = {
  path: 'r',
  element: <Outlet />,
  children: [
    {
      path: ':proxyId',
      element: <RedirectPage />,
    },
  ],
};
