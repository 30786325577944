import {Outlet, createBrowserRouter} from 'react-router-dom';
import React, {lazy} from 'react';
import {groupPaymentRoutes} from './group-payments/routes';
import {checkoutRoutes} from './checkouts/routes';
import {redirectRoutes} from './redirect/routes';
import {multiCardRoutes} from './multi-cards/routes';
import LanguageWrapper from '../wrappers/LanguageWrapper';
import CountryContextProvider from '../providers/CountryContextProvider';
import config from '../config';
import IntercomLauncherProvider from '../providers/IntercomLauncherProvider';
import CustomIntercomProvider from '../providers/CustomIntercomProvider';
import AxiosWrapper from '../wrappers/AxiosWrapper';
import MaintenanceRedirectWrapper from '../wrappers/MaintenanceRedirectWrapper';

const ErrorPage = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "error-page" */ './ErrorPage'
    )
);

const NotFoundPage = lazy(
  () => import(/* webpackChunkName: "not-found-page" */ './NotFoundPage')
);

const UnderMaintenancePage = lazy(
  () =>
    import(
      /* webpackChunkName: "under-maintenance-page" */ './UnderMaintenancePage'
    )
);

const CommonProviders: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => (
  <IntercomLauncherProvider>
    <CustomIntercomProvider
      appId={config.REACT_APP_INTERCOM_APP_ID}
      autoBoot={false}
    >
      <AxiosWrapper>
        <LanguageWrapper>
          <CountryContextProvider>{children}</CountryContextProvider>
        </LanguageWrapper>
      </AxiosWrapper>
    </CustomIntercomProvider>
  </IntercomLauncherProvider>
);

export const routes = createBrowserRouter([
  {
    path: '/',
    element: (
      <CommonProviders>
        <MaintenanceRedirectWrapper>
          <Outlet />
        </MaintenanceRedirectWrapper>
      </CommonProviders>
    ),
    errorElement: <ErrorPage errorMessage="Oops! Something went wrong." />, // @TODO: create a nice error page (nice to have)
    children: [
      checkoutRoutes,
      groupPaymentRoutes,
      multiCardRoutes,
      redirectRoutes,
    ],
  },
  {
    path: 'under-maintenance',
    element: (
      <CommonProviders>
        <UnderMaintenancePage />
      </CommonProviders>
    ),
  },
  {
    path: '*',
    element: (
      <CommonProviders>
        <NotFoundPage />
      </CommonProviders>
    ),
  },
]);
