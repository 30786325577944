import {Country} from '@handsin/api-node';
import {
  MuiTelInput,
  MuiTelInputCountry,
  MuiTelInputProps,
  FlagsMenuProps,
} from '@local/mui-phone-input';
import React, {FC, forwardRef} from 'react';
import {Box, Button, createFilterOptions, Typography} from '@mui/material';
import SearchableMenu from '../SearchableMenu/SearchableMenu';
import CountryFlag from '../../CountryFlag';

export interface PhoneInputProps
  extends Omit<
    MuiTelInputProps,
    | 'defaultCountry'
    | 'onChange'
    | 'forceCallingCode'
    | 'getFlagElement'
    | 'onlyCountries'
    | 'MenuComponent'
  > {
  defaultCountry?: Country;
  onNumberChange: (phoneNumber: string | null) => void;
  onCountryChange?: (country: Country | null) => void;
}

const FlagsMenu: FC<FlagsMenuProps> = ({
  isoCode,
  countryOptions,
  onSelectCountry,
  getFlagElement,
  anchorEl,
  open,
  onClose,
}) => (
  <SearchableMenu
    placement="bottom-start"
    anchorEl={typeof anchorEl === 'function' ? anchorEl() : anchorEl}
    open={open || false}
    onClose={() => onClose?.({}, 'backdropClick')}
    searcherProps={{
      disableClearable: true,
      freeSolo: false,
      multiple: false,
      value: countryOptions.find((country) => country.code === isoCode),
      options: countryOptions,
      getOptionLabel: (option) => option.displayName,
      onChange: (_, country) => onSelectCountry(country.code),
      filterOptions: createFilterOptions({
        matchFrom: 'any',
        ignoreAccents: true,
        ignoreCase: true,
        trim: true,
        stringify: (option) =>
          `${option.displayName} +${option.callingCode} ${option.code}`,
      }),
      renderOption: (optionProps, option) => (
        <Box component="li" {...optionProps}>
          <Button
            variant="text"
            color="inherit"
            fullWidth
            size="medium"
            style={{
              justifyContent: 'flex-start',
              textAlign: 'left',
              textTransform: 'none',
            }}
            startIcon={getFlagElement(option.code, {
              countryName: option.displayName,
              isSelected: false,
              imgProps: {},
            })}
          >
            <Typography>{option.displayName}</Typography>
            <Typography sx={{ml: 'auto'}}>+{option.callingCode}</Typography>
          </Button>
        </Box>
      ),
    }}
  />
);

const PhoneInput = forwardRef<HTMLDivElement, PhoneInputProps>(
  ({onNumberChange, onCountryChange, defaultCountry, ...props}, ref) => (
    <MuiTelInput
      {...props}
      forceCallingCode
      ref={ref}
      MenuComponent={FlagsMenu}
      onlyCountries={Object.values(Country) as MuiTelInputCountry[]}
      defaultCountry={defaultCountry as MuiTelInputCountry}
      onChange={(value, info) => {
        onNumberChange(info.numberValue ?? value);
        onCountryChange?.(info.countryCode as Country | null);
      }}
      // eslint-disable-next-line react/no-unstable-nested-components
      getFlagElement={(isoCode) => <CountryFlag country={isoCode} />}
    />
  )
);

PhoneInput.displayName = 'PhoneInput';

export default PhoneInput;
