import Grid from '@mui/material/Grid';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import React, {FC} from 'react';
import Typography from '@mui/material/Typography';
import {MerchantDTO as Merchant} from '@handsin/api-node';
import {useTranslation} from 'react-i18next';
import MerchantAvatar from '../../../../atoms/avatars/MerchantAvatar';

interface MerchantInfoProps {
  merchant: Merchant;
}

const MerchantInfo: FC<React.PropsWithChildren<MerchantInfoProps>> = ({
  merchant,
}) => {
  const {t} = useTranslation(['modals']);
  return (
    <Stack direction="row" spacing={2}>
      {merchant.logoUrl && (
        <MerchantAvatar
          src={merchant.logoUrl}
          alt={t(
            'joinGroupModal.components.joinGroupOverview.merchantInfo.avatar.altText',
            {ns: 'modals'}
          )}
        />
      )}
      <Grid container alignItems="center">
        <ListItemText
          primary={
            <Typography
              variant="body2"
              color="primary.dark"
              sx={{fontWeight: 400}}
            >
              {t(
                'joinGroupModal.components.joinGroupOverview.merchantInfo.title',
                {ns: 'modals'}
              )}
            </Typography>
          }
          secondary={merchant.name}
          sx={{mt: 0}}
        />
      </Grid>
    </Stack>
  );
};

export default MerchantInfo;
