import {AxiosError} from 'axios';
import {QueryClient, UseMutationResult} from '@tanstack/react-query';
import type {Namespace, TFunction} from 'i18next';
import type {GroupPaymentRecord} from '@local/backend/@types/updated-api-types/group-payments/GroupPaymentRecord';
import {ModalQueryData} from '@local/frontend/libs/modals/ModalQueryData';
import {ModalName} from '@local/frontend/libs/modals/ModalName';
import {handleCloseModal} from '@local/frontend/libs/modals/helpers';
import type {DefaultResponseError} from '@local/backend/@types/updated-api-types/DefaultResponseError';
import {UseTRPCMutationResult} from '@trpc/react-query/shared';
import {TrpcError} from '@local/frontend/libs/trpc/trpc';
import {MIN_GROUP_SIZE} from '../../../../constants';
import {
  KickFromGroupPaymentMutationParams,
  RemoveFromGroupPaymentMutationParams,
  UpdateGroupPaymentMutationParams,
} from '../../../../hooks/mutations/@types';
import {GroupPaymentUpdateParams} from '../../../../@types/updated-api-types/group-payments/UpdateGroupPaymentParams';
import {Customer} from '../../../../@types/updated-api-types/customer/Customer';

export const handleKick = (
  queryClient: QueryClient,
  updateGroupPaymentMutation: UseMutationResult<
    GroupPaymentRecord,
    AxiosError<DefaultResponseError>,
    UpdateGroupPaymentMutationParams,
    unknown
  >,
  kickFromGroupPaymentMutation: UseMutationResult<
    GroupPaymentRecord,
    AxiosError<DefaultResponseError>,
    KickFromGroupPaymentMutationParams,
    unknown
  >,
  removeFromGroupPaymentMutation: UseTRPCMutationResult<
    GroupPaymentRecord,
    TrpcError,
    RemoveFromGroupPaymentMutationParams,
    unknown
  >,
  groupPayment: GroupPaymentRecord,
  customerToKick: Customer,
  isDecreasingGroupSize: boolean | undefined,
  t: TFunction<Namespace>
): void => {
  const viewAllModal = queryClient.getQueryData<ModalQueryData>([
    'modalConfig',
    ModalName.VIEW_ALL_PARTICIPANTS,
  ]);

  // you shouldnt be able to do this anyway on the front end but just in case
  if (
    groupPayment.memberIds.includes(customerToKick.id) &&
    groupPayment.memberIds.length === 1
  ) {
    // show notification to inform merchant that they can't remove the last user. (this would cancel the group)
    queryClient.setQueryData(['notificationAlert'], {
      isShowing: true,
      message: t('body.components.participantActionsMenu.alerts.lastUser', {
        ns: 'dashboard',
      }),
      severity: 'error',
      timeToShow: 5000,
    });
    // exit out of function
    return;
  }

  // check if the member we are kicking is in the group
  if (groupPayment.memberIds.includes(customerToKick.id)) {
    kickFromGroupPaymentMutation.mutate(
      {
        groupPayment,
        ownerId: groupPayment.ownerId,
        customerToKick,
        isDecreasingGroupSize,
      },
      {
        onSuccess: () => {
          if (viewAllModal?.props.open) {
            handleCloseModal(queryClient, ModalName.VIEW_ALL_PARTICIPANTS);
          }
        },
      }
    );
    // exit out of function
    return;
  }

  // check if the member we are kicking is a non joined user (not in memberIds, but is in invited)
  if (
    !groupPayment.memberIds.includes(customerToKick.id) &&
    groupPayment.invited?.includes(customerToKick.id)
  ) {
    removeFromGroupPaymentMutation.mutate(
      {
        groupPaymentId: groupPayment.id,
        merchantId: groupPayment.merchantId,
        customerId: customerToKick.id,
      },
      {
        onSuccess: () => {
          // show notification to indicate remove was successful
          queryClient.setQueryData(['notificationAlert'], {
            isShowing: true,
            message: t(
              'body.components.participantActionsMenu.alerts.removeUser',
              {ns: 'dashboard'}
            ),
            severity: 'success',
            timeToShow: 5000,
          });

          const updateGroupPaymentParams: GroupPaymentUpdateParams = {
            disableNotifications: true,
          };

          // check if we should also reduce the group size when removing the non joined user
          if (
            isDecreasingGroupSize &&
            groupPayment.splitAllocation &&
            groupPayment.splitAllocation - 1 >= MIN_GROUP_SIZE
          ) {
            updateGroupPaymentParams.splitType = groupPayment.splitType;
            updateGroupPaymentParams.splitAllocation =
              groupPayment.splitAllocation - 1;
          }

          updateGroupPaymentMutation.mutate(
            {
              updateGroupPaymentParams,
              groupPayment,
            },
            {
              onSuccess: () => {
                if (viewAllModal?.props.open) {
                  handleCloseModal(
                    queryClient,
                    ModalName.VIEW_ALL_PARTICIPANTS
                  );
                }
              },
            }
          );
        },
      }
    );
  }
};
