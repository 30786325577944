import {Country, Language} from '@handsin/api-node';
import useCustomer from './data/customers';
import {getCountryByLanguage} from '../util';
import {useCurrentLanguage} from '../components/atoms/inputs/LanguageSelect/useCurrentLanguage';

const useCustomerCountry = (customerId: string | undefined): Country => {
  const customer = useCustomer(customerId);
  const currentLanguage = useCurrentLanguage();

  if (customer?.address?.country) {
    return customer.address.country;
  }

  return getCountryByLanguage(currentLanguage as Language);
};

export default useCustomerCountry;
