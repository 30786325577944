import type {GroupPaymentRecord} from '@local/backend/@types/updated-api-types/group-payments/GroupPaymentRecord';
import {getAllGroupMemberIds} from '@local/frontend/helpers/group-payments/getAllGroupMemberIds';

export const calcTotalCustomersInGroup = (
  groupPayment: GroupPaymentRecord
): number =>
  groupPayment.invited
    ? groupPayment.memberIds.concat(groupPayment.invited).length
    : groupPayment.memberIds.length;

export const calcGroupSize = (groupPayment: GroupPaymentRecord): number => {
  if (
    (groupPayment.splitType === 'EQUAL' ||
      groupPayment.splitType === 'FIXED_PRICE') &&
    groupPayment.splitAllocation
  ) {
    return groupPayment.splitAllocation;
  }

  if (groupPayment.splitType === 'BY_ITEM' && groupPayment.itemAllocation) {
    // note BY_ITEM does not have a maximum group size, so we use how many people are in the group to determine a SBI group size instead
    const numberOfJoinedAndAddedCustomers = getAllGroupMemberIds(groupPayment);
    return numberOfJoinedAndAddedCustomers.length;
  }

  throw new Error('Cannot calculate group size for invalid group');
};
