import React, {lazy} from 'react';
import {Outlet, RouteObject} from 'react-router-dom';
import NotificationProvider from '@local/frontend/providers/NotificationProvider';
import CheckoutProvider from '@local/frontend/providers/CheckoutProvider';
import CheckoutRedirectWrapper from '@local/frontend/providers/CheckoutRedirectWrapper';
import ModalProvider from '@local/frontend/libs/modals/ModalProvider';

const OwnerDetailsPage = lazy(
  () =>
    import(/* webpackChunkName: "owner-details-page" */ './OwnerDetailsPage')
);

const CheckoutTerminalPage = lazy(
  () =>
    import(
      /* webpackChunkName: "checkout-terminal-page" */ './CheckoutTerminalPage'
    )
);

export const checkoutRoutes: RouteObject = {
  path: '/c',
  element: (
    <NotificationProvider>
      <Outlet />
    </NotificationProvider>
  ),
  children: [
    {
      path: ':checkoutId',
      element: (
        <CheckoutProvider>
          <CheckoutRedirectWrapper>
            <ModalProvider>
              <Outlet />
            </ModalProvider>
          </CheckoutRedirectWrapper>
        </CheckoutProvider>
      ),
      children: [
        {
          index: true,
          element: <OwnerDetailsPage />,
        },
        {
          path: 't',
          element: <CheckoutTerminalPage />,
        },
      ],
    },
  ],
};
