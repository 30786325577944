import Grid from '@mui/material/Grid';
import {styled} from '@mui/material/styles';

export const selectedMemberBoxStyles = {
  backgroundColor: 'primary.dark',
  color: 'primary.contrastText',
  border: 'none',
  '&:hover': {
    cursor: 'pointer',
  },
};

export const memberBoxStyles = {
  backgroundColor: '#FFF',
  color: 'grey.500',
  border: 'solid 1px',
  borderColor: 'grey.500',
  '&:hover': {
    backgroundColor: 'primary.light',
    color: 'primary.contrastText',
    cursor: 'pointer',
  },
};

export const defaultMemberBoxStyles = {
  width: 60,
  height: 60,
  borderRadius: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.06)',
};

export const StyledMembersListContainer = styled(Grid)`
  overflow-x: auto;
  max-width: fit-content !important;
`;

interface BackgroundProps {
  src: string | undefined;
}

export const StyledImageBackground = styled('div')<BackgroundProps>`
  ${({src}) => `
background: url(${src});
background-position: center;
background-repeat: no-repeat;
background-size: cover;
background-blend-mode: darken;
filter: blur(2px);
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
opacity: 0.40;
height: 100%;
width: 100%;
`}
`;
