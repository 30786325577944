import {styled} from '@mui/material/styles';
import Link from '@mui/material/Link';

export const ClickableText = styled(Link)`
  ${({theme}) => `
  text-decoration: none;
  color: ${
    theme.palette.mode === 'light'
      ? theme.palette.primary.dark
      : theme.palette.primary.light
  };
  &:hover {
    font-weight: 500;
    color: ${theme.palette.primary.main};
    cursor: pointer;
  }
  `}
`;
