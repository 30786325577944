import {useQueries} from '@tanstack/react-query';
import React, {FC, Suspense} from 'react';
import {ModalQueryData} from './ModalQueryData';
import GlobalModal from './GlobalModal';
import modalConfig from './ModalConfig';

const ModalProvider: FC<
  React.PropsWithChildren<React.PropsWithChildren<unknown>>
> = ({children}) => {
  const initModals = useQueries({
    queries: Object.values(modalConfig).map((config) => ({
      queryKey: ['modalConfig', config.name],
      queryFn: (): ModalQueryData => ({
        name: config.name,
        props: config.defaultProps,
        vars: undefined,
      }),
      refetchOnWindowFocus: false,
    })),
  });

  return (
    <>
      {children}
      {initModals.map((modal) => {
        if (!modal.data) {
          return null;
        }

        const {Element} = modalConfig[modal.data.name];
        const vars = (modal.data.vars ?? {}) as React.ComponentProps<
          typeof Element
        >;
        return (
          <Suspense key={modal.data.name} fallback={null}>
            {modal.data?.props.open && (
              <GlobalModal name={modal.data.name} {...(modal.data.props ?? {})}>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-expect-error */}
                <Element {...vars} />
              </GlobalModal>
            )}
          </Suspense>
        );
      })}
    </>
  );
};

export default ModalProvider;
