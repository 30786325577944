import React, {FC} from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import LoadingButton from '../../atoms/buttons/LoadingButton';

interface ConfirmationDialogContentProps {
  handleReject: () => void;
  handleConfirm: () => void;
  title: string;
  description: string;
  isConfirmationLoading: boolean;
  buttonActions: {reject: string; accept: string};
}

const ConfirmationDialogContent: FC<
  React.PropsWithChildren<ConfirmationDialogContentProps>
> = ({
  handleConfirm,
  handleReject,
  title,
  description,
  isConfirmationLoading,
  buttonActions,
}) => {
  const {t} = useTranslation(['confirmation-dialog']);

  return (
    <>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <Alert severity="error">
          <AlertTitle>{t('subtitle', {ns: 'confirmation-dialog'})}</AlertTitle>
          <Typography variant="body2" sx={{whiteSpace: 'pre-line'}}>
            {description}
          </Typography>
        </Alert>
      </DialogContent>
      <DialogActions>
        <LoadingButton onClick={handleReject} disabled={isConfirmationLoading}>
          {buttonActions.reject}
        </LoadingButton>
        <LoadingButton
          onClick={handleConfirm}
          disabled={isConfirmationLoading}
          loading={isConfirmationLoading}
        >
          {buttonActions.accept}
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default ConfirmationDialogContent;
