import React, {FC} from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import {Controller, useFormContext, useWatch} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {Country} from '@handsin/api-node';
import Typography from '@mui/material/Typography';
import {useFeatureFlagVariantKey} from 'posthog-js/react';
import {featureFlags} from '@local/frontend/feature-flags';
import {GroupMemberFormValues} from './@types/group-member-form';
import LoadingButton from '../../atoms/buttons/LoadingButton';
import LocaleSelect from '../../atoms/inputs/LocaleSelect/LocaleSelect';
import PhoneInput from '../../atoms/inputs/PhoneInput/PhoneInput';

interface GroupMemberFormProps {
  onSubmit: (submitProps: GroupMemberFormValues) => Promise<void>;
  disabled?: boolean;
  loading?: boolean;
  buttonText?: string;
}

const GroupMemberForm: FC<React.PropsWithChildren<GroupMemberFormProps>> = ({
  onSubmit,
  loading = false,
  disabled = false,
  buttonText,
}) => {
  const {t} = useTranslation(['group-member-form', 'common']);
  const formMethods = useFormContext<GroupMemberFormValues>();
  const joinFormConversionVariants = useFeatureFlagVariantKey(
    featureFlags.joinFormConversion.key
  );

  const phoneNumberCountry = useWatch({
    control: formMethods.control,
    name: 'phoneNumberCountry',
  });

  return (
    <form onSubmit={formMethods.handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Controller
            name="firstName"
            control={formMethods.control}
            render={({field, fieldState}) => (
              <TextField
                {...field}
                fullWidth
                required
                label={t('form.firstName.label', {ns: 'common'})}
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="lastName"
            control={formMethods.control}
            render={({field, fieldState}) => (
              <TextField
                {...field}
                fullWidth
                label={t('form.lastName.label', {ns: 'common'})}
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
        {(joinFormConversionVariants ===
          featureFlags.joinFormConversion.variants.default ||
          joinFormConversionVariants ===
            featureFlags.joinFormConversion.variants.both ||
          joinFormConversionVariants ===
            featureFlags.joinFormConversion.variants.emailOnly) && (
          <Grid item xs={12}>
            <Controller
              name="email"
              control={formMethods.control}
              render={({field, fieldState}) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('form.email.label', {ns: 'common'})}
                  error={Boolean(fieldState.error)}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
        )}
        {(joinFormConversionVariants ===
          featureFlags.joinFormConversion.variants.default ||
          joinFormConversionVariants ===
            featureFlags.joinFormConversion.variants.both ||
          joinFormConversionVariants ===
            featureFlags.joinFormConversion.variants.phoneOnly) && (
          <Grid item xs={12}>
            <Controller
              control={formMethods.control}
              name="phoneNumber"
              render={({field, fieldState}) => (
                <PhoneInput
                  {...field}
                  onNumberChange={(value) => field.onChange(value)}
                  onCountryChange={(value) => {
                    if (value) {
                      formMethods.setValue(
                        'phoneNumberCountry',
                        value as Country
                      );
                    }
                  }}
                  defaultCountry={phoneNumberCountry ?? Country.GB}
                  fullWidth
                  label={t('form.phoneNumber.label', {
                    ns: 'common',
                  })}
                  placeholder={t('form.phoneNumber.placeholder', {
                    ns: 'common',
                  })}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs={12} sm>
          <Typography variant="caption" color="primary">
            {t('countryLanguage.label', {ns: 'group-member-form'})}
          </Typography>
        </Grid>

        <Grid item xs="auto">
          <LocaleSelect />
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            type="submit"
            variant="contained"
            fullWidth
            disabled={disabled}
            loading={loading}
          >
            {buttonText ?? t('buttonText.default', {ns: 'group-member-form'})}
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default GroupMemberForm;
