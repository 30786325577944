import type {LineItem} from '@local/backend/@types/updated-api-types/items/LineItem';
import Stack from '@mui/material/Stack';
import React, {FC} from 'react';
import Divider from '@mui/material/Divider';
import SbiMergedFlowLineItem from './SbiMergedFlowLineItem';

interface SbiMergedFlowLineItemListProps {
  lineItems: LineItem[];
  selectedCustomerId: string | undefined;
  viewOnly?: boolean;
}

const SbiMergedFlowLineItemList: FC<SbiMergedFlowLineItemListProps> = ({
  lineItems,
  selectedCustomerId,
  viewOnly,
}) => (
  <Stack direction="column" flex="auto" spacing={2} divider={<Divider />}>
    {lineItems.map((lineItem) => (
      <SbiMergedFlowLineItem
        key={lineItem.item.id}
        lineItem={lineItem}
        selectedCustomerId={selectedCustomerId}
        viewOnly={viewOnly}
      />
    ))}
  </Stack>
);

export default SbiMergedFlowLineItemList;
