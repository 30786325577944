import Grid from '@mui/material/Grid';
import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useQueryClient} from '@tanstack/react-query';
import {getQueryKey} from '@trpc/react-query';
import {type Money, MoneyUtils} from '@handsin/money';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {
  trpc,
  useCancelPaymentOfMultiCard,
} from '@local/frontend/libs/trpc/trpc';
import {useNotification} from '@local/frontend/hooks/useNotification';
import {useCustomModals} from '@local/frontend/libs/modals/useCustomModals';
import {ModalName} from '@local/frontend/libs/modals/ModalName';
import {
  IframeEvent,
  sendIframeMessage,
} from '@local/frontend/helpers/iframeMessenger';
import LoadingButton from '../../atoms/buttons/LoadingButton';
import ModalHeader from './ModalHeader';

interface CancelPaymentModalProps {
  cancelPaymentParams: {
    merchantId: string;
    multiCardId?: string;
    paymentId: string;
  };
  paymentDetails: {
    amount: Money;
    lastFourDigits?: string;
  };
  onCancel?: () => void;
}

const CancelPaymentModal: FC<
  React.PropsWithChildren<CancelPaymentModalProps>
> = ({cancelPaymentParams, onCancel, paymentDetails}) => {
  const {t} = useTranslation(['cancel-payment']);
  const queryClient = useQueryClient();
  const {open: openNotification} = useNotification();
  const {closeModal} = useCustomModals();

  const cancelPaymentMutation = useCancelPaymentOfMultiCard({
    onSuccess: async () => {
      openNotification({
        message: t('alerts.success.cancelPayment', {ns: 'cancel-payment'}),
        severity: 'success',
      });
      await queryClient.invalidateQueries(getQueryKey(trpc.multiCard.get));
      await queryClient.invalidateQueries(
        getQueryKey(trpc.payments.getMultiCardPayments)
      );
      if (onCancel) {
        onCancel();
      }
    },
    onError: (err) => {
      openNotification({
        message:
          err.data?.axiosError?.response?.data.detail ??
          t('alerts.errors.cancelPayment', {ns: 'cancel-payment'}),
        severity: 'error',
      });
    },
  });

  const handleClose = () => {
    closeModal(ModalName.CANCEL_PAYMENT);
  };

  const handleCancelMultiCardPayment = (cancelParams: {
    merchantId: string;
    multiCardId: string;
    paymentId: string;
  }) => {
    cancelPaymentMutation.mutate(cancelParams, {
      onSuccess: () => {
        sendIframeMessage({
          event: IframeEvent.PAYMENT_CANCELLED,
          data: {
            id: cancelParams.paymentId,
            merchantId: cancelParams.merchantId,
          },
        });
        handleClose();
      },
    });
  };

  // @TODO: handle in future if this confirmation modal is ever used for cancelling group payment payments
  if (!cancelPaymentParams.multiCardId) {
    return null;
  }

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <ModalHeader
          modalName={ModalName.CANCEL_PAYMENT}
          title={t('title', {ns: 'cancel-payment'})}
        />
      </Grid>
      <Grid item xs={12}>
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
          <Typography sx={{fontSize: 16, maxWidth: 400}} textAlign="center">
            {t('body', {
              ns: 'cancel-payment',
              paymentAmount: MoneyUtils.formatMoney(paymentDetails.amount),
              lastFourDigits: paymentDetails.lastFourDigits,
            })}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <LoadingButton
              fullWidth
              variant="outlined"
              disabled={cancelPaymentMutation.isLoading}
              onClick={handleClose}
              size="large"
            >
              {t('buttons.goBack', {ns: 'cancel-payment'})}
            </LoadingButton>
          </Grid>

          <Grid item xs={6}>
            <LoadingButton
              fullWidth
              variant="contained"
              loading={cancelPaymentMutation.isLoading}
              disabled={cancelPaymentMutation.isLoading}
              onClick={() =>
                cancelPaymentParams.multiCardId
                  ? handleCancelMultiCardPayment({
                      ...cancelPaymentParams,
                      multiCardId: cancelPaymentParams.multiCardId, // ts-compiler cannot infer from above return null check.
                    })
                  : undefined
              }
              size="large"
            >
              {t('buttons.cancel', {ns: 'cancel-payment'})}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CancelPaymentModal;
