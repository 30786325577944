import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import type {GroupPaymentRecord} from '@local/backend/@types/updated-api-types/group-payments/GroupPaymentRecord';
import {useNotification} from '@local/frontend/hooks/useNotification';
import {useCustomModals} from '@local/frontend/libs/modals/useCustomModals';
import {ModalName} from '@local/frontend/libs/modals/ModalName';
import {setDynamicPricingLocalStorageKey} from '@local/frontend/util/dynamic-pricing-key-handlers';
import {useUpdateGroupPayment} from '../../../../../hooks/mutations/groups-payments';
import LoadingButton from '../../../../atoms/buttons/LoadingButton';
import NoticeAlert from '../../../../atoms/NoticeAlert';
import ModalHeader from '../../ModalHeader';

interface DynamicPricingOptOutModalProps {
  handleClose?: () => void;
  groupPayment: GroupPaymentRecord | undefined;
  customerId: string | undefined;
}

const DynamicPricingOptOutModal: FC<
  React.PropsWithChildren<DynamicPricingOptOutModalProps>
> = ({handleClose, groupPayment, customerId}) => {
  const {t} = useTranslation(['modals', 'common']);
  const {closeModal, getModal} = useCustomModals();
  const {open: openNotification} = useNotification();
  const updateGroupPaymentMutation = useUpdateGroupPayment();

  const handleGoBack = () => {
    // handleClose is only defined when the modal is opened in dynamic pricing flow (aka on load / first time viewing)
    if (handleClose) {
      handleClose();
    } else {
      // otherwise, the modal was opened by the user specifically via the queryClient cache and so we need to close it via the queryClient cache
      closeModal(ModalName.DYNAMIC_PRICING_OWNER_OPT_OUT);
    }
  };

  const handleOptOutConfirmation = () => {
    if (!groupPayment) {
      openNotification({
        message: t('dynamicPricingOptOutModal.errors.noGroup', {ns: 'modals'}),
        severity: 'error',
      });
      return;
    }

    if (!customerId) {
      openNotification({
        message: t('dynamicPricingOptOutModal.errors.noCustomerId', {
          ns: 'modals',
        }),
        severity: 'error',
      });
      return;
    }

    updateGroupPaymentMutation.mutate(
      {
        groupPayment,
        updateGroupPaymentParams: {isDynamicPricingEnabled: false},
      },
      {
        onSuccess: () => {
          // set local storage key to prevent modal from automatically appearing again on page refreshes
          setDynamicPricingLocalStorageKey(
            groupPayment.id,
            customerId,
            'OWNER'
          );

          // show notificaion to owner confirming opt-out
          openNotification({
            message: t('dynamicPricingOptOutModal.alerts.success', {
              ns: 'modals',
            }),
            severity: 'success',
          });

          // close any dynamic pricing modals after opt-out update

          const dynamicPricingOverviewModal = getModal(
            ModalName.DYNAMIC_PRICING_OWNER_INFORMATION
          );

          if (dynamicPricingOverviewModal?.props.open) {
            closeModal(ModalName.DYNAMIC_PRICING_OWNER_INFORMATION);
          }

          const dynamicPricingOptOutModal = getModal(
            ModalName.DYNAMIC_PRICING_OWNER_OPT_OUT
          );

          if (dynamicPricingOptOutModal?.props.open) {
            closeModal(ModalName.DYNAMIC_PRICING_OWNER_OPT_OUT);
          }
        },
        onError: () => {
          openNotification({
            message: t('dynamicPricingOptOutModal.errors.failedToUpdate', {
              ns: 'modals',
            }),
            severity: 'error',
          });
        },
      }
    );
  };

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <ModalHeader
          title={t('dynamicPricingOptOutModal.title', {ns: 'modals'})}
          modalName={ModalName.DYNAMIC_PRICING_OWNER_OPT_OUT}
        />
      </Grid>
      <Grid item xs={10}>
        <NoticeAlert
          message={t('dynamicPricingOptOutModal.body.proceed', {ns: 'modals'})}
          severity="warning"
        />
      </Grid>
      <Grid item xs={10}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button variant="outlined" onClick={handleGoBack} fullWidth>
              {t('button.reject', {ns: 'common'})}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <LoadingButton
              variant="contained"
              onClick={handleOptOutConfirmation}
              fullWidth
              disabled={updateGroupPaymentMutation.isLoading}
              loading={updateGroupPaymentMutation.isLoading}
            >
              {t('button.accept', {ns: 'common'})}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DynamicPricingOptOutModal;
