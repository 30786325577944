import {AxiosResponse} from 'axios';
import type {CustomerCreationParams} from '@local/backend/@types/updated-api-types/customers/CustomerCreationParams';
import {Customer} from '../../@types/updated-api-types/customer/Customer';
import axios from '../../config/backend';
import {UpdateCustomerMutationParams} from '../../hooks/mutations/@types';

// Get a customer by id
export const getCustomerById = async (
  customerId: string
): Promise<Customer> => {
  const res: AxiosResponse<Customer> = await axios.get(
    `/customers/${customerId}`
  );
  return res.data;
};

export const getBatchCustomers = async (
  customerIds: string[]
): Promise<Customer[]> => {
  const res: AxiosResponse<Customer[]> = await axios.put('/customers/batch', {
    customerIds,
  });
  return res.data;
};

export const createCustomer = async (
  customerData: CustomerCreationParams
): Promise<Customer> => {
  const customer: AxiosResponse<Customer> = await axios.post('/customers', {
    customerData,
  });
  return customer.data;
};

export const updateCustomer = async ({
  customerId,
  customerUpdateParams,
}: UpdateCustomerMutationParams): Promise<Customer> => {
  const customer: AxiosResponse<Customer> = await axios.patch(
    `/customers/${customerId}`,
    {
      customerUpdateParams,
    }
  );
  return customer.data;
};

// Send an SMS with hands in sms service through our api
export const sendSms = async ({
  smsDetails,
  ownerId,
}: {
  smsDetails: {to: string; text: string};
  ownerId: string;
}): Promise<void> => {
  const res = await axios.post('/customers/sendSms', {smsDetails, ownerId});
  return res.data;
};
