import {useQueryClient} from '@tanstack/react-query';
import type {ComponentProps} from 'react';
import type {DialogProps} from '@mui/material/Dialog';
import type {ModalQueryData} from './ModalQueryData';
import type {ModalConfigType} from './ModalConfig';
import type {ModalName} from './ModalName';
import type CustomDialogProps from './@types/CustomDialogProps';

export const useCustomModals = (): {
  openModal: <T extends keyof ModalConfigType>(
    name: T,
    vars: ComponentProps<ModalConfigType[T]['Element']>,
    props?: Omit<CustomDialogProps, 'open'>
  ) => void;
  closeModal: <T extends keyof ModalConfigType>(name: T) => void;
  getModal: (name: ModalName) => ModalQueryData | undefined;
} => {
  const queryClient = useQueryClient();

  const getModal = (name: ModalName): ModalQueryData | undefined =>
    queryClient.getQueryData<ModalQueryData>(['modalConfig', name]);

  const openModal = <T extends keyof ModalConfigType>(
    name: T,
    vars: ComponentProps<ModalConfigType[T]['Element']>,
    props?: Omit<DialogProps, 'open'> & {
      onModalClose?: () => void;
      canClose?: boolean;
    }
  ): void => {
    queryClient.setQueryData<ModalQueryData>(
      ['modalConfig', name],
      (oldData) => ({
        name,
        props: {...oldData?.props, ...props, open: true},
        vars,
      })
    );
  };

  const closeModal = <T extends keyof ModalConfigType>(name: T): void => {
    const modal = queryClient.getQueryData<ModalQueryData>([
      'modalConfig',
      name,
    ]);

    if (modal && modal.props?.onModalClose) {
      modal.props.onModalClose();
    }
    queryClient.setQueryData<ModalQueryData>(
      ['modalConfig', name],
      (oldData) => ({
        name,
        props: {...oldData?.props, open: false},
        vars: undefined,
      })
    );
  };

  return {
    openModal,
    getModal,
    closeModal,
  };
};
