import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import React, {Dispatch, FC, SetStateAction} from 'react';
import {useTranslation} from 'react-i18next';
import {useQueryClient} from '@tanstack/react-query';
import {Customer} from '../../../@types/updated-api-types/customer/Customer';
import {sortCustomersByCreatedAt} from '../../../util';

interface ItemsSummaryMenuProps {
  isSummaryMenuOpen: boolean;
  anchorEl: HTMLElement | null;
  handleCloseSummaryMenu: () => void;
  selectedCustomerId: string | undefined;
  setSelectedCustomerId: Dispatch<SetStateAction<string | undefined>>;
  currentUserId: string | undefined;
}

const ItemsSummaryMenu: FC<React.PropsWithChildren<ItemsSummaryMenuProps>> = ({
  isSummaryMenuOpen,
  anchorEl,
  handleCloseSummaryMenu,
  selectedCustomerId,
  setSelectedCustomerId,
  currentUserId,
}) => {
  const {t} = useTranslation(['glossary']);
  const queryClient = useQueryClient();
  const customerTuples = queryClient.getQueriesData<Customer>({
    type: 'active',
    queryKey: ['customer'],
  });

  const customers = sortCustomersByCreatedAt(
    customerTuples
      .map((customerTuple) => customerTuple.at(1))
      .filter((maybeCustomer): maybeCustomer is Customer => !!maybeCustomer)
  );

  // sort customers to put current user at the top
  customers.sort((customerA: Customer) =>
    customerA.id === currentUserId ? -1 : 0
  );

  const renderMenuOptions = () => {
    const options = [t('groupOrder', {ns: 'glossary'}), ...customers];

    return options.map((option: Customer | string, index: number) => (
      <MenuItem
        key={typeof option === 'string' ? option : option.id}
        selected={
          typeof option === 'string'
            ? selectedCustomerId === option
            : selectedCustomerId === option.id
        }
        divider={index !== options.length - 1}
        onClick={() => {
          setSelectedCustomerId(
            typeof option === 'string' ? undefined : option.id
          );
          handleCloseSummaryMenu();
        }}
      >
        <ListItemText>
          {typeof option === 'string'
            ? option
            : `${option.firstName}'s ${t('item_other', {ns: 'glossary'})}`}
        </ListItemText>
      </MenuItem>
    ));
  };
  return (
    <Menu
      open={isSummaryMenuOpen}
      anchorEl={anchorEl}
      onClose={handleCloseSummaryMenu}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      sx={{zIndex: 3000, maxWidth: '100%'}}
    >
      <Paper
        sx={{
          width: 240,
          maxWidth: '100%',
          background: 'transparent',
        }}
        elevation={0}
      >
        <MenuList dense sx={{py: 0}}>
          {renderMenuOptions()}
        </MenuList>
      </Paper>
    </Menu>
  );
};

export default ItemsSummaryMenu;
