/* eslint-disable @typescript-eslint/ban-ts-comment */
import {Button, Stack, Avatar, Typography, Tooltip} from '@mui/material';
import React, {FC} from 'react';

interface SocialInviteButtonProps {
  tooltip?: string;
  href?: string;
  src: string;
  name: string;
  onClick?: () => void;
}

const SocialInviteButton: FC<
  React.PropsWithChildren<SocialInviteButtonProps>
> = ({href, src, name, onClick, tooltip}) => (
  <Tooltip arrow title={tooltip} PopperProps={{sx: {zIndex: 9000}}}>
    {/* @ts-ignore */}
    <Button
      variant="text"
      href={href}
      sx={{p: 1, bgcolor: 'grey.100', borderRadius: 1, boxShadow: 'none'}}
      target="_blank"
      rel="noopener noreferrer"
      onClick={onClick}
    >
      <Stack alignItems="center">
        <Avatar
          variant="rounded"
          sx={{
            width: 45,
            height: 45,
            aspectRatio: '1/1',
            objectFit: 'cover',
          }}
          src={src}
          alt={name}
        />
        <Typography variant="subtitle2" color="grey.600" textTransform="none">
          {name}
        </Typography>
      </Stack>
    </Button>
  </Tooltip>
);

SocialInviteButton.displayName = 'SocialInviteButton';

export default SocialInviteButton;
