import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import duration from 'dayjs/plugin/duration';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import 'dayjs/locale/es';
import 'dayjs/locale/nl';
import 'dayjs/locale/de';
import i18n from '../i18n.config';

// Configure Day.js locale based on the active language
const configureDayjsLocale = () => {
  dayjs.locale(i18n.resolvedLanguage ?? i18n.language);
};

// Listen for language changes and update Day.js locale
i18n.on('languageChanged', () => {
  configureDayjsLocale();
});

dayjs.extend(LocalizedFormat);

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);

// Initialize Day.js locale
configureDayjsLocale();

// Guess the users time zone
dayjs.tz.guess();

export default dayjs;
