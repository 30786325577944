import {QueryClient} from '@tanstack/react-query';
import {getCustomerById} from '../libs/api/customer.actions';
import {getFeedback} from '../libs/api/feedback.actions';
import {RelevantCheckoutIdsMap} from '../hooks/queries/@types';

const prefetchQueriesForGroupPaymentLeaveRoute = async (
  queryClient: QueryClient
): Promise<void> => {
  const ids = queryClient.getQueryData<RelevantCheckoutIdsMap>(['ids']);

  const customerId = ids?.customerId;

  await queryClient.prefetchQuery(['currentCustomerId'], () => customerId);

  if (ids && customerId) {
    const referenceId = `FHC_${JSON.stringify(ids)}`;

    await queryClient.prefetchQuery(['feedback', customerId], () =>
      getFeedback(referenceId, customerId)
    );

    await queryClient.prefetchQuery(['customer', customerId], () =>
      getCustomerById(customerId)
    );
  }
};

export default prefetchQueriesForGroupPaymentLeaveRoute;
