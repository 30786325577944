import {LocalMoneyCalculator, Money, MoneyUtils} from '@handsin/money';
import _ from 'lodash';
import type {LineItem} from '@local/backend/@types/updated-api-types/items/LineItem';
import type {GroupPaymentRecord} from '@local/backend/@types/updated-api-types/group-payments/GroupPaymentRecord';

export default class GroupPaymentHelper {
  constructor(private readonly groupPayment: GroupPaymentRecord) {}

  public isOwner(customerId: string): boolean {
    return customerId === this.groupPayment.ownerId;
  }

  public calculateLineItemsSubtotalMoney(): Money {
    const {lineItems, amountMoney} = this.groupPayment;

    if (!lineItems || _.isEmpty(lineItems)) {
      return {
        amount: 0,
        currency: this.groupPayment.amountMoney.currency,
      };
    }

    return lineItems
      .reduce(
        (calc, lineItem) => calc.add(lineItem.subtotalMoney),
        new LocalMoneyCalculator({
          amount: 0,
          currency: amountMoney.currency,
        })
      )
      .calculate();
  }

  public calculateLineItemsTotalMoney(): Money {
    const {lineItems, amountMoney} = this.groupPayment;

    if (!lineItems || _.isEmpty(lineItems)) {
      return {
        amount: 0,
        currency: this.groupPayment.amountMoney.currency,
      };
    }

    return lineItems
      .reduce(
        (calc, lineItem) => calc.add(lineItem.totalMoney),
        new LocalMoneyCalculator({
          amount: 0,
          currency: amountMoney.currency,
        })
      )
      .calculate();
  }

  public calculateNumberOfLineItems = (): number =>
    this.groupPayment.lineItems
      ?.map((lineItem: LineItem) => lineItem.quantity)
      .reduce((total, current) => total + current, 0) ?? 0;

  public createOrderSummary = (): string => {
    const {lineItems} = this.groupPayment;
    const lineItem = lineItems?.at(0);
    return !lineItem
      ? MoneyUtils.formatMoney(this.groupPayment.totalMoney)
      : `${lineItem.item.name}${
          lineItem.quantity > 1 ? ` x${lineItem.quantity}` : ''
        }`;
  };

  public getGroupSize(): number {
    switch (this.groupPayment.splitType) {
      case 'EQUAL':
      case 'FIXED_PRICE':
        if (this.groupPayment.splitAllocation) {
          return this.groupPayment.splitAllocation;
        }
        throw new Error(
          `Invalid group payment record. 'splitAllocation' must be defined in a ${
            this.groupPayment.splitType === 'EQUAL'
              ? 'equal split'
              : 'fixed price'
          } group payment`
        );
      case 'BY_ITEM': {
        return this.groupPayment.invited
          ? this.groupPayment.invited.concat(this.groupPayment.memberIds).length
          : this.groupPayment.memberIds.length;
      }
      default:
        throw new Error('Invalid Split Type');
    }
  }

  public isGroupPaymentExpired(): boolean {
    return this.groupPayment.expirationDate
      ? new Date() >= new Date(this.groupPayment.expirationDate) &&
          !['APPROVED', 'COMPLETED', 'CANCELLED'].includes(
            this.groupPayment.status
          )
      : false;
  }

  public getItemsAllocatedTo(customerId: string): LineItem[] | undefined {
    if (!this.groupPayment.itemAllocation || !this.groupPayment.lineItems) {
      return undefined;
    }

    const itemAllocation = this.groupPayment.itemAllocation[customerId];

    if (!itemAllocation) {
      return [];
    }

    const itemIdToItem = new Map<string, LineItem>(
      this.groupPayment.lineItems.map((lineItem) => [
        lineItem.item.id,
        lineItem,
      ])
    );

    return itemAllocation.map((allocatedItems) => {
      const lineItem = itemIdToItem.get(allocatedItems.itemId);

      if (!lineItem) {
        throw new Error(
          'Item that is not part of the payment, has been allocated to a customer in the group'
        );
      }

      return {
        item: lineItem.item,
        quantity: allocatedItems.quantity,
        subtotalMoney: new LocalMoneyCalculator(lineItem.subtotalMoney)
          .divide(lineItem.quantity)
          .multiply(allocatedItems.quantity)
          .calculate(),
        totalMoney: new LocalMoneyCalculator(lineItem.totalMoney)
          .divide(lineItem.quantity)
          .multiply(allocatedItems.quantity)
          .calculate(),
      };
    });
  }

  public hasJoinedGroup(customerId: string): boolean {
    return this.groupPayment.memberIds.some(
      (memberId) => memberId === customerId
    );
  }

  public getNumberOfAllocatedLineItems(): number {
    if (
      !this.groupPayment.itemAllocation ||
      _.isEmpty(this.groupPayment.itemAllocation)
    ) {
      return 0;
    }

    return Object.values(this.groupPayment.itemAllocation)
      .flatMap((allocatedItems) => allocatedItems)
      .reduce((total, item) => total + item.quantity, 0);
  }

  public hasAllItemsBeenAllocated(): boolean {
    if (!this.groupPayment.lineItems) {
      return false;
    }

    return (
      this.getNumberOfAllocatedLineItems() - this.getTotalLineItemQuantity() ===
      0
    );
  }

  public getTotalLineItemQuantity(): number {
    if (!this.groupPayment.lineItems) {
      return 0;
    }

    return this.groupPayment.lineItems.reduce(
      (total, lineItem) => total + lineItem.quantity,
      0
    );
  }

  public getTotalAllocatedItemCount(customerId: string): number {
    const items = this.groupPayment.itemAllocation?.[customerId];
    if (!items) {
      return 0;
    }

    return items.reduce((total, item) => total + item.quantity, 0);
  }
}
