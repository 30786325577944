import Grid from '@mui/material/Grid';
import React, {FC} from 'react';
import {useQueryClient} from '@tanstack/react-query';
import Box from '@mui/material/Box';
import type {GroupPaymentRecord} from '@local/backend/@types/updated-api-types/group-payments/GroupPaymentRecord';
import {ModalName} from '@local/frontend/libs/modals/ModalName';
import {useTranslation} from 'react-i18next';
import {sortCustomersByCreatedAt} from '../../../../util';
import ParticipantItem from '../../../group-dashboard/ParticipantsCard/components/ParticipantItem/ParticipantItem';
import {Customer} from '../../../../@types/updated-api-types/customer/Customer';
import ModalHeader from '../ModalHeader';

interface ViewAllParticipantsModalProps {
  groupPayment: GroupPaymentRecord;
  memberIds: (string | null)[]; // null represents slots/spaces for members to be added
}

const ViewAllParticipantsModal: FC<
  React.PropsWithChildren<ViewAllParticipantsModalProps>
> = ({groupPayment, memberIds}) => {
  const {t} = useTranslation(['modals']);
  const queryClient = useQueryClient();
  const customerTuples = queryClient.getQueriesData<Customer>(['customer']);

  const customers = sortCustomersByCreatedAt(
    customerTuples
      .map((customerTuple) => customerTuple[1])
      .filter((maybeCustomer): maybeCustomer is Customer => !!maybeCustomer)
      .filter(
        (customer) =>
          // filter only those who are included in the item allocation list
          groupPayment.itemAllocation &&
          Object.keys(groupPayment.itemAllocation).includes(customer.id)
      )
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ModalHeader
          title={t('viewAllParticipantsModal.title', {ns: 'modals'})}
          modalName={ModalName.VIEW_ALL_PARTICIPANTS}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {(groupPayment.splitType === 'EQUAL' ||
            groupPayment.splitType === 'FIXED_PRICE') &&
            memberIds.map((memberId: string | null, index: number) => (
              <Grid
                item
                xs={12}
                md={6}
                key={memberId ?? `member-slot-${index}`}
              >
                <Box sx={{border: 1, borderColor: 'grey.300', borderRadius: 2}}>
                  <ParticipantItem
                    participantId={memberId ?? undefined}
                    groupPayment={groupPayment}
                    sx={{height: '100px'}}
                  />
                </Box>
              </Grid>
            ))}

          {groupPayment.splitType === 'BY_ITEM' &&
            customers.map((customer: Customer) => (
              <Grid item xs={12} md={6} key={customer.id}>
                <Box sx={{border: 1, borderColor: 'grey.300', borderRadius: 2}}>
                  <ParticipantItem
                    participantId={customer.id}
                    groupPayment={groupPayment}
                    sx={{height: '100px'}}
                  />
                </Box>
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ViewAllParticipantsModal;
