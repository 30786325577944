export enum FeedbackService {
  GROUP_PAYMENTS = 'group-payments',
}

export interface CreateFeedbackMutationParams {
  referenceId: string;
  satisfactionRating: number;
  customerId: string;
  review?: string;
  source?: FeedbackService;
  email: string;
  name?: string;
  phoneNumber?: string;
}
