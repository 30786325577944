import React from 'react';
import {createFilterOptions} from '@mui/material/Autocomplete';
import {Country} from '@handsin/api-node';
import {
  Box,
  Button,
  InputAdornment,
  SelectProps,
  TextField,
  Typography,
} from '@mui/material';
import {getCountryName} from '@local/frontend/helpers/country';
import {useTranslation} from 'react-i18next';
import {useMerchantStyle} from '@local/frontend/hooks/useMerchantStyle';
import {CountryName} from '../../../@types/iso/CountryName';
import CountryFlag from '../CountryFlag';
import SearchableMenu from './SearchableMenu/SearchableMenu';

interface FilterCountryOption {
  name: CountryName;
  code: string;
}

function countryCodeToOption(
  countryCode: Country
): FilterCountryOption | undefined {
  const countryName: CountryName | undefined = getCountryName(countryCode);
  return countryName ? {code: countryCode, name: countryName} : undefined;
}

export type CountrySelectProps = Omit<
  SelectProps,
  'value' | 'defaultValue' | 'onChange'
> & {
  value?: Country; // countryCode
  defaultValue?: Country; // countryCode
  onChange: (countryCode: Country) => void;
};

const CountrySelect = React.forwardRef<HTMLDivElement, CountrySelectProps>(
  ({value, defaultValue, onChange, ...props}, ref) => {
    const merchantStyles = useMerchantStyle();
    const defaultSelected = defaultValue
      ? countryCodeToOption(defaultValue)
      : undefined;
    const selected = value ? countryCodeToOption(value) : undefined;
    const [menuAnchorEl, setmenuAnchorEl] = React.useState<null | HTMLElement>(
      null
    );
    const handleToggleMenu = (event: React.MouseEvent<HTMLElement>) => {
      setmenuAnchorEl(menuAnchorEl ? null : event.currentTarget);
    };
    const {t} = useTranslation('country-language-popper');

    return (
      <>
        <TextField
          ref={ref}
          select
          label={props.label}
          onClick={handleToggleMenu}
          defaultValue={defaultValue}
          value={value}
          InputProps={{
            sx: {fontFamily: merchantStyles?.fontFamily},
            readOnly: true,
            startAdornment: value ? (
              <InputAdornment position="start">
                <CountryFlag country={value} />
              </InputAdornment>
            ) : undefined,
          }}
        >
          <option value={selected?.code}>{selected?.name}</option>
        </TextField>
        <SearchableMenu
          anchorEl={menuAnchorEl}
          open={!!menuAnchorEl}
          onClose={() => setmenuAnchorEl(null)}
          searcherProps={{
            noOptionsText: t('countrySelect.notFound', 'No country found'),
            multiple: false,
            freeSolo: false,
            disableClearable: true,
            autoComplete: true,
            defaultValue: defaultSelected, // need null to prevent MUI control errors
            value: selected, // need null to prevent MUI control errors
            options: Object.entries(CountryName)
              .map(([countryCode, countryName]) => ({
                name: countryName,
                code: countryCode,
              }))
              .sort((a: FilterCountryOption, b: FilterCountryOption) =>
                a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
              ),
            isOptionEqualToValue: (
              a: FilterCountryOption,
              b: FilterCountryOption
            ) => a.code === b.code,
            filterOptions: createFilterOptions<FilterCountryOption>({
              ignoreCase: true,
              trim: true,
              matchFrom: 'any',
              stringify: (option: FilterCountryOption) =>
                `${option.name} ${option.code}`,
            }),
            groupBy: (option: FilterCountryOption) =>
              option.name[0]?.toUpperCase() ?? '',
            getOptionLabel: (option: FilterCountryOption) => option.name,
            onChange: (
              _e: React.SyntheticEvent,
              option: FilterCountryOption
            ) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              const country: Country = Country[option.code];
              onChange(country);
              setmenuAnchorEl(null);
            },
            renderOption: (renderProps, option) => (
              <Box component="li" {...renderProps}>
                <Button
                  variant="text"
                  color="inherit"
                  fullWidth
                  size="medium"
                  style={{
                    justifyContent: 'flex-start',
                    textAlign: 'left',
                    textTransform: 'none',
                    fontFamily: merchantStyles?.fontFamily,
                  }}
                  startIcon={<CountryFlag country={option.code} />}
                >
                  <Typography sx={{fontFamily: 'inherit'}}>
                    {option.name} ({option.code})
                  </Typography>
                </Button>
              </Box>
            ),
          }}
        />
      </>
    );
  }
);

CountrySelect.displayName = 'CountrySelect';

export default CountrySelect;
