import React, {FC} from 'react';
import {PostHogProvider} from 'posthog-js/react';
import {POSTHOG_CONFIG} from '../config/posthog';

const CustomPosthogProvider: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => (
  <PostHogProvider
    apiKey={POSTHOG_CONFIG.POSTHOG_KEY}
    options={{
      api_host: POSTHOG_CONFIG.POSTHOG_HOST,
    }}
  >
    {children}
  </PostHogProvider>
);

export default CustomPosthogProvider;
