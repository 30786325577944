import React, {FC, Suspense} from 'react';
import {RouterProvider} from 'react-router-dom';
import Loading from './components/atoms/Loading';
import {routes} from './pages/routes';

const Routes: FC<React.PropsWithChildren<unknown>> = () => (
  <Suspense fallback={null}>
    <RouterProvider router={routes} fallbackElement={<Loading />} />
  </Suspense>
);

export default Routes;
