import React, {FC} from 'react';
import {AvatarProps} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import type {LineItem} from '@local/backend/@types/updated-api-types/items/LineItem';
import type {MerchantDTO} from '@local/backend/@types/updated-api-types/merchants/MerchantDTO';
import {HANDS_IN_LOGO} from '@local/frontend/constants';
import {StyledImageBackground} from '../../../sections/groupCheckout/SplitSection/ItemSplit/styles';

const ItemAvatar: FC<
  React.PropsWithChildren<
    Omit<AvatarProps, 'alt'> & {lineItem: LineItem; merchant: MerchantDTO}
  >
> = ({sx, lineItem, merchant, ...props}) => {
  const getLineItemImage = (): string => {
    const mainImageUrl = lineItem.item.imageUrls?.at(0);
    if (mainImageUrl) {
      return mainImageUrl;
    }

    if (merchant?.logoUrl) {
      return merchant.logoUrl;
    }

    if (merchant?.isWhiteLabelled) {
      return 'https://res.cloudinary.com/dvrjmbnri/image/upload/v1708536251/FHC%20-%20Do%20Not%20Touch/No-Image-Placeholder_qkixlm.png';
    }

    return HANDS_IN_LOGO;
  };

  return (
    <Avatar
      sx={{
        borderRadius: 2,
        width: '85px',
        height: '85px',
        position: 'relative',
        backgroundColor: 'white',
        boxShadow: 1,
        ...sx,
      }}
      {...props}
    >
      <StyledImageBackground src={props.src ?? getLineItemImage()} />
      <img
        src={props.src ?? getLineItemImage()}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'contain',
          zIndex: 1,
        }}
        alt={lineItem.item.name}
      />
    </Avatar>
  );
};

export default ItemAvatar;
