import React, {Dispatch, FC, SetStateAction} from 'react';
import Grid from '@mui/material/Grid';
import {Country, Language} from '@handsin/api-node';
import {useQueryClient} from '@tanstack/react-query';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {useNavigate} from 'react-router-dom';
import type {GroupPaymentRecord} from '@local/backend/@types/updated-api-types/group-payments/GroupPaymentRecord';
import {useNotification} from '@local/frontend/hooks/useNotification';
import {getCustomerValidation} from '@local/frontend/validators/custom-validation';
import {SplitType} from '@local/frontend/@types/updated-api-types/group-payments/SplitType';
import {JoinerGroupModalStep} from '@local/frontend/libs/modals/ModalName';
import useCustomerCountry from '@local/frontend/hooks/useCustomerCountry';
import PhoneInput from '@local/frontend/components/atoms/inputs/PhoneInput/PhoneInput';
import {
  useCreateCustomer,
  useUpdateCustomer,
} from '../../../../hooks/mutations/customers';
import {calcTotalCustomersInGroup} from '../../../../util/group-calculators';
import LoadingButton from '../../../atoms/buttons/LoadingButton';
import {useJoinGroupPayment} from '../../../../hooks/mutations/groups-payments';
import {Customer} from '../../../../@types/updated-api-types/customer/Customer';

interface JoinFormProps {
  groupPayment: GroupPaymentRecord;
  setModalStep: Dispatch<SetStateAction<JoinerGroupModalStep>>;
  selectedCustomer: Customer | null;
}

const JoinGroupForm: FC<React.PropsWithChildren<JoinFormProps>> = ({
  groupPayment,
  setModalStep,
  selectedCustomer: existingCustomer,
}) => {
  const {i18n, t} = useTranslation(['modals', 'common']);

  const customerValidation = getCustomerValidation(t);
  const joinGroupSchema = Yup.object(customerValidation);

  const queryClient = useQueryClient();
  const {open: openNotification} = useNotification();

  const customerCountry = useCustomerCountry(existingCustomer?.id);

  const {
    formState: {isSubmitting, isValid},
    ...formMethods
  } = useForm<Yup.InferType<typeof joinGroupSchema>>({
    mode: 'all',
    resolver: yupResolver(joinGroupSchema),
    defaultValues: {
      firstName: existingCustomer?.firstName ?? '',
      lastName: existingCustomer?.lastName ?? '',
      // prevent showing defaulted email to user
      email: existingCustomer?.email ?? '',
      phoneNumber: existingCustomer?.phoneNumber ?? '',
      phoneNumberCountry: existingCustomer?.address?.country ?? Country.GB,
      language:
        existingCustomer?.language ??
        (i18n.resolvedLanguage as Language) ??
        Language.EN,
      country: customerCountry,
    },
  });

  const joinGroupPaymentMutation = useJoinGroupPayment();
  const updateCustomerMutation = useUpdateCustomer();
  const createCustomerMutation = useCreateCustomer();
  const navigate = useNavigate();

  const handleExistingUserJoin = async (
    existingCustomerJoining: Customer,
    values: Yup.InferType<typeof joinGroupSchema>
  ) => {
    // update customer first, in the event they are joining with any changes to their name, email and phone number
    await updateCustomerMutation.mutateAsync(
      {
        customerUpdateParams: {
          firstName: values.firstName,
          lastName: values.lastName,
          phoneNumber: values.phoneNumber,
          email: values.email?.toLowerCase(),
          language: values.language,
        },
        customerId: existingCustomerJoining.id,
      },
      {
        onSuccess: (updatedCustomer, variables, context) => {
          // join group
          joinGroupPaymentMutation.mutate(
            {
              customer: {
                id: updatedCustomer.id,
                firstName: updatedCustomer.firstName,
                lastName: updatedCustomer.lastName,
                phoneNumber: updatedCustomer.phoneNumber,
                email: updatedCustomer.email?.toLowerCase(), // force emails to be lowercase
              },
              groupPaymentId: groupPayment.id,
            },
            {
              onSuccess: async (updatedGpr) => {
                // update query client with new groupPayment data
                queryClient.setQueryData(
                  ['groupPayment', groupPayment.id],
                  updatedGpr
                );

                queryClient.setQueryData(
                  ['customer', updatedCustomer.id],
                  updatedCustomer
                );

                // ensure that the user joining receives the most up to date share price visible to them
                await queryClient.invalidateQueries([
                  'group-payment-shares',
                  groupPayment.id,
                ]);

                // navigate user to their customer dashboard
                navigate(
                  `/g/${groupPayment.id}/group-dashboard?mid=${groupPayment.merchantId}&cid=${updatedCustomer.id}`,
                  {replace: true}
                );
              },
              onError: () => {
                // If the mutation fails, use the context returned from update customer onMutate to roll back to previous customer record
                updateCustomerMutation.mutate({
                  customerId: variables.customerId,
                  customerUpdateParams: {
                    firstName: context.previousCustomer?.firstName,
                    lastName: context.previousCustomer?.lastName ?? '', // important for first time fails
                    email: context.previousCustomer?.email ?? '', // important for first time fails
                    phoneNumber: context.previousCustomer?.phoneNumber ?? '', // important for first time fails
                  },
                });

                queryClient.setQueryData(
                  ['customer', variables.customerId],
                  context.previousCustomer
                );
              },
            }
          );
        },
      }
    );
  };

  const handleNewUserJoin = async (
    formValues: Yup.InferType<typeof joinGroupSchema>
  ) => {
    // create a new customer with form values
    const newJoiningCustomer = await createCustomerMutation.mutateAsync({
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      phoneNumber: formValues.phoneNumber,
      email: formValues.email?.toLowerCase(), // force emails to be lowercase
      language: (i18n.resolvedLanguage as Language | undefined) ?? Language.EN,
    });

    switch (groupPayment.splitType) {
      case SplitType.EQUAL:
      case SplitType.FIXED_PRICE:
        joinGroupPaymentMutation.mutate(
          {
            customer: newJoiningCustomer,
            groupPaymentId: groupPayment.id,
          },
          {
            // update query client with new groupPayment data
            onSuccess: async (updatedGpr) => {
              queryClient.setQueryData(
                ['groupPayment', groupPayment.id],
                updatedGpr
              );
              queryClient.setQueryData(
                ['customer', newJoiningCustomer.id],
                newJoiningCustomer
              );
              // ensure that the user joining receives the most up to date share price visible to them
              await queryClient.invalidateQueries([
                'group-payment-shares',
                groupPayment.id,
              ]);

              // navigate user to their customer dashboard
              navigate(
                `/g/${groupPayment.id}/group-dashboard?mid=${groupPayment.merchantId}&cid=${newJoiningCustomer.id}`,
                {replace: true}
              );
            },
            onError: (error) => {
              // check if error was due to duplicate email
              if (
                error.response?.data.name ===
                'MEMBER_WITH_MATCHING_EMAIL_ALREADY_EXISTS'
              ) {
                setModalStep(JoinerGroupModalStep.REINVITE);
              }
            },
          }
        );
        break;
      default:
    }
  };

  const handleOnSubmit = async (
    formValues: Yup.InferType<typeof joinGroupSchema>
  ) => {
    try {
      const totalUsersInGroup = calcTotalCustomersInGroup(groupPayment);
      if (!totalUsersInGroup) {
        openNotification({
          message: t(
            'joinGroupModal.components.joinGroupForm.errors.noGroupTotal',
            {ns: 'modals'}
          ),
          severity: 'error',
        });
        return;
      }

      // conditions for an existing customer to be able to join a group
      const canExistingCustomerJoinGroup =
        existingCustomer &&
        !groupPayment.memberIds.includes(existingCustomer.id) &&
        groupPayment.invited &&
        groupPayment.invited.includes(existingCustomer.id);

      // check if a new customer is joining the group
      if (!existingCustomer) {
        await handleNewUserJoin(formValues);
      } else if (canExistingCustomerJoinGroup) {
        // else check if the customer joining was selected by the user (a pre-existing customer added to the group)
        // and that they are still eligible to join the group (not already in the group and still a added member)
        await handleExistingUserJoin(existingCustomer, formValues);
      } else {
        // otherwise the pre selected existing customer is not able to join the group

        setModalStep(JoinerGroupModalStep.INVITED_LIST);

        // unselect invalid user as they dont exist anymore
        queryClient.setQueryData<Customer | null>(['selectedCustomer'], null);

        openNotification({
          message: t(
            'joinGroupModal.components.joinGroupForm.errors.cannotJoin',
            {ns: 'modals'}
          ),
          severity: 'error',
        });
      }

      await queryClient.invalidateQueries(['groupPayment', groupPayment.id]);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <form onSubmit={formMethods.handleSubmit(handleOnSubmit)}>
      <Grid container spacing={2}>
        {/* form */}
        <Grid item xs={12}>
          <Controller
            control={formMethods.control}
            name="firstName"
            render={({field, fieldState}) => (
              <TextField
                {...field}
                required
                variant="outlined"
                label={t('form.firstName.label', {ns: 'common'})}
                placeholder={t('form.firstName.placeholder', {
                  ns: 'common',
                })}
                fullWidth
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={formMethods.control}
            name="lastName"
            render={({field, fieldState}) => (
              <TextField
                {...field}
                variant="outlined"
                label={t('form.lastName.label', {ns: 'common'})}
                placeholder={t('form.lastName.placeholder', {
                  ns: 'common',
                })}
                fullWidth
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={formMethods.control}
            name="email"
            render={({field, fieldState}) => (
              <TextField
                {...field}
                variant="outlined"
                required
                label={t('form.email.label', {ns: 'common'})}
                placeholder={t('form.email.placeholder', {
                  ns: 'common',
                })}
                fullWidth
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={formMethods.control}
            name="phoneNumber"
            render={({field, fieldState}) => (
              <PhoneInput
                {...field}
                onNumberChange={(value) => {
                  field.onChange(value);
                }}
                onCountryChange={(value) => {
                  if (value) {
                    formMethods.setValue('phoneNumberCountry', value);
                  }
                }}
                defaultCountry={formMethods.watch('phoneNumberCountry')}
                variant="outlined"
                required
                fullWidth
                label={t('form.phoneNumber.label', {ns: 'common'})}
                placeholder={t('form.phoneNumber.placeholder', {
                  ns: 'common',
                })}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>

        {/* button actions */}
        <Grid item xs={12}>
          <LoadingButton
            type="submit"
            variant="contained"
            fullWidth
            loading={isSubmitting}
            disabled={!isValid || isSubmitting}
          >
            {t('joinGroupModal.components.joinGroupForm.button.text', {
              ns: 'modals',
            })}
          </LoadingButton>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            sx={{
              fontWeight: 500,
              fontSize: 12,
              opacity: 0.75,
              '&:hover': {
                opacity: 1,
                cursor: 'pointer',
                textDecoration: 'underline',
              },
            }}
            align="center"
            color="primary"
            onClick={() => setModalStep(JoinerGroupModalStep.REINVITE)}
          >
            {t('joinGroupModal.components.joinGroupForm.links.alreadyJoined', {
              ns: 'modals',
            })}
          </Typography>
        </Grid>
      </Grid>
    </form>
  );
};

export default JoinGroupForm;
