import React, {FC} from 'react';
import Grid from '@mui/material/Grid';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import {MoneyUtils} from '@handsin/money';
import {useTranslation} from 'react-i18next';
import type {GroupPaymentRecord} from '@local/backend/@types/updated-api-types/group-payments/GroupPaymentRecord';
import {SplitType} from '@local/frontend/@types/updated-api-types/group-payments/SplitType';
import {Customer} from '../../../../../@types/updated-api-types/customer/Customer';
import {calcSharePrice} from '../../../../../util/converters';
import {calcGroupSize} from '../../../../../util/group-calculators';
import {useGroupPaymentCustomerShares} from '../../../../../hooks/queries/group-payments';
import useCustomerShareAmount from '../../../../../hooks/useCustomerShareAmount';
import Loading from '../../../../atoms/Loading';

interface GroupPaymentInfoProps {
  groupPayment: GroupPaymentRecord;
  groupOwner: Customer;
  selectedCustomer: Customer | null;
}

const GroupPaymentInfo: FC<React.PropsWithChildren<GroupPaymentInfoProps>> = ({
  groupOwner,
  groupPayment,
  selectedCustomer,
}) => {
  const {t} = useTranslation(['modals', 'glossary']);

  const isGroupEqualOrFixed =
    groupPayment.splitType === SplitType.EQUAL ||
    groupPayment.splitType === SplitType.FIXED_PRICE;

  const {
    data: groupPaymentCustomerSharesMap,
    isLoading: isGroupCustomerSharesLoading,
  } = useGroupPaymentCustomerShares(groupPayment.id);

  // NOTE: if the group is equal or fixed we can always pass the ownerId as every members share is the same in EQUAL and Fixed.
  const customerShareAmount = useCustomerShareAmount(
    isGroupEqualOrFixed ? groupPayment.ownerId : selectedCustomer?.id,
    groupPaymentCustomerSharesMap
  );

  // in the specific edge-case where the user is joining as a non-selected customer (new user), we calculate the share price here.
  const calculatedSharePrice = calcSharePrice(
    groupPayment,
    selectedCustomer?.id
  );

  if (isGroupCustomerSharesLoading) {
    return <Loading />;
  }

  let shareAmountToDisplay: string;
  if (customerShareAmount) {
    shareAmountToDisplay = MoneyUtils.formatMoney(customerShareAmount);
  } else if (calculatedSharePrice) {
    shareAmountToDisplay = MoneyUtils.formatMoney(
      calculatedSharePrice.totalMoney
    );
  } else {
    shareAmountToDisplay = t(
      'joinGroupModal.components.joinGroupOverview.groupPaymentInfo.calculateShare',
      {ns: 'modals'}
    );
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={6}>
            <ListItemText
              primary={
                <Typography
                  variant="body2"
                  color="primary.dark"
                  sx={{fontWeight: 400}}
                >
                  {t('groupOwner', {ns: 'glossary'})}
                </Typography>
              }
              secondary={`${groupOwner.firstName} ${groupOwner.lastName}`}
            />
          </Grid>
          <Grid item xs={6}>
            <ListItemText
              primary={
                <Typography
                  variant="body2"
                  color="primary.dark"
                  sx={{fontWeight: 400}}
                >
                  {t('groupSize', {ns: 'glossary'})}
                </Typography>
              }
              secondary={calcGroupSize(groupPayment)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={6}>
            <ListItemText
              primary={
                <Typography
                  variant="body2"
                  color="primary.dark"
                  sx={{fontWeight: 400}}
                >
                  {t('totalAmount', {ns: 'glossary'})}
                </Typography>
              }
              secondary={MoneyUtils.formatMoney(groupPayment.totalMoney)}
            />
          </Grid>
          <Grid item xs={6}>
            <ListItemText
              primary={
                <Typography
                  variant="body2"
                  color="primary.dark"
                  sx={{fontWeight: 400}}
                >
                  {t('yourShare', {ns: 'glossary'})}
                </Typography>
              }
              secondary={shareAmountToDisplay}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GroupPaymentInfo;
