const getSbiRoute = (
  groupPaymentId: string,
  merchantId: string,
  customerId?: string
): string => {
  if (customerId) {
    return `/g/${groupPaymentId}/sbi?mid=${merchantId}&cid=${customerId}`;
  }

  return `/g/${groupPaymentId}/sbi?mid=${merchantId}`;
};

export default getSbiRoute;
