import type {GroupPaymentRecord} from '@local/backend/@types/updated-api-types/group-payments/GroupPaymentRecord';
import {sendEmail} from '@local/frontend/libs/api/group-payments.actions';
import {Customer} from '../../../../../@types/updated-api-types/customer/Customer';

interface HandleEmailReminderParams {
  customer: Customer;
  groupPayment: GroupPaymentRecord;
}

export const handleEmailReminder = async ({
  customer,
  groupPayment,
}: HandleEmailReminderParams): Promise<void> => {
  if (customer.email) {
    await sendEmail({
      emailDetails: {
        to: [customer.email],
        subject: '', // generated on API
        templateName: 'group-payment-reminder',
        customerId: customer.id,
      },
      groupPaymentId: groupPayment.id,
      ownerId: groupPayment.ownerId,
    });
  } else {
    throw new Error(
      'Cannot remind customer as they do not have an email associated to them'
    );
  }
};
