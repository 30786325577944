import {AxiosError} from 'axios';
import {
  type QueryObserverOptions,
  useQueries,
  useQuery,
  type UseQueryOptions,
  type UseQueryResult,
} from '@tanstack/react-query';
import type {DefaultResponseError} from '@local/backend/@types/updated-api-types/DefaultResponseError';
import {Customer} from '../../../@types/updated-api-types/customer/Customer';
import {getCustomerById} from '../../../libs/api/customer.actions';

export const useInitializeCustomer = (
  customerId: string | undefined,
  queryOptions?: QueryObserverOptions<
    Customer | undefined,
    AxiosError<DefaultResponseError>
  >
): UseQueryResult<Customer | undefined, AxiosError<DefaultResponseError>> =>
  useQuery<Customer | undefined, AxiosError<DefaultResponseError>>(
    ['customer', customerId],
    () => (customerId ? getCustomerById(customerId) : undefined),
    {
      enabled: !!customerId,
      ...queryOptions,
    }
  );

export const useInitializeCustomers = (
  memberIds: string[] | undefined,
  queryOptions?: Omit<
    UseQueryOptions<Customer, AxiosError<DefaultResponseError>>,
    'context'
  >
): UseQueryResult<Customer, AxiosError<DefaultResponseError>>[] =>
  useQueries<UseQueryOptions<Customer, AxiosError<DefaultResponseError>>[]>({
    queries:
      memberIds?.map((customerId: string) => ({
        queryKey: ['customer', customerId],
        queryFn: () => getCustomerById(customerId),
        enabled: !!memberIds,
        ...queryOptions,
      })) ?? [],
  });

// --- FOR QUERIES THAT HAVE ALREADY BEEN PREFETCHED ---
export const useCustomer = (
  customerId: string | undefined
): UseQueryResult<Customer | undefined, AxiosError<DefaultResponseError>> =>
  useQuery<Customer | undefined, AxiosError<DefaultResponseError>>(
    ['customer', customerId],
    () => (customerId ? getCustomerById(customerId) : undefined),
    {
      enabled: !!customerId,
    }
  );
