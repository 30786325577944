import React, {FC} from 'react';
import {Grid} from '@mui/material';
import QRCode from 'react-qr-code';
import {InviteProps} from './@types/InviteProps';

const QRInvite: FC<React.PropsWithChildren<InviteProps>> = ({linkToCopy}) => (
  <Grid container justifyContent="center">
    <QRCode value={linkToCopy} size={200} />
  </Grid>
);

export default QRInvite;
