import type {Namespace, TFunction} from 'i18next';
import {Money} from '@handsin/api-node';
import {MoneyUtils} from '@handsin/money';
import type {GroupPaymentRecord} from '@local/backend/@types/updated-api-types/group-payments/GroupPaymentRecord';

interface DynamicPricingButtonTextParams {
  groupPayment: GroupPaymentRecord | undefined;
  sharePrice: Money | undefined;
  isDynamicPricingCheckboxTicked: boolean;
  t: TFunction<Namespace>;
}

export const getDynamicPricingButtonText = ({
  groupPayment,
  sharePrice,
  isDynamicPricingCheckboxTicked,
  t,
}: DynamicPricingButtonTextParams): string => {
  let authShareMoney: Money | undefined;
  if (groupPayment && sharePrice) {
    const authShareAmount = sharePrice
      ? Math.floor(
          sharePrice.amount /
            (groupPayment.totalMoney.amount / groupPayment.amountMoney.amount)
        )
      : undefined;

    authShareMoney = authShareAmount
      ? {amount: authShareAmount, currency: sharePrice.currency}
      : undefined;
  }

  // check if dynamic pricing is not enabled
  if (!isDynamicPricingCheckboxTicked) {
    if (!authShareMoney) {
      return t('dynamicPricingConfirmationModal.body.continue', {
        ns: 'modals',
      });
    }

    // show auth amount of money user will pay when dynamic pricing is not enabled
    return `${t('dynamicPricingConfirmationModal.body.continueAndPay', {
      ns: 'modals',
    })} ${MoneyUtils.formatMoney(authShareMoney)}`;
  }

  const showAmountRange =
    authShareMoney && sharePrice && authShareMoney.amount !== sharePrice.amount;

  // show range from initial share amount to share price if we can
  if (showAmountRange && authShareMoney) {
    return `${t('dynamicPricingConfirmationModal.body.secureAndPay', {
      ns: 'modals',
    })} ${MoneyUtils.formatMoney(authShareMoney)} - ${MoneyUtils.formatMoney(
      sharePrice
    )}`;
  }

  // else show share price if we can
  if (sharePrice) {
    return `${t('dynamicPricingConfirmationModal.body.secureAndPay', {
      ns: 'modals',
    })} ${MoneyUtils.formatMoney(sharePrice)}`;
  }

  // else just default to this
  return t('dynamicPricingConfirmationModal.body.secureAndPay', {
    ns: 'modals',
  });
};
