import {AxiosError} from 'axios';
import {UseQueryResult, useQuery} from '@tanstack/react-query';
import {useNavigate} from 'react-router-dom';
import type {DefaultResponseError} from '@local/backend/@types/updated-api-types/DefaultResponseError';
import {Checkout} from '../../../@types/updated-api-types/checkouts/Checkout';
import {
  getCheckoutById,
  getCheckoutPublicDetails,
} from '../../../libs/api/checkouts.actions';
import {QueryOptions} from '../@types';

export const useInitialiseCheckout = (
  checkoutId: string | undefined,
  merchantId: string | undefined,
  queryOptions?: QueryOptions<
    Checkout | undefined,
    AxiosError<DefaultResponseError>
  >
): UseQueryResult<Checkout | undefined, AxiosError<DefaultResponseError>> => {
  const navigate = useNavigate();
  return useQuery<Checkout | undefined, AxiosError<DefaultResponseError>>(
    ['checkout', checkoutId],
    () => (checkoutId ? getCheckoutById(checkoutId) : undefined),
    {
      enabled: !!checkoutId && !!merchantId,
      onError: (err: AxiosError<DefaultResponseError>) => {
        if (
          checkoutId &&
          merchantId &&
          err.response &&
          err.response.data?.name === 'ACCESS_TOKEN_EXPIRED'
        ) {
          navigate(`/c/${checkoutId}/t?mid=${merchantId}`);
        }
      },
      ...queryOptions,
    }
  );
};

export const useInitialiseCheckoutPublicDetails = (
  checkoutId: string | undefined,
  merchantId: string | undefined,
  queryOptions?: QueryOptions<
    Checkout | undefined,
    AxiosError<DefaultResponseError>
  >
): UseQueryResult<Checkout | undefined, AxiosError<DefaultResponseError>> =>
  useQuery<Checkout | undefined, AxiosError<DefaultResponseError>>(
    ['checkout-public', checkoutId],
    () =>
      checkoutId && merchantId
        ? getCheckoutPublicDetails(checkoutId, merchantId)
        : undefined,
    {
      enabled: !!checkoutId && !!merchantId,
      ...queryOptions,
    }
  );
