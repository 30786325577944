import React, {FC} from 'react';
import {useParams, useSearchParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Loading from '../components/atoms/Loading';
import {useInitialiseCheckout} from '../hooks/queries/checkouts';
import ErrorPage from '../pages/ErrorPage';

const CheckoutProvider: FC<React.PropsWithChildren<unknown>> = ({children}) => {
  const {t} = useTranslation(['common']);
  const {checkoutId} = useParams<Record<string, string>>(); // path params
  const [searchParams] = useSearchParams(); // query params
  const merchantId = searchParams.get('mid') ?? undefined;

  const {data: checkout, isInitialLoading: isCheckoutLoading} =
    useInitialiseCheckout(checkoutId, merchantId);

  if (isCheckoutLoading) {
    return <Loading />;
  }

  if (!checkout) {
    return <ErrorPage errorMessage={t('errors.noCheckout', {ns: 'common'})} />;
  }

  return <>{children}</>;
};

export default CheckoutProvider;
