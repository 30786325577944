import React, {FC} from 'react';
import {useParams, useSearchParams} from 'react-router-dom';
import axiosInstance from '../config/backend';

const AxiosWrapper: FC<React.PropsWithChildren<unknown>> = ({children}) => {
  const {groupPaymentId, checkoutId, multiCardId} =
    useParams<Record<string, string>>();
  const [searchParams] = useSearchParams();
  const merchantId = searchParams.get('mid');

  const groupPaymentIdSearchParam = searchParams.get('groupPaymentId');
  const checkoutIdSearchParam = searchParams.get('checkoutId');
  const multiCardIdSearchParam = searchParams.get('multiCardId');

  axiosInstance.defaults.headers.common.merchantid = merchantId ?? '';

  // set the checkout id - won't exist in self-hosted flows
  if (checkoutId || checkoutIdSearchParam) {
    axiosInstance.defaults.headers.common.checkoutid =
      checkoutId ?? checkoutIdSearchParam ?? '';
  }

  // set the group payment id - will exist in self-hosted flows
  if (groupPaymentId || groupPaymentIdSearchParam) {
    axiosInstance.defaults.headers.common.grouppaymentid =
      groupPaymentId ?? groupPaymentIdSearchParam ?? '';
  }

  if (multiCardId || multiCardIdSearchParam) {
    axiosInstance.defaults.headers.common.multiCardId =
      multiCardId ?? multiCardIdSearchParam ?? '';
  }

  return <>{children}</>;
};

export default AxiosWrapper;
