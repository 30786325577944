import React, {createContext, ReactNode, useMemo, useState} from 'react';
import {LocalMoneyCalculator, Money, MoneyUtils} from '@handsin/money';
import _ from 'lodash';
import {PaymentRecord} from '@local/backend/@types/updated-api-types/payments/PaymentRecord';
import {useTranslation} from 'react-i18next';
import {MultiCardVariantSteps} from '../pages/multi-cards/@types/MultiCardVariantSteps';
import {
  useGetPaymentsOfMultiCard,
  useMultiCardPayment,
} from '../libs/trpc/trpc';
import useMerchantId from '../hooks/useMerchantId';
import {QUERY_REFETCH_INTERVAL} from '../constants';
import Loading from '../components/atoms/Loading';

export type MultiCardPerPageContextType = {
  numberOfCards: number;
  updateNumberOfCards: (newValue: number) => void;
  currentActiveStep: MultiCardVariantSteps;
  navigateToStep: (step: MultiCardVariantSteps) => void;
  changeCurrentAmount: (newAmount: number) => void;
  moneyToPay: Money | undefined;
  minNumberOfCards: number;
  maxNumberOfCards: number | undefined;
  multiCardSteps: {
    label: string;
    amount: string;
  }[];
  totalNumberOfRemainingPayments: number;
  remainingMoney: Money | undefined;
  paymentRecords: PaymentRecord[];
  lastPaymentReferenceId: string | undefined;
  setLastPaymentReferenceId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
};

export const MultiCardPerPageContext = createContext<
  MultiCardPerPageContextType | undefined
>(undefined);

interface MultiCardPerPageProviderProps {
  children: ReactNode;
  multiCardId: string;
}

const MultiCardPerPageProvider: React.FC<MultiCardPerPageProviderProps> = ({
  children,
  multiCardId,
}) => {
  const {t} = useTranslation('multi-card');
  const merchantId = useMerchantId();
  const {data: multiCardRecord, isInitialLoading: isMultiCardLoading} =
    useMultiCardPayment(
      {merchantId: merchantId ?? '', multiCardId},
      {
        enabled: !!merchantId && !!multiCardId,
        refetchInterval: (data) =>
          data?.status === 'PENDING' || data?.status === 'APPROVED'
            ? QUERY_REFETCH_INTERVAL
            : false,
      }
    );

  const {
    data: cachedPaymentRecords,
    isInitialLoading: isPaymentRecordsLoading,
  } = useGetPaymentsOfMultiCard(
    {
      merchantId: multiCardRecord?.merchantId ?? '',
      multiCardId: multiCardRecord?.id ?? '',
    },
    {
      enabled: !!multiCardRecord?.merchantId && !!multiCardRecord?.merchantId,
      refetchInterval: 1000,
    }
  );

  const paymentRecords = useMemo(
    () =>
      _.sortBy(
        _.filter(
          cachedPaymentRecords,
          (paymentRecord) =>
            !['CANCELLED', 'FAILED'].includes(paymentRecord.status)
        ),
        (paymentRecord) => paymentRecord.createdAt
      ),
    [cachedPaymentRecords]
  );

  const remainingMoney = multiCardRecord
    ? new LocalMoneyCalculator(multiCardRecord.totalMoney)
        .subtract(
          multiCardRecord.approvedMoney ?? {
            amount: 0,
            currency: multiCardRecord.totalMoney.currency,
          }
        )
        .calculate()
    : undefined;

  const isPaidInFull = !!remainingMoney && remainingMoney.amount <= 0;

  const maxNumberOfCards = multiCardRecord?.pageOptions?.maxNumberCards;
  const minNumberOfCards = multiCardRecord?.paymentIds
    ? multiCardRecord.paymentIds.length + 1
    : 1;

  const defaultInitialNumberOfCards = useMemo(() => {
    // eslint-disable-next-line no-nested-ternary
    const remainingNumberOfPayments = isPaidInFull
      ? paymentRecords.length
      : maxNumberOfCards
        ? Math.min(paymentRecords.length + 1, maxNumberOfCards) // prevent defaulting to a number higher than maxNumberOfCards, if set
        : paymentRecords.length + 1;

    if (maxNumberOfCards !== undefined) {
      const result =
        paymentRecords.length === 0
          ? Math.min(2, maxNumberOfCards) // handle edge case if maxCards is set to 1
          : remainingNumberOfPayments;

      return result;
    }

    return Math.max(2, remainingNumberOfPayments);
  }, [isPaidInFull, multiCardRecord, paymentRecords.length, maxNumberOfCards]);

  // used to determine when to navigate away from MultiCardVariantSteps.PAY_SUCCESS step
  const [lastPaymentReferenceId, setLastPaymentReferenceId] = useState<
    string | undefined
  >(undefined);

  const [numberOfCards, setNumberOfCards] = useState<number>(
    defaultInitialNumberOfCards
  );

  const [amountToPay, setAmountToPay] = useState<number | undefined>(0);
  const moneyToPay = useMemo(
    () =>
      multiCardRecord && amountToPay
        ? {
            amount: amountToPay,
            currency: multiCardRecord.amountMoney.currency,
          }
        : undefined,
    [multiCardRecord, amountToPay]
  );

  // determines whether we should show the select number of cards screen or skip ahead
  const showSelectNumberOfCards =
    !multiCardRecord?.paymentIds || multiCardRecord.paymentIds.length <= 0;

  const [currentActiveStep, setActiveStep] = useState<MultiCardVariantSteps>(
    showSelectNumberOfCards
      ? MultiCardVariantSteps.SELECT_NUMBER_OF_CARDS
      : MultiCardVariantSteps.ENTER_AMOUNT
  );
  const navigateToStep = (step: MultiCardVariantSteps): void => {
    setActiveStep(step);
  };

  const changeCurrentAmount = (newAmount: number): void => {
    if (remainingMoney && newAmount > remainingMoney.amount) {
      return;
    }

    setAmountToPay(newAmount);

    // if the new amount is equal to the remaining amount,then cap the number of cards and do not add further 0,00 steps
    if (newAmount === remainingMoney?.amount) {
      setNumberOfCards(paymentRecords.length + 1);
    }
  };

  const totalNumberOfRemainingPayments =
    numberOfCards < paymentRecords.length
      ? 1 // add 1 payment step if numberOfCards is lte completed payments to indicate to the user theres more left to pay
      : numberOfCards - paymentRecords.length;

  const updateNumberOfCards = (newAmount: number): void => {
    if (!multiCardRecord) {
      return;
    }

    setNumberOfCards(newAmount);

    const newRemainingPaymentSlots =
      newAmount <= paymentRecords.length
        ? 1 // add 1 payment step if numberOfCards is lte completed payments to indicate to the user theres more left to pay
        : newAmount - paymentRecords.length;

    const remainingAmount = new LocalMoneyCalculator(
      multiCardRecord.totalMoney
    ).subtract(
      multiCardRecord.approvedMoney ?? {
        amount: 0,
        currency: multiCardRecord.totalMoney.currency,
      }
    );

    if (
      newRemainingPaymentSlots === newAmount ||
      newRemainingPaymentSlots === 1
    ) {
      changeCurrentAmount(
        remainingAmount.divide(newRemainingPaymentSlots).calculate().amount
      );
    } else {
      changeCurrentAmount(
        remainingAmount.divide(newAmount - paymentRecords.length).calculate()
          .amount
      );
    }
  };

  const multiCardSteps = useMemo(() => {
    if (!multiCardRecord) {
      return [];
    }

    const completedPaymentSteps = paymentRecords.map((paymentRecord, idx) => ({
      label: `${t('stepper.cardTitle', {ns: 'multi-card'})} ${idx + 1}`,
      amount: MoneyUtils.formatMoney(
        paymentRecord.approvedMoney ?? {
          amount: 0,
          currency: paymentRecord.totalMoney.currency,
        }
      ),
    }));

    const remainingAmount = new LocalMoneyCalculator(
      multiCardRecord.totalMoney
    ).subtract(
      multiCardRecord.approvedMoney ?? {
        amount: 0,
        currency: multiCardRecord.totalMoney.currency,
      }
    );

    const defaultCardAmountToPay = remainingAmount
      .subtract(
        // if totalNumberOfPayments is 1. then don't subract anything from the remaining amount
        totalNumberOfRemainingPayments <= 1
          ? {amount: 0, currency: multiCardRecord.totalMoney.currency} // zero here for last payment. since automatically calculated as remaining amount by division operation next
          : moneyToPay ?? {
              amount: 0,
              currency: multiCardRecord.totalMoney.currency,
            } // subtract active step amount that has been input by user
      )
      .divide(
        // eslint-disable-next-line no-nested-ternary
        totalNumberOfRemainingPayments <= 1
          ? 1 // if last payment then don't divide (handle divide by zero edge case)
          : moneyToPay
            ? Math.max(totalNumberOfRemainingPayments - 1, 1) // if money to pay is set we don't want to include this step in the calculation since we've already subtracted this value above
            : totalNumberOfRemainingPayments
      )
      .calculate();

    const remainingCardPaymentSlots = _.range(
      0,
      totalNumberOfRemainingPayments
    );

    return completedPaymentSteps.concat(
      remainingCardPaymentSlots.map((_v, cardIdx) => {
        const nonCompletedPaymentIdx = completedPaymentSteps.length + cardIdx;
        const isLastCard = cardIdx === remainingCardPaymentSlots.length - 1;
        let amountToDisplay = defaultCardAmountToPay;

        // the current step should always display and use moneyToPay (user-input-amount)
        if (!isLastCard && cardIdx === 0 && moneyToPay) {
          amountToDisplay = moneyToPay;
        }

        return {
          label: `${t('stepper.cardTitle', {ns: 'multi-card'})} ${nonCompletedPaymentIdx + 1}`,
          amount: MoneyUtils.formatMoney(amountToDisplay),
        };
      })
    );
  }, [
    paymentRecords,
    amountToPay,
    multiCardRecord,
    numberOfCards,
    moneyToPay?.amount,
  ]);

  const multiCardPerPageState = useMemo<MultiCardPerPageContextType>(
    () => ({
      numberOfCards,
      updateNumberOfCards,
      currentActiveStep,
      navigateToStep,
      changeCurrentAmount,
      moneyToPay,
      maxNumberOfCards,
      minNumberOfCards,
      multiCardSteps,
      totalNumberOfRemainingPayments,
      remainingMoney,
      paymentRecords,
      lastPaymentReferenceId,
      setLastPaymentReferenceId,
    }),
    [
      paymentRecords,
      lastPaymentReferenceId,
      totalNumberOfRemainingPayments,
      numberOfCards,
      moneyToPay,
      currentActiveStep,
      maxNumberOfCards,
      minNumberOfCards,
      multiCardSteps,
      remainingMoney,
      setLastPaymentReferenceId,
    ]
  );

  if (isMultiCardLoading || isPaymentRecordsLoading) {
    return <Loading />;
  }

  return (
    <MultiCardPerPageContext.Provider value={multiCardPerPageState}>
      {children}
    </MultiCardPerPageContext.Provider>
  );
};

export default MultiCardPerPageProvider;
