import React, {FC} from 'react';
import {useParams} from 'react-router-dom';
import {HandsInThemeProvider, theme} from '@hands-in/theme';
import {createTheme} from '@mui/material/styles';
import useMerchantId from '../hooks/useMerchantId';
import {useMultiCardPayment} from '../libs/trpc/trpc';
import Loading from '../components/atoms/Loading';

const MultiCardCustomThemeWrapper: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const merchantId = useMerchantId();
  const {multiCardId} = useParams();
  const {data: multiCardPayment, isInitialLoading} = useMultiCardPayment(
    {
      merchantId: merchantId ?? '',
      multiCardId: multiCardId ?? '',
    },
    {enabled: !!multiCardId && !!merchantId}
  );

  const merchantThemeStyles = multiCardPayment?.pageOptions?.style;

  // create a merchant theme based on the payments theme options
  const merchantTheme = createTheme({
    ...theme,
    typography: {
      ...theme.typography,
      ...merchantThemeStyles?.typography,
    },
    palette: {
      ...theme.palette,
      ...merchantThemeStyles?.palette,
    },
    components: {
      ...theme.components,
      MuiButton: {
        styleOverrides: merchantThemeStyles?.components?.button?.styleOverrides,
      },
    },
  });

  if (isInitialLoading) {
    return <Loading />;
  }

  return (
    <HandsInThemeProvider theme={merchantTheme}>
      {children}
    </HandsInThemeProvider>
  );
};

export default MultiCardCustomThemeWrapper;
