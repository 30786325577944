export enum ModalName {
  PAYMENT_APPROVED = 'PAYMENT_APPROVED',
  JOIN_GROUP = 'JOIN_GROUP',
  LEAVE_GROUP = 'LEAVE_GROUP',
  LINEITEM_SUMMARY = 'LINEITEM_SUMMARY',
  JOIN_BY_ITEM_GROUP = 'JOIN_BY_ITEM_GROUP',
  SHARE_INVITE = 'SHARE_INVITE',
  LINE_ITEM_INFO = 'LINE_ITEM_INFO',
  VIEW_ALL_PARTICIPANTS = 'VIEW_ALL_PARTICIPANTS',
  FEEDBACK_MODAL = 'FEEDBACK_MODAL',
  DYNAMIC_PRICING_OWNER_INFORMATION = 'DYNAMIC_PRICING_OWNER_INFORMATION',
  DYNAMIC_PRICING_OWNER_OPT_OUT = 'DYNAMIC_PRICING_OWNER_OPT_OUT',
  DYNAMIC_PRICING_JOINER_INFORMATION = 'DYNAMIC_PRICING_JOINER_INFORMATION',
  DASHBOARD_SETTINGS = 'DASHBOARD_SETTINGS',
  SBI_MODIFY_BASKET = 'SBI_MODIFY_BASKET',
  REQUEST_GROUP_INVITE_MODAL = 'REQUEST_GROUP_INVITE_MODAL',
  ADD_GROUP_MEMBER = 'ADD_GROUP_MEMBER',
  CANCEL_PAYMENT = 'CANCEL_PAYMENT',
  HOW_IT_WORKS = 'HOW_IT_WORKS',
  EDIT_MEMBER_DETAILS = 'EDIT_MEMBER_DETAILS',
}

export enum JoinerGroupModalStep {
  JOINER_FORM = 'JOINER_FORM',
  JOINER_OVERVIEW = 'JOINER_OVERVIEW',
  INVITED_LIST = 'INVITED_LIST',
  REINVITE = 'REINVITE',
  ALREADY_PAID = 'ALREADY_PAID',
}
