import React, {FC, useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import {ToggleButton} from '@mui/material';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MailIcon from '@mui/icons-material/Mail';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import {useTranslation} from 'react-i18next';
import type {GroupPaymentRecord} from '@local/backend/@types/updated-api-types/group-payments/GroupPaymentRecord';
import {MoneyUtils} from '@handsin/money';
import useMerchant from '@local/frontend/hooks/data/merchants';
import _ from 'lodash';
import {useFeatureFlagEnabled, usePostHog} from 'posthog-js/react';
import {useInitializeCustomer} from '@local/frontend/hooks/queries/customers';
import SplitTitle from '../../../components/atoms/SplitTitle';
import QRInvite from './QRInvite';
import ClipboardInvite from './ClipboardInvite';
import EmailInvite from './EmailInvite';
import SmsInvite from './SmsInvite';
import HelpToolTip from '../../../components/atoms/HelpToolTip';

interface ShareSectionProps {
  groupPayment: GroupPaymentRecord;
  showTitle?: boolean;
}

enum ShareType {
  CLIPBOARD = 'clipboard',
  QR = 'qr',
  EMAIL = 'email',
  SMS = 'sms',
}

interface ShareOptionValue {
  type: ShareType;
  description: string;
  component: JSX.Element;
  icon: JSX.Element;
  disabled?: boolean;
}

const ShareSection: FC<React.PropsWithChildren<ShareSectionProps>> = ({
  groupPayment,
  showTitle = true,
}) => {
  const posthog = usePostHog();

  useEffect(() => {
    posthog.capture('Opened invite modal');
  }, []);

  const {t} = useTranslation([
    'share-section',
    'clipboard-invite',
    'qr-invite',
    'email-invite',
    'sms-invite',
  ]);
  const merchant = useMerchant();
  const {data: owner} = useInitializeCustomer(groupPayment.ownerId);
  const joinLink = groupPayment.url;
  const enableSms = useFeatureFlagEnabled('enable-sms');
  const enableEmail = useFeatureFlagEnabled('enable-email');

  const [selectedShareOption, setSelectedShareOption] = useState(
    ShareType.CLIPBOARD
  );

  const handleSelectShareOption = (
    _event: React.MouseEvent<HTMLElement>,
    option: ShareType | null
  ) => {
    posthog.capture('Selected share option', {option});
    // enforce that one option is selected
    if (option !== null) {
      setSelectedShareOption(option);
    }
  };

  const options: ShareOptionValue[] = [
    {
      type: ShareType.CLIPBOARD,
      description: t('description', {ns: 'clipboard-invite'}),
      component: (
        <ClipboardInvite
          text={t('inviteMessage', {
            ns: 'clipboard-invite',
            merchantName: merchant.name,
            ownerFirstName: owner ? _.capitalize(owner.firstName) : '',
            totalAmount: MoneyUtils.formatMoney(groupPayment.totalMoney),
          })}
          linkToCopy={joinLink}
        />
      ),
      icon: <ContentCopyIcon />,
    },
    {
      type: ShareType.QR,
      description: t('description', {ns: 'qr-invite'}),
      component: <QRInvite linkToCopy={joinLink} />,
      icon: <QrCode2Icon />,
    },
    {
      type: ShareType.EMAIL,
      description: t('description', {ns: 'email-invite'}),
      component: <EmailInvite groupPayment={groupPayment} />,
      icon: <MailIcon />,
      disabled: !enableEmail,
    },
    {
      type: ShareType.SMS,
      description: t('description', {ns: 'sms-invite'}),
      component: (
        <SmsInvite linkToCopy={joinLink} groupPayment={groupPayment} />
      ),
      icon: <PhoneIphoneIcon />,
      disabled: !enableSms,
    },
  ];

  const selectedShareOptionValue =
    options.find(
      (option) => !option.disabled && option.type === selectedShareOption
    ) ?? options[0];

  if (!selectedShareOptionValue) {
    return null;
  }

  return (
    <Grid container spacing={2}>
      {showTitle && (
        <Grid item xs={12}>
          <SplitTitle
            title={t('splitTitle', {ns: 'share-section'})}
            subTitle={selectedShareOptionValue.description}
          >
            <HelpToolTip title={t('tooltip', {ns: 'share-section'})} arrow />
          </SplitTitle>
        </Grid>
      )}
      <Grid item xs={12}>
        <ToggleButtonGroup
          color="primary"
          value={selectedShareOption}
          exclusive
          about="share method"
          onChange={handleSelectShareOption}
        >
          {options.map(
            (option) =>
              !option.disabled && (
                <ToggleButton key={option.type} value={option.type}>
                  {option.icon}
                </ToggleButton>
              )
          )}
        </ToggleButtonGroup>
      </Grid>
      <Grid item xs={12}>
        {selectedShareOptionValue.component}
      </Grid>
    </Grid>
  );
};

export default ShareSection;
