import React, {FC} from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {useFeatureFlagEnabled} from 'posthog-js/react';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {useTheme} from '@mui/material/styles';
import {useTranslation} from 'react-i18next';

const MaintenanceBanner: FC<React.PropsWithChildren<unknown>> = () => {
  const {t} = useTranslation(['maintenance']);
  const theme = useTheme();
  const isMaintenanceBannerEnabled = useFeatureFlagEnabled(
    'enable-maintenance-mode-banner'
  );

  if (!isMaintenanceBannerEnabled) {
    return null;
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      bgcolor={theme.palette.warning.main}
      color="#000"
      mb={2}
      sx={{
        border: '6px solid #ffb101',
        borderImage:
          'repeating-linear-gradient(-55deg, #000, #000 20px, #ffb101 20px, #ffb101 40px) 10',
      }}
    >
      <Typography fontSize="14px" marginRight={0.5} fontWeight={550}>
        {t('banner.title', {ns: 'maintenance'})}
      </Typography>
      <Tooltip title={t('banner.info', {ns: 'maintenance'})} arrow>
        <InfoOutlinedIcon fontSize="inherit" />
      </Tooltip>
    </Stack>
  );
};

export default MaintenanceBanner;
