export enum SbiV2Steps {
  SELECT_PARTICIPANT = 'select', // new joiners starting modal
  VIEW_PARTICIPANT = 'view', // existing joiners starting modal
  VERIFY_PAYERS = 'verify', // owner starting modal
  ASSIGN_ITEMS = 'assign',
  SHARE_PAYMENT = 'share',
  PAY = 'pay',
  JOIN = 'join',
  HOW_IT_WORKS = 'how_it_works',
  PAYMENT_COMPLETE = 'payment_complete',
  GROUP_OVERVIEW = 'overview',
  ASSIGN_MEMBERS_TO_ITEMS = 'assign_items',
}
