import {Country, Language} from '@handsin/api-node';
import type {Namespace, TFunction} from 'i18next';
import {matchIsValidTel} from '@local/mui-phone-input';
import * as Yup from 'yup';
import {AnyObject} from 'yup/lib/types';

export const nameValidation = (
  field: string,
  t: TFunction<Namespace>
): Yup.StringSchema<string | undefined, AnyObject, string | undefined> =>
  Yup.string()
    .transform((value) => (!value ? undefined : value))
    .matches(
      /^(?!\s).+$/,
      t('nameValidation.cannotStart', {
        field,
        ns: 'validation',
      })
    )
    .matches(
      /^(?!.*\s$).+$/,
      t('nameValidation.cannotEnd', {
        field,
        ns: 'validation',
      })
    )
    .matches(
      /^(?!.*\s\s).+$/,
      t('nameValidation.cannotContain', {
        field,
        ns: 'validation',
      })
    )
    .matches(
      /^(?!\s)(?!.*\s$)(?!.*\s\s).+$/,
      t('nameValidation.invalid', {field, ns: 'validation'})
    );

export const emailValidation = (
  t: TFunction<Namespace>
): Yup.StringSchema<string | undefined, AnyObject, string | undefined> =>
  Yup.string()
    .transform((value) => (!value ? undefined : value))
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      t('emailValidation.invalid', {ns: 'validation'})
    );

export const phoneValidation = (
  t: TFunction<Namespace>
): Yup.StringSchema<string | undefined, AnyObject, string | undefined> =>
  Yup.string().test(
    'phoneValidation',
    t('phoneValidation.invalid', {ns: 'validation'}),
    (s) => !s || matchIsValidTel(s)
  );

export const getCustomerValidation = (t: TFunction<Namespace>) => ({
  firstName: nameValidation(
    t('customerValidation.firstName.field', {ns: 'validation'}),
    t
  ).required(
    t('customerValidation.firstName.required', {
      ns: 'validation',
    })
  ),
  lastName: nameValidation(
    t('customerValidation.lastName.field', {
      ns: 'validation',
    }),
    t
  ),
  email: emailValidation(t).notRequired(),
  phoneNumber: phoneValidation(t).required(
    t('customerValidation.phoneNumber.required', {
      ns: 'validation',
    })
  ),
  phoneNumberCountry: Yup.mixed<Country>()
    .oneOf(Object.values(Country))
    .required(),
  country: Yup.mixed<Country>().oneOf(Object.values(Country)).required(),
  language: Yup.mixed<Language>().oneOf(Object.values<Language>(Language)),
});
