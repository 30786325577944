import {QueryClient} from '@tanstack/react-query';
import {ModalName} from './ModalName';
import {ModalQueryData} from './ModalQueryData';

// only to be used where useCustomModals() hook can not
export const handleCloseModal = (
  queryClient: QueryClient,
  name: ModalName
): void => {
  queryClient.setQueryData<ModalQueryData>(
    ['modalConfig', name],
    (oldData) => ({
      name,
      props: {...oldData?.props, open: false},
      vars: undefined,
    })
  );
};
