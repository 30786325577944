import React, {FC} from 'react';
import {useParams} from 'react-router-dom';
import {HandsInThemeProvider, theme} from '@hands-in/theme';
import {createTheme} from '@mui/material/styles';
import useMerchantId from '../hooks/useMerchantId';
import {useInitialiseGroupPayment} from '../hooks/queries/group-payments';
import useCustomerId from '../hooks/useCustomerId';
import Loading from '../components/atoms/Loading';

const GroupPaymentCustomThemeWrapper: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const merchantId = useMerchantId();
  const customerId = useCustomerId();
  const {groupPaymentId} = useParams();
  const {data: groupPayment, isInitialLoading} = useInitialiseGroupPayment(
    groupPaymentId,
    merchantId,
    customerId,
    {enabled: !!groupPaymentId && !!merchantId}
  );

  const merchantThemeStyles = groupPayment?.pageOptions?.style;

  // create a merchant theme based on the payments theme options
  const merchantTheme = createTheme({
    ...theme,
    typography: {
      ...theme.typography,
      ...merchantThemeStyles?.typography,
    },
    palette: {
      ...theme.palette,
      ...merchantThemeStyles?.palette,
    },
    components: {
      ...theme.components,
      ...merchantThemeStyles?.components,
    },
  });

  if (isInitialLoading) {
    return <Loading />;
  }

  return (
    <HandsInThemeProvider theme={merchantTheme}>
      {children}
    </HandsInThemeProvider>
  );
};

export default GroupPaymentCustomThemeWrapper;
