import React, {FC} from 'react';
import {TooltipProps} from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import CustomTooltip from './CustomTooltip';

const HelpToolTip: FC<
  React.PropsWithChildren<Omit<TooltipProps, 'children'>>
> = ({title, placement = 'top', arrow = false}) => (
  <CustomTooltip
    enabled
    title={title}
    placement={placement}
    arrow={arrow}
    enterTouchDelay={0}
    PopperProps={{sx: {zIndex: 9000}}}
  >
    <HelpIcon sx={{color: '#AECAF6'}} fontSize="small" />
  </CustomTooltip>
);

export default HelpToolTip;
