import type {DialogProps} from '@mui/material/Dialog';
import React, {FC} from 'react';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {useCustomModals} from './useCustomModals';
import {ModalName} from './ModalName';

const GlobalModal: FC<
  React.PropsWithChildren<
    React.PropsWithChildren<
      DialogProps & {name: ModalName; canClose?: boolean; fullScreen?: boolean}
    >
  >
> = ({
  children,
  name,
  open,
  sx,
  title,
  canClose = true,
  fullScreen = true,
  ...props
}) => {
  const {closeModal} = useCustomModals();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const onClose = () => (canClose ? closeModal(name) : undefined);

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen ? isMobile : false}
      onClose={onClose}
      sx={{overflow: 'auto', ...sx}}
      {...props}
    >
      <DialogContent sx={{p: 2}}>{children}</DialogContent>
    </Dialog>
  );
};

export default GlobalModal;
