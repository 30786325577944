import {isMobileOnly, isDesktop} from 'react-device-detect';
import {Country} from '@handsin/api-node';
import useCountry from '@local/frontend/hooks/useCountry';
import React, {FC, useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import {useMutation} from '@tanstack/react-query';
import {MAXIMUM_TIMEOUT_DELAY} from '@local/frontend/constants';
import {usePostHog} from 'posthog-js/react';
import SocialInviteButton from './SocialInviteButton';

interface SmartSocialButtonsProps {
  timeout?: number;
  text: string;
  link: string;
}

const SmartSocialButtons: FC<
  React.PropsWithChildren<SmartSocialButtonsProps>
> = ({timeout = 2000, link, text}) => {
  const posthog = usePostHog();
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const {country} = useCountry();

  useEffect(
    () => () => {
      if (timeoutId) {
        // clear the timeout on unmount
        clearTimeout(timeoutId);
      }
    },
    [timeoutId]
  );

  const copyMutation = useMutation(
    async () => {
      await navigator.clipboard.writeText(link);
    },
    {
      onSuccess: () => {
        posthog.capture('Shared link', {via: 'window.clipboard'});
        if (timeoutId) {
          clearTimeout(timeoutId);
        }

        setIsCopied(true);
        const newTimeoutId = setTimeout(
          () => {
            setIsCopied(false);
          },
          Math.min(timeout, MAXIMUM_TIMEOUT_DELAY)
        );
        setTimeoutId(newTimeoutId);
      },
      onError: () => {
        console.error('Could not open share navigation');
      },
    }
  );
  const shareData: ShareData = {title: text, url: link};
  const canShare = navigator.canShare && navigator.canShare(shareData);
  const shareMutation = useMutation(
    async () => {
      if (canShare) {
        posthog.capture('Shared link', {via: 'window.navigator'});
        await navigator.share(shareData);
      }
    },
    {
      onError: () => {
        console.error('Could not open share navigation');
      },
    }
  );
  const singleLineMessage = [text, link].join('\n');
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: `repeat(auto-fill, minmax(${80}px, 1fr))`,
        gridAutoColumns: 'auto',
        gap: 1,
      }}
    >
      <SocialInviteButton
        name="Whatsapp"
        onClick={() => {
          posthog.capture('Shared link', {via: 'Whatsapp'});
        }}
        href={`https://wa.me/?text=${encodeURIComponent(singleLineMessage)}`}
        src="https://res.cloudinary.com/dvrjmbnri/image/upload/v1708440085/FHC%20-%20Do%20Not%20Touch/icons8-whatsapp_g2bvmw.svg"
      />
      <SocialInviteButton
        name="Facebook"
        href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          link
        )}`}
        src="https://res.cloudinary.com/dvrjmbnri/image/upload/v1708440086/FHC%20-%20Do%20Not%20Touch/icons8-facebook_mzxbrm.svg"
      />
      {isDesktop && (
        <SocialInviteButton
          onClick={() => {
            posthog.capture('Shared link', {via: 'Messenger'});
          }}
          name="Messenger"
          href={`https://www.facebook.com/dialog/send?app_id=${751447616993602}&link=${encodeURIComponent(
            link
          )}&redirect_uri=${encodeURIComponent(link)}`}
          src="https://res.cloudinary.com/dvrjmbnri/image/upload/v1708441806/FHC%20-%20Do%20Not%20Touch/icons8-messenger_iysmas.svg"
        />
      )}
      {country === Country.JP && (
        <SocialInviteButton
          onClick={() => {
            posthog.capture('Shared link', {via: 'Hatena'});
          }}
          name="Hatena"
          href={`http://b.hatena.ne.jp/add?mode=confirm&url=${link}&title=${text}`}
          src="https://res.cloudinary.com/dvrjmbnri/image/upload/v1708616361/FHC%20-%20Do%20Not%20Touch/icons8-hatena-an-internet-services-company-in-japan-operates-social-bookmarking-service-96_cu3ptp.png"
        />
      )}
      {[Country.UA, Country.RU, Country.PH, Country.BG].includes(country) && (
        <SocialInviteButton
          onClick={() => {
            posthog.capture('Shared link', {via: 'Viber'});
          }}
          name="Viber"
          href={`viber://forward?text=${encodeURIComponent(singleLineMessage)}`}
          src="https://res.cloudinary.com/dvrjmbnri/image/upload/v1708620590/FHC%20-%20Do%20Not%20Touch/logo_icon_purple_large_kag4e1.png"
        />
      )}
      <SocialInviteButton
        onClick={() => {
          posthog.capture('Shared link', {via: 'Telegram'});
        }}
        name="Telegram"
        href={`https://t.me/share/url?text=${encodeURIComponent(
          singleLineMessage
        )}&url=${encodeURIComponent(link)}`}
        src="https://res.cloudinary.com/dvrjmbnri/image/upload/v1708440086/FHC%20-%20Do%20Not%20Touch/icons8-telegram-app_gwtn8h.svg"
      />
      <SocialInviteButton
        onClick={() => {
          posthog.capture('Shared link', {via: 'Gmail'});
        }}
        name="Gmail"
        href={`https://mail.google.com/mail/?view=cm&fs=1&tf=1&su=${encodeURIComponent(
          text
        )}&body=${encodeURIComponent(link)}`}
        src="https://res.cloudinary.com/dvrjmbnri/image/upload/v1708440085/FHC%20-%20Do%20Not%20Touch/icons8-gmail_gylewh.svg"
      />
      <SocialInviteButton
        onClick={() => {
          posthog.capture('Shared link', {via: 'Snapchat'});
        }}
        name="Snapchat"
        src="https://res.cloudinary.com/dvrjmbnri/image/upload/v1708597411/FHC%20-%20Do%20Not%20Touch/icons8-snapchat_kxxnxl.svg"
        href={`https://www.snapchat.com/scan?attachmentUrl=${encodeURIComponent(
          link
        )}`}
      />
      {isMobileOnly && (
        <SocialInviteButton
          onClick={() => {
            posthog.capture('Shared link', {via: 'SMS'});
          }}
          name="SMS"
          href={`sms:;?&body=${encodeURIComponent(singleLineMessage)}`}
          src="https://res.cloudinary.com/dvrjmbnri/image/upload/v1708615325/FHC%20-%20Do%20Not%20Touch/icons8-sms-100_rvitam.png"
        />
      )}
      <SocialInviteButton
        onClick={() => {
          posthog.capture('Shared link', {via: 'Email'});
        }}
        tooltip="Send email"
        name="Email"
        href={`mailto:?&subject=${encodeURIComponent(
          text
        )}&body=${encodeURIComponent(link)}`}
        src="https://res.cloudinary.com/dvrjmbnri/image/upload/v1708615169/FHC%20-%20Do%20Not%20Touch/email_dcrawx.png"
      />
      <SocialInviteButton
        onClick={() => {
          posthog.capture('Shared link', {via: 'Link'});
          copyMutation.mutate();
        }}
        tooltip={isCopied ? 'Copied!' : 'Click to copy'}
        name="Link"
        src={
          isCopied
            ? 'https://res.cloudinary.com/dvrjmbnri/image/upload/v1708442492/FHC%20-%20Do%20Not%20Touch/icons8-success_sjppw4.svg'
            : 'https://res.cloudinary.com/dvrjmbnri/image/upload/v1708441857/FHC%20-%20Do%20Not%20Touch/icons8-link-50_1_g2szih.png'
        }
      />
      {canShare && (
        <SocialInviteButton
          onClick={() => {
            posthog.capture('Shared link', {via: 'Other'});
            shareMutation.mutate();
          }}
          name="Other"
          tooltip="Share using other apps"
          src="https://res.cloudinary.com/dvrjmbnri/image/upload/v1708615400/FHC%20-%20Do%20Not%20Touch/icons8-other-100_ncznya.png"
        />
      )}
    </Box>
  );
};

export default SmartSocialButtons;
