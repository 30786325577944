import React, {createContext, useMemo} from 'react';
import {useUpdateGroupPayment} from '../hooks/mutations/groups-payments';
import {UpdateGroupPaymentMutationContextType} from './@types/UpdateGroupPaymentMutationContextType';

export const UpdateGroupPaymentMutationContext = createContext<
  UpdateGroupPaymentMutationContextType | undefined
>(undefined);

interface UpdateGroupPaymentMutationProviderProps {
  children: React.ReactNode;
}

const UpdateGroupPaymentMutationProvider = ({
  children,
}: UpdateGroupPaymentMutationProviderProps): React.JSX.Element => {
  const updateGroupPaymentMutation = useUpdateGroupPayment();

  const updateGroup = useMemo(
    () => ({updateGroupPaymentMutation}),
    [updateGroupPaymentMutation]
  );

  return (
    <UpdateGroupPaymentMutationContext.Provider value={updateGroup}>
      {children}
    </UpdateGroupPaymentMutationContext.Provider>
  );
};

export default UpdateGroupPaymentMutationProvider;
