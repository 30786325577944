import React, {FC} from 'react';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import useCachedGroupPayment from '@local/frontend/hooks/useCachedGroupPayment';
import {useNotification} from '@local/frontend/hooks/useNotification';
import {
  emailValidation,
  getCustomerValidation,
  phoneValidation,
} from '@local/frontend/validators/custom-validation';
import {useCreateCustomer} from '@local/frontend/hooks/mutations/customers';
import {useInitializeCustomers} from '@local/frontend/hooks/queries/customers';
import {Customer} from '@local/frontend/@types/updated-api-types/customer/Customer';
import {useCustomModals} from '@local/frontend/libs/modals/useCustomModals';
import {ModalName} from '@local/frontend/libs/modals/ModalName';
import useCustomer from '@local/frontend/hooks/data/customers';
import useCustomerCountry from '@local/frontend/hooks/useCustomerCountry';
import useCustomerId from '@local/frontend/hooks/useCustomerId';
import {useInviteToGroupPayment} from '@local/frontend/libs/trpc/trpc';
import useMerchant from '@local/frontend/hooks/data/merchants';
import GroupMemberForm from '../forms/GroupMemberForm';
import {GroupMemberFormValues} from '../forms/@types/group-member-form';
import {useCurrentLanguage} from '../../atoms/inputs/LanguageSelect/useCurrentLanguage';
import {getSupportedLanguages} from '../../atoms/inputs/LanguageSelect/getSupportedLanguages';
import ModalHeader from './ModalHeader';

const AddGroupMemberModal: FC<React.PropsWithChildren<unknown>> = () => {
  const merchant = useMerchant();
  const cid = useCustomerId();
  const {t} = useTranslation(['modals', 'group-member-form']);
  const customer = useCustomer(cid);
  const {open: openNotification} = useNotification();
  const {closeModal} = useCustomModals();
  const groupPayment = useCachedGroupPayment();
  const customerValidation = getCustomerValidation(t);
  const currentLanguage = useCurrentLanguage();
  const supportedLanguagesMap = getSupportedLanguages();
  const currentLanguageMetaData = supportedLanguagesMap[currentLanguage];

  const addGroupMemberFormValidationSchema = Yup.object({
    ...customerValidation,
    email: emailValidation(t).notRequired(),
    phoneNumber: phoneValidation(t).notRequired(),
  });

  const customerCountry = useCustomerCountry(cid);

  const formMethods = useForm<
    Yup.InferType<typeof addGroupMemberFormValidationSchema>
  >({
    mode: 'all',
    resolver: yupResolver(addGroupMemberFormValidationSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      phoneNumberCountry: customerCountry,
      language: customer?.language ?? currentLanguageMetaData?.locale,
      country: customerCountry,
    },
  });

  const {
    formState: {isValid},
  } = formMethods;

  const createCustomerMutation = useCreateCustomer();
  const inviteGroupMemberMutation = useInviteToGroupPayment();

  const allGroupMemberIds = groupPayment.memberIds.concat(
    groupPayment.invited ?? []
  );

  const customerQueries = useInitializeCustomers(allGroupMemberIds, {
    refetchOnWindowFocus: false,
  });

  const allGroupMembers = customerQueries
    .map((customerQuery) => customerQuery.data)
    .filter((maybeCustomer): maybeCustomer is Customer => !!maybeCustomer);

  const handleAddMemberSubmit = async (formValues: GroupMemberFormValues) => {
    try {
      if (!cid) {
        throw new Error('No customerId was present in url');
      }

      // check if email already exists in the group
      if (
        allGroupMembers.some(
          (member) =>
            !!member.email &&
            !!formValues.email &&
            member.email.toLowerCase() === formValues.email.toLowerCase()
        )
      ) {
        openNotification({
          message: t('addGroupMemberModal.duplicateEmail', {ns: 'modals'}),
          severity: 'error',
        });

        return;
      }

      if (
        allGroupMembers.some(
          (member) =>
            !!member.phoneNumber &&
            !!formValues.phoneNumber &&
            member.phoneNumber === formValues.phoneNumber
        )
      ) {
        openNotification({
          message: t('addGroupMemberModal.duplicatePhone', {ns: 'modals'}),
          severity: 'error',
        });

        return;
      }

      const createdCustomer = await createCustomerMutation.mutateAsync({
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        email: formValues.email?.toLowerCase(),
        phoneNumber: formValues.phoneNumber,
        language: formValues.language,
        address: formValues.country ? {country: formValues.country} : undefined,
      });

      await inviteGroupMemberMutation.mutateAsync(
        {
          groupPaymentId: groupPayment.id,
          customerIds: [createdCustomer.id],
          merchantId: merchant.id,
          ownerId: cid,
        },
        {
          onSuccess: () => {
            openNotification({
              message: t('addGroupMemberModal.success', {ns: 'modals'}),
              severity: 'success',
            });

            closeModal(ModalName.ADD_GROUP_MEMBER);
          },
        }
      );
    } catch (error) {
      openNotification({
        message: t('addGroupMemberModal.error', {ns: 'modals'}),
        severity: 'error',
      });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ModalHeader
          modalName={ModalName.ADD_GROUP_MEMBER}
          title={t('addGroupMemberModal.title', {ns: 'modals'})}
          subtitle={t('addGroupMemberModal.subtitle', {ns: 'modals'})}
          subtitleTypographyProps={{fontWeight: 400, fontSize: 14}}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <FormProvider {...formMethods}>
          <GroupMemberForm
            buttonText={t('buttonText.primary', {
              ns: 'group-member-form',
            })}
            onSubmit={handleAddMemberSubmit}
            loading={
              inviteGroupMemberMutation.isLoading ||
              createCustomerMutation.isLoading
            }
            disabled={
              !isValid ||
              inviteGroupMemberMutation.isLoading ||
              createCustomerMutation.isLoading
            }
          />
        </FormProvider>
      </Grid>
    </Grid>
  );
};

export default AddGroupMemberModal;
