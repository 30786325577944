import {useQueryClient} from '@tanstack/react-query';
import {
  closeNotification,
  openNotification,
} from '../helpers/notifications/notification-helpers';
import {NotifcationQueryParams} from '../helpers/notifications/@types';

export const useNotification = () => {
  const queryClient = useQueryClient();

  return {
    open: (notificationParams: NotifcationQueryParams) =>
      openNotification(queryClient, notificationParams),
    close: () => closeNotification(queryClient),
  };
};
