import {UseMutationResult, useMutation} from '@tanstack/react-query';
import {useIntercom} from 'react-use-intercom';
import useIntercomLauncher from './useIntercomLauncher';
import {useIntercomHash} from '../libs/trpc/trpc';
import {useInitializeCustomer} from './queries/customers';
import useCustomerId from './useCustomerId';
import {formatFullName} from '../util/stringFormatters';
import useMerchant from './data/merchants';

const useChatSupport = (): UseMutationResult<void, unknown, void, unknown> => {
  const merchant = useMerchant();
  const {boot, show} = useIntercom();
  const {isLauncherOpen, setIsLauncherOpen, setHasBooted} =
    useIntercomLauncher();
  const intercomHashMutation = useIntercomHash();
  const customerId = useCustomerId();
  const {data: customer} = useInitializeCustomer(customerId);

  const handleDisplayChatSupportMutation = useMutation(
    async () => {
      // early terminate if launcher is already open
      if (isLauncherOpen) {
        return;
      }

      // generate a hash for identify verification to prevent user impersonation through the intercom messenger
      const hash = customer
        ? await intercomHashMutation.mutateAsync({
            customerId: customer.id,
          })
        : undefined;

      boot({
        userHash: hash,
        userId: customer?.id,
        email: customer?.email,
        createdAt: customer?.createdAt,
        name: customer
          ? formatFullName(customer.firstName, customer.lastName)
          : undefined,
        phone: customer?.phoneNumber,
        company: {
          companyId: merchant.id,
          name: merchant.name,
        },
      });
      show();
      setHasBooted(true);
      setIsLauncherOpen(true);
    },
    {
      onError: (err) => {
        console.error(err);
      },
    }
  );

  return handleDisplayChatSupportMutation;
};

export default useChatSupport;
