import * as Yup from 'yup';
import type {Namespace, TFunction} from 'i18next';
import type {
  AnyObject,
  OptionalObjectSchema,
  TypeOfShape,
} from 'yup/lib/object';
import type {RequiredStringSchema} from 'yup/lib/string';
import {emailValidation} from './custom-validation';

export interface ReInviteFormValues {
  email: string;
}

export const getResendInviteValidationSchema = (
  t: TFunction<Namespace>
): OptionalObjectSchema<
  {
    email: RequiredStringSchema<string | undefined, AnyObject>;
  },
  AnyObject,
  TypeOfShape<{
    email: RequiredStringSchema<string | undefined, AnyObject>;
  }>
> =>
  Yup.object({
    email: emailValidation(t).required(
      t('resendInviteValidationSchema.email.required', {
        ns: 'validation',
      })
    ),
  });
