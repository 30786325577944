import {CircularProgress} from '@mui/material';
import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {ModalName} from '@local/frontend/libs/modals/ModalName';
import {useRelevantCheckoutIdsMap} from '../../../../hooks/data/ids';
import {useCustomer} from '../../../../hooks/queries/customers';
import NoticeAlert from '../../../atoms/NoticeAlert';
import FeedbackForm from '../../forms/FeedbackForm';
import ModalHeader from '../ModalHeader';

const FeedbackModal: FC<React.PropsWithChildren<unknown>> = () => {
  const {t} = useTranslation(['modals']);
  const relevantCheckoutIds = useRelevantCheckoutIdsMap();
  const {
    data: customer,
    isLoading: isCustomerLoading,
    isError: errorLoadingCustomer,
  } = useCustomer(relevantCheckoutIds?.customerId);

  if (isCustomerLoading) {
    return <CircularProgress size={21} color="primary" />;
  }

  if (errorLoadingCustomer || !customer) {
    return (
      <NoticeAlert
        severity="error"
        message={t('feedbackModal.errors.noCustomer', {ns: 'modals'})}
      />
    );
  }

  return (
    <>
      <ModalHeader
        title={t('feedbackModal.title', {ns: 'modals'})}
        modalName={ModalName.FEEDBACK_MODAL}
      />
      <FeedbackForm customer={customer} />
    </>
  );
};

export default FeedbackModal;
