import React, {CSSProperties, FC} from 'react';
import Tooltip, {TooltipProps} from '@mui/material/Tooltip';

interface CustomTooltipProps extends TooltipProps {
  enabled?: boolean;
  style?: CSSProperties;
}

const CustomTooltip: FC<React.PropsWithChildren<CustomTooltipProps>> = ({
  enabled = false,
  style,
  children,
  title,
  ...props
}) => (
  <Tooltip
    title={enabled ? title : ''}
    arrow
    enterTouchDelay={0}
    PopperProps={{sx: {zIndex: 9000}}}
    {...props}
  >
    <span style={style}>{children}</span>
  </Tooltip>
);

export default CustomTooltip;
