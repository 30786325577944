import {AxiosError} from 'axios';
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';
import type {DefaultResponseError} from '@local/backend/@types/updated-api-types/DefaultResponseError';
import {FeedbackRecord} from '../../../@types/updated-api-types/feedback/FeedbackRecord';
import {createFeedback} from '../../../libs/api/feedback.actions';
import {CreateFeedbackMutationParams} from '../@types/feedback';

export const useCreateFeedback = (): UseMutationResult<
  FeedbackRecord,
  AxiosError<DefaultResponseError>,
  CreateFeedbackMutationParams,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation<
    FeedbackRecord,
    AxiosError<DefaultResponseError>,
    CreateFeedbackMutationParams,
    unknown
  >(createFeedback, {
    onSuccess: (feedbackRecord) => {
      queryClient.setQueryData<FeedbackRecord>(
        ['feedback', feedbackRecord.customerId],
        feedbackRecord
      );
    },
  });
};
