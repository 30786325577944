import MultiCardPerPageProvider from '@local/frontend/providers/MultiCardPerPageProvider';
import React, {FC} from 'react';
import {useParams} from 'react-router-dom';

const MultiCardPerPageVariantWrapper: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const params = useParams();
  if (!params || !params.multiCardId) {
    throw new Error('Invalid multi-card ID');
  }

  return (
    <MultiCardPerPageProvider multiCardId={params.multiCardId}>
      {children}
    </MultiCardPerPageProvider>
  );
};

export default MultiCardPerPageVariantWrapper;
