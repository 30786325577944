import {useFeatureFlagEnabled} from 'posthog-js/react';
import React, {FC} from 'react';
import {Navigate, useParams} from 'react-router-dom';

const MaintenanceRedirectWrapper: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const currentUrl = new URL(window.location.href);
  const {groupPaymentId, checkoutId, multiCardId} =
    useParams<Record<string, string>>();
  const merchantId = currentUrl.searchParams.get('mid');
  const customerId = currentUrl.searchParams.get('cid');
  const isMaintenancePageEnabled = useFeatureFlagEnabled(
    'enable-maintenance-mode-page'
  );

  let redirectUrl = `/under-maintenance?mid=${merchantId}&cid=${customerId}&redirectUrl=${currentUrl}`;

  // need one of below to be able to retrieve an access token for API requests
  if (groupPaymentId) {
    redirectUrl = `${redirectUrl}&groupPaymentId=${groupPaymentId}`;
  } else if (checkoutId) {
    redirectUrl = `${redirectUrl}&checkoutId=${checkoutId}`;
  } else if (multiCardId) {
    redirectUrl = `${redirectUrl}&multiCardId=${multiCardId}`;
  }

  if (isMaintenancePageEnabled) {
    return <Navigate replace to={redirectUrl} />;
  }

  return <>{children}</>;
};

export default MaintenanceRedirectWrapper;
