import {AxiosError} from 'axios';
import {UseMutationResult, useMutation} from '@tanstack/react-query';
import type {DefaultResponseError} from '@local/backend/@types/updated-api-types/DefaultResponseError';
import {shortenUrlWithProxy} from '../../../libs/api/proxies.actions';
import {CreateRedirectProxyMutationParams} from '../@types';

export const useProxyMutation = (): UseMutationResult<
  string,
  AxiosError<DefaultResponseError>,
  CreateRedirectProxyMutationParams,
  unknown
> =>
  useMutation<
    string,
    AxiosError<DefaultResponseError>,
    CreateRedirectProxyMutationParams
  >(shortenUrlWithProxy);
