import type {Namespace, TFunction} from 'i18next';
import dayjs from 'dayjs';

interface SmsVariables {
  ownerFirstName: string;
  ownerLastName?: string;
  merchantName: string;
  // 'invite' extra info = share amount (e.g. '£5.20')
  // 'reminder' extra info = time left (e.g. '10 minutes')
  extraInfo: string | undefined;
  linkToJoin: string;
}

export function formatSmsText(
  variables: SmsVariables,
  smsType: string,
  t: TFunction<Namespace>
): string {
  const {ownerFirstName, ownerLastName, merchantName, extraInfo, linkToJoin} =
    variables;
  // initialize permanent parts
  let startPart = '';
  switch (smsType) {
    case 'reminder':
      startPart = t('formatters.sms.start.reminder', {ns: 'common'});
      break;
    case 'invite':
      startPart = t('formatters.sms.start.invite', {ns: 'common'});
      break;
    default:
      break;
  }
  const groupPaymentPart = t('formatters.sms.groupPayment', {
    ns: 'common',
  });
  const linkPart = `${t('formatters.sms.link', {
    ns: 'common',
  })} ${linkToJoin}`;
  let charactersLeft = 160;
  charactersLeft -= (startPart + groupPaymentPart + linkPart).length;
  // initialize variable parts with min chars (initializes sms with min words/chars)
  let ownerNamePart = `${ownerFirstName[0]}`;
  if (ownerLastName) {
    ownerNamePart += `.${ownerLastName[0]}`;
  }
  let merchantPart = '';
  let extraInfoPart = '';

  function checkNewSmsLength() {
    if (
      charactersLeft - (ownerNamePart + merchantPart + extraInfoPart).length >=
      0
    ) {
      return true;
    }
    return false;
  }

  // try using owner first name
  let previousPartText = ownerNamePart;
  ownerNamePart = ownerFirstName;
  if (!checkNewSmsLength()) {
    ownerNamePart = previousPartText;
  }

  // try including merchant name
  previousPartText = merchantPart;
  merchantPart = t('formatters.sms.merchantPart', {
    merchantName,
    ns: 'common',
  });
  if (!checkNewSmsLength()) {
    merchantPart = previousPartText;
  }

  // try including extra info
  if (extraInfo) {
    previousPartText = extraInfoPart;
    switch (smsType) {
      case 'reminder':
        extraInfoPart = t('formatters.sms.extraInfo.reminder', {
          extraInfo,
          ns: 'common',
        });
        break;
      case 'invite':
        extraInfoPart = t('formatters.sms.extraInfo.invite', {
          extraInfo,
          ns: 'common',
        });
        break;
      default:
        break;
    }
    if (!checkNewSmsLength()) {
      extraInfoPart = previousPartText;
    }
  }

  // try including second name initial
  previousPartText = ownerNamePart;
  ownerNamePart = `${ownerFirstName}`;
  if (ownerLastName) {
    ownerNamePart += ` ${ownerLastName[0]}`;
  }
  if (!checkNewSmsLength()) {
    ownerNamePart = previousPartText;
  }

  // try using full name
  previousPartText = ownerNamePart;
  ownerNamePart = `${ownerFirstName} ${ownerLastName}`;
  if (!checkNewSmsLength()) {
    ownerNamePart = previousPartText;
  }

  return `${startPart}${ownerNamePart}${groupPaymentPart}${merchantPart}${extraInfoPart}${linkPart}`;
}

export function formatTimeLeft(
  secondsLeft: number,
  t: TFunction<Namespace>
): string {
  const days = Math.round(secondsLeft / (60 * 60 * 24));
  if (days >= 1) {
    return t('formatters.timeLeft.day', {
      count: days,
      ns: 'common',
    });
  }

  const hours = Math.round(secondsLeft / (60 * 60));
  if (hours >= 1) {
    return t('formatters.timeLeft.hour', {
      count: hours,
      ns: 'common',
    });
  }

  const minutes = secondsLeft >= 60 ? Math.floor(secondsLeft / 60) : 0;
  if (minutes >= 1) {
    return t('formatters.timeLeft.minute', {
      count: minutes,
      ns: 'common',
    });
  }

  const seconds = secondsLeft >= 0 ? Math.round(secondsLeft - minutes * 60) : 0;
  return t('formatters.timeLeft.second', {
    count: seconds,
    ns: 'common',
  });
}

export const formatFullName = (
  firstName: string,
  lastName?: string
): string => {
  let name;
  if (lastName) {
    name = `${firstName} ${lastName}`;
  } else {
    name = firstName;
  }
  return name.replace(/\b\w/g, (word) => word.toUpperCase());
};

export const maskEmailString = (email: string, mask = '*'): string => {
  const emailParts = email.split('@');
  const emailUsername = emailParts[0] ?? '';
  const emailDomain = emailParts[1] ?? '';
  const usernameLength = emailUsername.length;

  let maskedEmailUsername = '';

  if (usernameLength <= 1) {
    // show character if email is a single letter
    maskedEmailUsername = emailUsername;
  } else if (usernameLength === 2) {
    // show first character and astrisk next if email is only 2 letters long
    maskedEmailUsername = `${emailUsername.charAt(0)}${mask}`;
  } else if (usernameLength > 2) {
    // show first and last character and astrisk the rest in between if more than 2 letters long
    maskedEmailUsername =
      emailUsername.charAt(0) +
      mask.repeat(usernameLength - 2) +
      emailUsername.charAt(usernameLength - 1);
  }

  return `${maskedEmailUsername}@${emailDomain}`;
};

export function formatDateTime(date: Date): string {
  return dayjs(date).format('LLLL z');
}
