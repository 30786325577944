import React from 'react';
import {Button, ButtonProps, CircularProgressProps} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

export interface LoadingButtonProps extends ButtonProps {
  loading?: boolean;
  progressProps?: CircularProgressProps;
  component?: React.ElementType;
}

const LoadingButton = React.forwardRef<HTMLButtonElement, LoadingButtonProps>(
  ({loading = false, children, progressProps, onClick, ...props}, ref) => (
    <Button {...props} onClick={loading ? undefined : onClick} ref={ref}>
      {loading ? (
        <CircularProgress color="inherit" size={21} {...progressProps} />
      ) : (
        children
      )}
    </Button>
  )
);

LoadingButton.displayName = 'LoadingButton';

export default LoadingButton;
