import React, {FC, useEffect, useMemo} from 'react';
import type {GroupPaymentRecord} from '@local/backend/@types/updated-api-types/group-payments/GroupPaymentRecord';
import Stack from '@mui/material/Stack';
import {MoneyUtils} from '@handsin/money';
import {Trans, useTranslation} from 'react-i18next';
import {Customer} from '@local/frontend/@types/updated-api-types/customer/Customer';
import useCachedGroupPayment from '@local/frontend/hooks/useCachedGroupPayment';
import {useSplitByItemHelper} from '@local/frontend/hooks/queries/frontend/SBI/useSplitByItemHelper';
import GroupPaymentHelper from '@local/frontend/util/GroupPaymentHelper';
import {formatFullName} from '@local/frontend/util/stringFormatters';
import {useTheme} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {useCustomModals} from '@local/frontend/libs/modals/useCustomModals';
import {ModalName} from '@local/frontend/libs/modals/ModalName';
import useCustomerId from '@local/frontend/hooks/useCustomerId';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ParticipantAvatar from '../../atoms/avatars/ParticipantAvatar';
import NoticeAlert from '../../atoms/NoticeAlert';

// sort customers by relevance - owner > non-joined > order created in
const sortByRelevance = (
  a: Customer,
  b: Customer,
  groupPayment: GroupPaymentRecord
) => {
  const groupPaymentHelper = new GroupPaymentHelper(groupPayment);
  const aIsOwner = a.id === groupPayment.ownerId;
  const bIsOwner = b.id === groupPayment.ownerId;

  const aHasJoined = groupPaymentHelper.hasJoinedGroup(a.id);
  const bHasJoined = groupPaymentHelper.hasJoinedGroup(b.id);

  if (aIsOwner) return -1;
  if (bIsOwner) return 1;

  if (!aHasJoined && bHasJoined) return -1;
  if (!bHasJoined && aHasJoined) return 1;

  // check if either customer does not have a createdAt date
  if (!a.createdAt || !b.createdAt) {
    if (!a.createdAt && !b.createdAt) {
      return 0; // Both a and b are falsy thus considered equal
    }

    if (!a.createdAt) {
      return -1; // a.createdAt is falsy (0), so a should come before b
    }

    return 1; // b.createdAt is falsy (0), so b should come after a
  }

  if (a.createdAt < b.createdAt) {
    return -1;
  }

  return 1;
};

interface GroupMemberAvatarTabItemProps {
  customer: Customer;
  isSelected: boolean;
  onMemberClick: (customerId: string) => void;
  groupPayment: GroupPaymentRecord;
}

const GroupMemberAvatarTabItem: FC<GroupMemberAvatarTabItemProps> = ({
  customer,
  isSelected,
  onMemberClick,
  groupPayment,
}) => {
  const theme = useTheme();
  const sbiHelper = useSplitByItemHelper(groupPayment);
  const currentCustomerShare = sbiHelper.calculateShareOf(customer.id);

  const {t} = useTranslation(['glossary', 'group-member-slider']);
  const itemCount = sbiHelper.getAllocatedItemCount(customer.id);

  const color = isSelected
    ? theme.palette.primary.lighter
    : theme.palette.primary.dark;

  const backgroundColor = isSelected
    ? theme.palette.primary.dark
    : theme.palette.primary.lighter;

  return (
    <Stack spacing={1} alignItems="center">
      <Box sx={{pt: 1}}>
        <ParticipantAvatar
          firstName={customer.firstName}
          lastName={customer.lastName}
          sx={{
            backgroundColor,
            color,
            '&:hover': isSelected
              ? {}
              : {
                  backgroundColor: color,
                  color: backgroundColor,
                  cursor: 'pointer',
                },
          }}
          badgeProps={{
            color: itemCount > 0 ? 'success' : 'warning',
            badgeContent: itemCount.toString(),
          }}
          onClick={isSelected ? undefined : () => onMemberClick(customer.id)}
        />
      </Box>
      <Box>
        <Stack>
          <Typography variant="subtitle1" align="center">
            {formatFullName(customer.firstName, customer.lastName)}
          </Typography>
          <Typography variant="caption" align="center">
            {t('total', {ns: 'glossary'})}{' '}
            {MoneyUtils.formatMoney(currentCustomerShare)} • {itemCount}{' '}
            {t('item', {ns: 'glossary', count: itemCount})}
          </Typography>
        </Stack>
      </Box>
    </Stack>
  );
};

interface GroupMemberAvatarSelectorProps {
  customers: Customer[];
  selectedGroupMemberId: string | undefined;
  setSelectedGroupMemberId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
}

const GroupMemberAvatarSelector: FC<
  React.PropsWithChildren<GroupMemberAvatarSelectorProps>
> = ({customers, selectedGroupMemberId, setSelectedGroupMemberId}) => {
  const {t} = useTranslation(['glossary', 'group-member-slider']);
  const {openModal} = useCustomModals();
  const groupPayment = useCachedGroupPayment();
  const currentCustomerId = useCustomerId();
  const sortedCustomers = useMemo(
    () => customers.sort((a, b) => sortByRelevance(a, b, groupPayment)),
    [customers, groupPayment]
  );

  useEffect(() => {
    if (sortedCustomers.at(0)?.id) {
      setSelectedGroupMemberId(sortedCustomers.at(0)?.id);
    }
  }, []);

  const handleOpenAddMember = () => {
    openModal(ModalName.ADD_GROUP_MEMBER, {});
  };

  const isOwner = groupPayment.ownerId === currentCustomerId;
  const owner = sortedCustomers.find((c) => c.id === groupPayment.ownerId);

  if (customers.length < 1) {
    return (
      <Stack spacing={1}>
        <NoticeAlert severity="info">
          <Typography variant="body2">
            <Trans
              ns="group-member-slider"
              i18nKey={isOwner ? 'noCustomersOwner' : 'noCustomersJoiner'}
              components={{strong: <span style={{fontWeight: 600}} />}}
              values={{
                ownerName: owner
                  ? `(${formatFullName(owner.firstName, owner.lastName)})`
                  : '',
              }}
            />
          </Typography>
        </NoticeAlert>
        {isOwner && (
          <Button variant="contained" fullWidth onClick={handleOpenAddMember}>
            {t('button', {ns: 'group-member-slider'})}
          </Button>
        )}
      </Stack>
    );
  }

  const handleSelectMember = (memberId: string) => {
    setSelectedGroupMemberId(memberId);
  };

  return (
    // box has been included to fix issue with tabs collapsing on overflow
    <Box sx={{display: 'flex', justifyContent: 'center'}}>
      <Tabs
        value={selectedGroupMemberId}
        onChange={(_e, v) => handleSelectMember(v)}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        {sortedCustomers.map((customer) => {
          const isSelected = customer.id === selectedGroupMemberId;

          return (
            <Tab
              key={customer.id}
              value={customer.id}
              icon={
                <GroupMemberAvatarTabItem
                  customer={customer}
                  isSelected={isSelected}
                  groupPayment={groupPayment}
                  onMemberClick={handleSelectMember}
                />
              }
            />
          );
        })}
      </Tabs>
    </Box>
  );
};

export default GroupMemberAvatarSelector;
