import {useParams} from 'react-router-dom';
import {Checkout} from '../@types/updated-api-types/checkouts/Checkout';
import useCheckout from './data/checkouts';

const useCachedCheckout = (): Checkout => {
  const {checkoutId} = useParams<Record<string, string>>();
  const checkoutRecord = useCheckout(checkoutId);
  if (!checkoutRecord) {
    throw new Error(
      'useCheckoutCache can only be used from within the checkout query provider'
    );
  }

  return checkoutRecord;
};

export default useCachedCheckout;
