import Button from '@mui/material/Button';
import Dialog, {DialogProps} from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import type {GroupPaymentRecord} from '@local/backend/@types/updated-api-types/group-payments/GroupPaymentRecord';

import LoadingButton from '../../../atoms/buttons/LoadingButton';
import NoticeAlert from '../../../atoms/NoticeAlert';

interface RemoveCustomerDialogProps extends DialogProps {
  handleReject: () => void;
  handleConfirm: (isDecreasingGroupSize?: boolean) => void;
  isKickLoading: boolean;
  isKickAndUpdateLoading: boolean;
  customerBeingRemoved: string;
  groupPayment: GroupPaymentRecord;
}

const RemoveCustomerDialog: FC<
  React.PropsWithChildren<RemoveCustomerDialogProps>
> = ({
  groupPayment,
  isKickLoading,
  isKickAndUpdateLoading,
  handleReject,
  handleConfirm,
  customerBeingRemoved,
  ...props
}) => {
  const {t} = useTranslation(['remove-customer-dialog']);
  const canKickAndReduce =
    groupPayment.splitAllocation && groupPayment.splitAllocation - 1 >= 2;

  return (
    <Dialog {...props}>
      <DialogTitle id="alert-dialog-title">
        {t('title', {
          ns: 'remove-customer-dialog',
          customerBeingRemoved,
        })}
      </DialogTitle>
      {canKickAndReduce && (
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            variant="subtitle1"
            sx={{lineHeight: '1.25'}}
          >
            <NoticeAlert
              message={t('body', {ns: 'remove-customer-dialog'})}
              severity="info"
            />
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid
            item
            xs={canKickAndReduce ? 12 : 6}
            sm={canKickAndReduce ? 12 : 'auto'}
            order={canKickAndReduce ? 1 : 0}
          >
            <Button
              fullWidth
              onClick={handleReject}
              disabled={isKickLoading || isKickAndUpdateLoading}
            >
              {t('button.reject', {ns: 'remove-customer-dialog'})}
            </Button>
          </Grid>
          <Grid
            item
            xs={canKickAndReduce ? 12 : 6}
            sm={canKickAndReduce ? 12 : 'auto'}
          >
            <Grid container justifyContent="flex-end" spacing={2}>
              {canKickAndReduce && (
                <Grid item xs sm="auto">
                  <LoadingButton
                    fullWidth
                    onClick={() => handleConfirm(true)}
                    disabled={isKickLoading || isKickAndUpdateLoading}
                    loading={isKickAndUpdateLoading}
                    variant="outlined"
                  >
                    {t('button.options.removeUserAndDecreaseGroupSize', {
                      ns: 'remove-customer-dialog',
                    })}
                  </LoadingButton>
                </Grid>
              )}
              <Grid item xs>
                <LoadingButton
                  fullWidth
                  onClick={() => handleConfirm()}
                  disabled={isKickLoading || isKickAndUpdateLoading}
                  loading={isKickLoading}
                  variant="outlined"
                >
                  {t('button.options.removeUser', {
                    ns: 'remove-customer-dialog',
                  })}
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveCustomerDialog;
