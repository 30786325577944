import React, {createContext, useState, ReactNode, useMemo} from 'react';

export type MerchantStyleContextType = {
  fontFamily?: string;
  setFontFamily: (fontFamily?: string) => void;
};

export const MerchantStyleContext = createContext<
  MerchantStyleContextType | undefined
>(undefined);

interface MerchantStyleProviderProps {
  children: ReactNode;
  initialFontFamily?: string; // Add a prop for initial fontFamily
}

const MerchantStyleProvider: React.FC<MerchantStyleProviderProps> = ({
  children,
  initialFontFamily,
}) => {
  const [fontFamily, setFontFamily] = useState<string | undefined>(
    initialFontFamily
  );

  const merchantStyles = useMemo<MerchantStyleContextType>(
    () => ({fontFamily, setFontFamily}),
    [fontFamily]
  );

  return (
    <MerchantStyleContext.Provider value={merchantStyles}>
      {children}
    </MerchantStyleContext.Provider>
  );
};

export default MerchantStyleProvider;
