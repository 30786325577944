import React, {FC} from 'react';
import {useParams, useSearchParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useInitialiseGroupPayment} from '../hooks/queries/group-payments';
import ErrorPage from '../pages/ErrorPage';

const GroupPaymentProvider: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const {t} = useTranslation(['common']);
  const {groupPaymentId} = useParams<Record<string, string>>(); // path params
  const [searchParams] = useSearchParams(); // query params
  const merchantId = searchParams.get('mid') ?? undefined;
  const customerId = searchParams.get('cid') ?? undefined;

  const {data: groupPayment, isInitialLoading: isGroupPaymentLoading} =
    useInitialiseGroupPayment(groupPaymentId, merchantId, customerId);

  if (isGroupPaymentLoading) {
    return null;
  }

  if (!groupPayment) {
    return (
      <ErrorPage errorMessage={t('errors.noGroupPayment', {ns: 'common'})} />
    );
  }

  return <>{children}</>;
};

export default GroupPaymentProvider;
