import React, {FC, useEffect, useMemo} from 'react';
import {useQueryClient} from '@tanstack/react-query';
import GroupPaymentWebsocketClient from './GroupPaymentWebsocketClient';
import {RelevantCheckoutIdsMap} from '../hooks/queries/@types';

const GroupPaymentWebsocketWrapper: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const socketClient = useMemo(() => new GroupPaymentWebsocketClient(), []);
  const queryClient = useQueryClient();
  const authData = queryClient.getQueryData<RelevantCheckoutIdsMap>(['ids']);

  if (!authData) {
    throw new Error(
      "Cannot use GroupPaymentWebsocketWrapper without ['ids'] populated in the react query cache"
    );
  }

  useEffect(() => {
    if (!socketClient.isConnected()) {
      socketClient.connect(authData, queryClient);
    }

    return () => {
      socketClient.disconnect();
    };
  }, []);

  return <>{children}</>;
};

export default GroupPaymentWebsocketWrapper;
