import i18n, {ReadCallback} from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';
import config from './config';

i18n
  // detect user language
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next to load additional plugins such as language detection
  .use(initReactI18next)
  .use(
    resourcesToBackend(
      (language: string, namespace: string, cb: ReadCallback) =>
        import(`./locales/${language}/${namespace}.json`)
          .then(({default: resource}) => {
            cb(null, resource);
          })
          .catch((err) => {
            console.error(err);
            cb(err, null);
          })
    )
  )
  .init({
    debug: config.LOG_LEVEL === 'debug',
    fallbackLng: 'en',
    defaultNS: 'common',
    ns: ['common'],
    supportedLngs: ['en', 'es', 'fr', 'nl', 'de', 'it', 'pt'],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      useSuspense: true,
    },
  })
  .catch((err) => console.error(err));

// custom formatters
i18n.services.formatter?.add('uppercase', (value: string) =>
  value.toUpperCase()
);
i18n.services.formatter?.add('lowercase', (value: string) =>
  value.toLowerCase()
);
i18n.services.formatter?.add(
  'capitalize',
  (value: string) => `${value.at(0)?.toUpperCase()}${value.substring(1)}`
);

export default i18n;
