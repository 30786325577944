import {ErrorMessage} from '@hookform/error-message';
import Typography, {TypographyProps} from '@mui/material/Typography';
import React from 'react';
import type {
  FieldErrors,
  FieldName,
  FieldValues,
  FormState,
} from 'react-hook-form';
import type {FieldValuesFromFieldErrors} from '@hookform/error-message/dist/types';

interface FormFieldErrorProps<TFieldValues extends FieldValues>
  extends TypographyProps {
  name: FieldName<FieldValuesFromFieldErrors<FieldErrors<TFieldValues>>>;
  errors: FormState<TFieldValues>['errors'];
}

const FormFieldError = <TFieldValues extends FieldValues>({
  name,
  errors,
  ...props
}: FormFieldErrorProps<TFieldValues>) => (
  <ErrorMessage
    name={name}
    errors={errors}
    render={({message}) => (
      <Typography
        variant="caption"
        color="error"
        sx={{ml: 2, ...props.sx}}
        {...props}
      >
        {message}
      </Typography>
    )}
  />
);

export default FormFieldError;
