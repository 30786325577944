import {AxiosResponse} from 'axios';
import axios from '../../config/backend';
import {CreateRedirectProxyMutationParams} from '../../hooks/mutations/@types';

// Get a redirect proxy by proxy id
export const getRedirectUrlFromProxyId = async (
  proxyId: string
): Promise<string> => {
  const res: AxiosResponse<string> = await axios.get(
    `/proxies/redirect/${proxyId}`
  );
  return res.data;
};

// create a redirect proxy and return the proxy id
export const shortenUrlWithProxy = async ({
  url,
}: CreateRedirectProxyMutationParams): Promise<string> => {
  const res: AxiosResponse<string> = await axios.post('/proxies/redirect', {
    url,
  });
  return res.data;
};
