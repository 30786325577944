import React, {FC} from 'react';
import Divider from '@mui/material/Divider';
import Stack, {StackProps} from '@mui/material/Stack';
import type {LineItem} from '@local/backend/@types/updated-api-types/items/LineItem';
import LineItemSummary from './containers/LineItemSummary';

interface LineItemsListProps extends StackProps {
  lineItems: LineItem[];
}

const LineItemsList: FC<React.PropsWithChildren<LineItemsListProps>> = ({
  lineItems,
  sx,
  ...props
}) => (
  <Stack
    {...props}
    divider={<Divider flexItem />}
    spacing={1}
    sx={{overflowY: 'auto', overflowX: 'hidden', ...sx}}
  >
    {lineItems.map((lineItem: LineItem) => (
      <LineItemSummary lineItem={lineItem} key={lineItem.item.id} />
    ))}
  </Stack>
);

export default LineItemsList;
