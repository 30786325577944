import ListItem, {ListItemProps} from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import React, {FC, useState} from 'react';
import {useQueryClient} from '@tanstack/react-query';
import EditIcon from '@mui/icons-material/Edit';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {IconButton, Stack} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {MoneyUtils} from '@handsin/money';
import {useTranslation} from 'react-i18next';
import type {GroupPaymentRecord} from '@local/backend/@types/updated-api-types/group-payments/GroupPaymentRecord';
import {SplitType} from '@local/frontend/@types/updated-api-types/group-payments/SplitType';
import {useCustomModals} from '@local/frontend/libs/modals/useCustomModals';
import {ModalName} from '@local/frontend/libs/modals/ModalName';
import {useFeatureFlagEnabled} from 'posthog-js/react';
import {useNavigate} from 'react-router-dom';
import useCustomerId from '@local/frontend/hooks/useCustomerId';
import {usePayment} from '../../../../../hooks/data/payments';
import ParticipantAvatar from '../../../../atoms/avatars/ParticipantAvatar';
import CustomerPaymentStatusPill from '../../../../molecules/widgets/payments/CustomerPaymentStatusPill';
import {
  formatFullName,
  maskEmailString,
} from '../../../../../util/stringFormatters';
import useCustomerShareAmount from '../../../../../hooks/useCustomerShareAmount';
import {useUpdateGroupPayment} from '../../../../../hooks/mutations/groups-payments';
import useCustomer from '../../../../../hooks/data/customers';
import {
  useCreateCustomer,
  useUpdateCustomer,
} from '../../../../../hooks/mutations/customers';
import {useGroupPaymentCustomerShares} from '../../../../../hooks/queries/group-payments';
import ParticipantActionsMenu from '../ParticipantActionsMenu';
import EditName from './EditName';
import {AddCustomerFormValues} from './@types/AddCustomerFormValues';

interface ParticipantContainerProps extends ListItemProps {
  participantId: string | undefined;
  groupPayment: GroupPaymentRecord;
}

const ParticipantItem: FC<
  React.PropsWithChildren<ParticipantContainerProps>
> = ({participantId, groupPayment, ...props}) => {
  const {t} = useTranslation(['dashboard']);
  const queryClient = useQueryClient();
  const {openModal} = useCustomModals();
  const [customerOptionsMenuAnchorEl, setCustomerOptionsMenuAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const enableSms = useFeatureFlagEnabled('enable-sms');
  const enableEmail = useFeatureFlagEnabled('enable-email');

  const navigate = useNavigate();

  const createCustomerMutation = useCreateCustomer();
  const updateCustomerMutation = useUpdateCustomer();

  const participant = useCustomer(participantId);

  const currentCustomerId = useCustomerId();

  const participantPayment = usePayment(
    participantId ? groupPayment.memberPayments[participantId] : undefined
  );

  const [isEditingName, setIsEditingName] = useState(false);
  const {data: groupPaymentCustomerSharesMap} = useGroupPaymentCustomerShares(
    groupPayment.id
  );

  const customerShareAmount = useCustomerShareAmount(
    participantId,
    groupPaymentCustomerSharesMap
  );

  const handleOpenAddMember = () => {
    openModal(ModalName.ADD_GROUP_MEMBER, {});
  };

  const handleEditName = () => setIsEditingName(true);

  const handleDiscard = () => setIsEditingName(false);

  const updateGroupPaymentMutation = useUpdateGroupPayment();

  // updates a customer name
  const handleUpdateCustomerName = async (
    customerFormValues: AddCustomerFormValues,
    customerId: string | undefined
  ) => {
    try {
      // we should always have a customerId if updating an existing added customer
      if (!customerId) {
        return;
      }
      await updateCustomerMutation.mutateAsync(
        {
          customerId,
          customerUpdateParams: {
            firstName: customerFormValues.firstName,
            lastName: customerFormValues.lastName,
          },
        },
        {
          onSuccess: async (customerRecord) => {
            await queryClient.invalidateQueries([
              'customer',
              customerRecord.id,
            ]);
          },
          onSettled: () => {
            setIsEditingName(false);
          },
        }
      );
    } catch (err) {
      throw new Error('Sorry, could not update this customers name');
    }
  };

  const isCurrentCustomerTheOwner = currentCustomerId === groupPayment.ownerId;
  const isCurrentCustomerTheParticipant = participantId === currentCustomerId;
  const isParticipantTheOwner = groupPayment.ownerId === participantId;
  const hasParticipantJoined =
    participantId && groupPayment.memberIds.includes(participantId);
  const editable =
    isCurrentCustomerTheParticipant ||
    (isCurrentCustomerTheOwner && !hasParticipantJoined);

  if (isEditingName && participant) {
    return (
      <ListItem {...props}>
        <ListItemText>
          <EditName
            handleDiscard={handleDiscard}
            handleApply={(formvalues: AddCustomerFormValues) =>
              handleUpdateCustomerName(formvalues, participant.id)
            }
            firstName={participant?.firstName}
            lastName={participant?.lastName}
            isLoading={
              updateCustomerMutation.isLoading ||
              createCustomerMutation.isLoading ||
              updateGroupPaymentMutation.isLoading
            }
          />
        </ListItemText>
      </ListItem>
    );
  }

  const shouldRenderPlaceholderText =
    groupPayment.splitType !== 'BY_ITEM' ||
    (groupPayment.splitType === 'BY_ITEM' && isCurrentCustomerTheOwner);

  if (!participant && shouldRenderPlaceholderText) {
    return (
      <ListItem {...props}>
        <ListItemAvatar>
          <ParticipantAvatar />
        </ListItemAvatar>
        <ListItemText>
          <Typography
            onClick={() =>
              isCurrentCustomerTheOwner ? handleOpenAddMember() : undefined
            }
            variant="subtitle1"
            sx={{
              color: 'primary.main',
              cursor: isCurrentCustomerTheOwner ? 'pointer' : undefined,
              textDecoration: isCurrentCustomerTheOwner
                ? 'underline'
                : undefined,
            }}
          >
            {isCurrentCustomerTheOwner
              ? t('body.components.participantItem.title.isOwner', {
                  ns: 'dashboard',
                })
              : t('body.components.participantItem.title.isNotOwner', {
                  ns: 'dashboard',
                })}
          </Typography>
        </ListItemText>
      </ListItem>
    );
  }

  if (!participant) {
    return null;
  }

  const handleOpenCustomerMenuOptions = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setCustomerOptionsMenuAnchorEl(event.currentTarget);
  };

  const isOwner =
    !!currentCustomerId && groupPayment.ownerId === currentCustomerId;

  // check if the current customer is viewing their own participant item options
  const isParticipantTheCurrentCustomer = participant.id === currentCustomerId;

  const participantHasShare =
    !!customerShareAmount?.amount && customerShareAmount.amount > 0;

  const hasNoValidPayment =
    !participantPayment ||
    ['CANCELLED', 'FAILED'].includes(participantPayment.status);

  // can only pay on behalf if the participant is not the current viewing customer and the participant has no payment or a cancelled/failed payment
  const canPayOnBehalf =
    !isParticipantTheCurrentCustomer &&
    hasNoValidPayment &&
    participantHasShare;

  const canKick =
    isOwner && !isParticipantTheCurrentCustomer && hasNoValidPayment;

  const canModifyBasket =
    isOwner &&
    groupPayment.splitType === SplitType.BY_ITEM &&
    hasNoValidPayment;

  // can only cancel a participants payment if there is a pending or aprroved payment and the customer viewing is the one that made the payment
  const canCancelPayment =
    !!participantPayment &&
    ['PENDING', 'APPROVED'].includes(participantPayment.status) &&
    !!currentCustomerId &&
    currentCustomerId === participantPayment.customerId;

  // can only remind when you can pay on behalf and when the participant has provided atleast an email
  const canRemind =
    canPayOnBehalf &&
    ((!!participant.email && !!enableEmail) ||
      (!!participant.phoneNumber && !!enableSms));

  const enableParticipantActions =
    canCancelPayment ||
    canPayOnBehalf ||
    canKick ||
    canRemind ||
    canModifyBasket;

  return (
    <Box>
      <ListItem {...props}>
        <ListItemAvatar>
          <ParticipantAvatar
            isOwner={isParticipantTheOwner}
            firstName={participant.firstName}
            lastName={participant.lastName}
            isCurrentCustomer={participant.id === currentCustomerId}
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Stack>
              <Typography variant="subtitle1" sx={{fontWeight: 400}}>
                {formatFullName(participant.firstName, participant.lastName)}
                {editable && (
                  <EditIcon
                    onClick={handleEditName}
                    sx={{
                      ml: 1,
                      fontSize: '16px',
                      color: 'grey.600',
                      cursor: 'pointer',
                      '&:hover': {
                        color: 'primary.dark',
                      },
                    }}
                  />
                )}
              </Typography>
              {!!participant.email && (
                <Typography
                  variant="caption"
                  color="primary"
                  sx={{fontSize: 12}}
                >
                  {maskEmailString(participant.email)}
                </Typography>
              )}
            </Stack>
          }
          secondary={
            customerShareAmount
              ? MoneyUtils.formatMoney(customerShareAmount)
              : undefined
          }
          secondaryTypographyProps={{sx: {color: 'primary.dark'}}}
        />

        <Stack spacing={1} sx={{display: 'flex', alignItems: 'flex-end'}}>
          <CustomerPaymentStatusPill
            customerId={participantId}
            groupPayment={groupPayment}
          />
          {enableParticipantActions && (
            <>
              <IconButton onClick={(e) => handleOpenCustomerMenuOptions(e)}>
                <MoreHorizIcon />
              </IconButton>
              <ParticipantActionsMenu
                anchorEl={customerOptionsMenuAnchorEl}
                setAnchorEl={setCustomerOptionsMenuAnchorEl}
                groupPayment={groupPayment}
                participant={participant}
                canPayOnBehalf={canPayOnBehalf && !!currentCustomerId}
                onPayOnBehalf={() => {
                  if (currentCustomerId) {
                    navigate(
                      `/g/${groupPayment.id}/pay-on-behalf?mid=${groupPayment.merchantId}&cid=${currentCustomerId}&on_behalf=${participant.id}`
                    );
                  }
                }}
                canKick={canKick}
                canCancelPayment={canCancelPayment}
                canRemind={canRemind}
                canModifyBasket={canModifyBasket}
              />
            </>
          )}
        </Stack>
      </ListItem>
    </Box>
  );
};

export default ParticipantItem;
