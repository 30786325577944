import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, {FC, useEffect, useState} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Slide from '@mui/material/Slide';
import {TransitionProps} from '@mui/material/transitions';
import {Controller, useFieldArray, useForm, useWatch} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  TextField,
  useTheme,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useTranslation} from 'react-i18next';
import type {GroupPaymentRecord} from '@local/backend/@types/updated-api-types/group-payments/GroupPaymentRecord';
import {useKickFromGroup} from '@local/frontend/hooks/mutations/groups-payments';
import useSbiContext from '@local/frontend/hooks/useSbiContext';
import type {Customer} from '@local/backend/@types/updated-api-types/customers/Customer';
import {useCustomModals} from '@local/frontend/libs/modals/useCustomModals';
import {ModalName} from '@local/frontend/libs/modals/ModalName';
import * as Yup from 'yup';
import {
  useInviteToGroupPayment,
  useRemoveFromGroupPayment,
} from '@local/frontend/libs/trpc/trpc';
import useCustomerId from '@local/frontend/hooks/useCustomerId';
import {useNotification} from '@local/frontend/hooks/useNotification';
import {useCreateCustomer} from '../../../../hooks/mutations/customers';
import getJoinByItemGroupSchema from './joinByItemGroupSchema';
import ModalHeader from '../ModalHeader';

const Transition = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>
    // eslint-disable-next-line react/jsx-props-no-spreading
  ) => <Slide direction="up" ref={ref} {...props} />
);
Transition.displayName = 'Transition';

enum JoinByItemAccordionPanels {
  ADDED_MEMBERS_PANEL = 'ADDED_MEMBERS_PANEL',
  EXISTING_MEMBERS_PANEL = 'EXISTING_MEMBERS_PANEL',
}

interface JoinByItemModalProps {
  groupPayment: GroupPaymentRecord;
  customers: Customer[];
}

const JoinByItemModal: FC<React.PropsWithChildren<JoinByItemModalProps>> = ({
  groupPayment,
  customers,
}) => {
  const {t} = useTranslation(['modals', 'glossary']);
  const sbiContext = useSbiContext();
  const theme = useTheme();
  const {open: openNotification} = useNotification();
  const createCustomerMutation = useCreateCustomer();
  const kickFromGroupPaymentMutation = useKickFromGroup();
  const inviteToGroupPaymentMutation = useInviteToGroupPayment();
  const removeFromGroupPayment = useRemoveFromGroupPayment();
  const {closeModal} = useCustomModals();
  const currentCustomerId = useCustomerId();

  const joinByItemGroupSchema = getJoinByItemGroupSchema(t);

  const {
    formState: {isDirty, isValid, isSubmitting, dirtyFields},
    ...formMethods
  } = useForm<Yup.InferType<typeof joinByItemGroupSchema>>({
    mode: 'all',
    resolver: yupResolver(joinByItemGroupSchema),
    defaultValues: {
      existingGroupCustomers: customers,
      newAddedCustomers: [],
      addedCustomerName: '',
    },
  });

  const existingGroupCustomersFieldArray = useFieldArray({
    keyName: 'fieldId',
    control: formMethods.control,
    name: 'existingGroupCustomers',
  });

  const newAddedCustomersFieldArray = useFieldArray({
    keyName: 'fieldId',
    control: formMethods.control,
    name: 'newAddedCustomers',
  });

  const allNewAddedCustomers = useWatch({
    control: formMethods.control,
    name: 'newAddedCustomers',
  });

  const addedCustomerName = useWatch({
    control: formMethods.control,
    name: 'addedCustomerName',
  });

  const handleSubmitForm = async ({
    newAddedCustomers,
    existingGroupCustomers,
  }: Yup.InferType<typeof joinByItemGroupSchema>) => {
    if (!currentCustomerId) {
      openNotification({
        message: t('joinByItemModal.errors.noOwner', {ns: 'modals'}),
        severity: 'error',
      });
      throw new Error('No customerId present');
    }

    let updatedGpr: GroupPaymentRecord = groupPayment;
    // create in parallel the new customers provided by user input to join split by item group
    const newCustomers = await Promise.all(
      newAddedCustomers.map(async (userToAdd) => {
        const customer = await createCustomerMutation.mutateAsync({
          firstName: userToAdd.name,
        });

        return customer;
      })
    );

    // add new customers to the group if there were any
    if (newCustomers.length > 0) {
      const newCustomerIdsToAdd = newCustomers.map((customer) => customer.id);

      updatedGpr = await inviteToGroupPaymentMutation.mutateAsync({
        groupPaymentId: groupPayment.id,
        merchantId: groupPayment.merchantId,
        customerIds: newCustomerIdsToAdd,
        ownerId: currentCustomerId,
      });
    }

    // get all customer ids that were still left from the existing group members
    const customerIdsToKeep = new Set(
      existingGroupCustomers.map((customer) => customer.id)
    );

    // get the customer ids to be removed from the group
    const allCustomerIdsToRemoveSet = new Set(
      updatedGpr.memberIds
        .concat(updatedGpr.invited ?? [])
        .filter((groupMemberId) => !customerIdsToKeep.has(groupMemberId))
    );

    if (updatedGpr.invited) {
      // get the customers to be removed
      const allGroupCustomersToRemove = customers.filter((groupMember) =>
        allCustomerIdsToRemoveSet.has(groupMember.id)
      );

      // get all the customer ids that are only ADDED to the group and need to be removed
      const customerIdsAddedToGroupToRemoveSet = new Set(
        allGroupCustomersToRemove
          .filter((customerToRemove) =>
            updatedGpr.invited?.includes(customerToRemove.id)
          )
          .map((customerToRemove) => customerToRemove.id)
      );

      if (customerIdsAddedToGroupToRemoveSet.size > 0) {
        const removedCustomerIds = updatedGpr.invited.filter(
          (existingAddedMemberId) =>
            !customerIdsAddedToGroupToRemoveSet.has(existingAddedMemberId)
        );

        // remove added customers from the group
        await Promise.all(
          removedCustomerIds.map(async (removedCustomerId) => {
            updatedGpr = await removeFromGroupPayment.mutateAsync({
              groupPaymentId: updatedGpr.id,
              merchantId: updatedGpr.merchantId,
              customerId: removedCustomerId,
            });
          })
        );

        // ensure we update the sbi context with the latest item allocation
        if (updatedGpr.itemAllocation) {
          sbiContext.setItemAlloc(updatedGpr.itemAllocation);
        }
      }
    }

    // get the customers who have joined the group and need to be kicked from the group
    const customersJoinedInGroupToKick = customers.filter(
      (customerToRemove) =>
        allCustomerIdsToRemoveSet.has(customerToRemove.id) &&
        updatedGpr.memberIds.includes(customerToRemove.id)
    );

    if (customersJoinedInGroupToKick.length > 0) {
      await Promise.all(
        customersJoinedInGroupToKick.map(async (customerToKick) => {
          updatedGpr = await kickFromGroupPaymentMutation.mutateAsync({
            groupPayment: updatedGpr,
            ownerId: updatedGpr.ownerId,
            customerToKick,
          });

          // ensure we update the context with the latest item allocation
          if (updatedGpr.itemAllocation) {
            sbiContext.setItemAlloc(updatedGpr.itemAllocation);
          }
        })
      );
    }

    closeModal(ModalName.JOIN_BY_ITEM_GROUP);
  };

  const [expanded, setExpanded] = useState<JoinByItemAccordionPanels | false>(
    JoinByItemAccordionPanels.EXISTING_MEMBERS_PANEL
  );

  const handleAccordionPanelChange =
    (panel: JoinByItemAccordionPanels) =>
    (_event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  useEffect(() => {
    // automatically close added user accordion on removal of last added user
    if (
      allNewAddedCustomers.length < 1 &&
      expanded === JoinByItemAccordionPanels.ADDED_MEMBERS_PANEL
    ) {
      setExpanded(false);
    }
  }, [allNewAddedCustomers]);

  const handleAddCustomer = () => {
    if (!addedCustomerName) {
      return;
    }

    newAddedCustomersFieldArray.append({
      name: addedCustomerName,
    });

    formMethods.resetField('addedCustomerName');

    setExpanded(JoinByItemAccordionPanels.ADDED_MEMBERS_PANEL);
  };

  const isAnyChanges =
    formMethods.getValues().existingGroupCustomers.length !==
      customers.length || allNewAddedCustomers.length >= 1;

  const getSubmitJoinByItemButtonText = () => {
    if (
      formMethods.getValues().existingGroupCustomers.length !== customers.length
    ) {
      if (allNewAddedCustomers.length > 0) {
        return t('joinByItemModal.form.button.options.addAndUpdate', {
          ns: 'modals',
          count: allNewAddedCustomers.length,
        });
      }

      return t('joinByItemModal.form.button.options.update', {ns: 'modals'});
    }

    if (allNewAddedCustomers.length > 0) {
      return t('joinByItemModal.form.button.options.addNewUser', {
        ns: 'modals',
        count: allNewAddedCustomers.length,
      });
    }

    return t('joinByItemModal.form.button.options.default', {ns: 'modals'});
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <ModalHeader
          title={t('joinByItemModal.title', {ns: 'modals'})}
          modalName={ModalName.JOIN_BY_ITEM_GROUP}
        />
      </Grid>
      <Grid item xs={12}>
        <form onSubmit={formMethods.handleSubmit(handleSubmitForm)}>
          <Grid container spacing={2}>
            {/* render existing customers already in the split by item group */}
            <Grid item xs={12}>
              <Accordion
                expanded={
                  expanded === JoinByItemAccordionPanels.EXISTING_MEMBERS_PANEL
                }
                onChange={handleAccordionPanelChange(
                  JoinByItemAccordionPanels.EXISTING_MEMBERS_PANEL
                )}
                sx={{width: '100%'}}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography sx={{fontSize: 14, fontWeight: 500}}>
                    {t('joinByItemModal.body.currentGroupMembers', {
                      ns: 'modals',
                    })}{' '}
                    ({existingGroupCustomersFieldArray.fields.length})
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{maxHeight: '180px', overflowY: 'auto'}}>
                  <Grid container spacing={1}>
                    {existingGroupCustomersFieldArray.fields.map(
                      (existingCustomer, index) => (
                        <Grid item xs={12} key={`${existingCustomer.fieldId}`}>
                          <Controller
                            name={`existingGroupCustomers.${index}.firstName`}
                            control={formMethods.control}
                            render={({field}) => (
                              <TextField
                                {...field}
                                fullWidth
                                InputProps={
                                  groupPayment.ownerId !== existingCustomer.id
                                    ? {
                                        // not allowed to remove owner from a group
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <IconButton
                                              onClick={() => {
                                                existingGroupCustomersFieldArray.remove(
                                                  index
                                                );
                                              }}
                                            >
                                              <CloseIcon
                                                sx={{fontSize: '18px'}}
                                              />
                                            </IconButton>
                                          </InputAdornment>
                                        ),
                                      }
                                    : {}
                                }
                                variant="outlined"
                                label={
                                  existingCustomer.id === groupPayment.ownerId
                                    ? t('owner', {ns: 'glossary'})
                                    : t('groupMember', {
                                        ns: 'glossary',
                                      })
                                }
                                disabled
                              />
                            )}
                          />
                        </Grid>
                      )
                    )}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>

            {/* Will display all added new members in a SBI group */}
            <Grid item xs={12}>
              <Accordion
                expanded={
                  expanded === JoinByItemAccordionPanels.ADDED_MEMBERS_PANEL
                }
                onChange={handleAccordionPanelChange(
                  JoinByItemAccordionPanels.ADDED_MEMBERS_PANEL
                )}
                sx={{width: '100%'}}
                disabled={allNewAddedCustomers.length < 1}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography sx={{fontSize: 14, fontWeight: 500}}>
                    {t('joinByItemModal.body.addedMembers', {ns: 'modals'})} (
                    {allNewAddedCustomers.length})
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{maxHeight: '180px', overflowY: 'auto'}}>
                  <Grid container spacing={1}>
                    {newAddedCustomersFieldArray.fields.map(
                      (groupUser, index) => (
                        <Grid item xs={12} key={`${groupUser.fieldId}`}>
                          <Controller
                            name={`newAddedCustomers.${index}.name`}
                            control={formMethods.control}
                            render={({field}) => (
                              <TextField
                                {...field}
                                fullWidth
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        onClick={() => {
                                          newAddedCustomersFieldArray.remove(
                                            index
                                          );
                                        }}
                                      >
                                        <CloseIcon sx={{fontSize: '18px'}} />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                                variant="outlined"
                                label={t('groupMember', {
                                  ns: 'glossary',
                                })}
                                disabled
                              />
                            )}
                          />
                        </Grid>
                      )
                    )}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            {/* Add new member to a SBI group */}
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontSize: 16,
                      fontWeight: 500,
                      color: theme.palette.primary.main,
                    }}
                    gutterBottom
                  >
                    {t('joinByItemModal.body.addNewMember', {ns: 'modals'})}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="addedCustomerName"
                    control={formMethods.control}
                    render={({field}) => (
                      <TextField
                        {...field}
                        fullWidth
                        placeholder={t(
                          'joinByItemModal.form.name.placeholder',
                          {ns: 'modals'}
                        )}
                        variant="outlined"
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault();
                            // only add customers on enter key press, if field has been changed
                            if (dirtyFields.addedCustomerName) {
                              handleAddCustomer();
                            }
                          }
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleAddCustomer}
                                disabled={
                                  isSubmitting || !dirtyFields.addedCustomerName
                                }
                              >
                                <AddIcon fontSize="small" />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* buttons */}
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs>
                  <Button
                    variant="outlined"
                    fullWidth
                    onClick={() => {
                      closeModal(ModalName.JOIN_BY_ITEM_GROUP);
                    }}
                  >
                    {t('close', {ns: 'glossary'})}
                  </Button>
                </Grid>
                <Grid item xs>
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    disabled={
                      isSubmitting || !isAnyChanges || !isValid || !isDirty
                    }
                  >
                    {getSubmitJoinByItemButtonText()}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default JoinByItemModal;
