import {GroupPaymentStatus} from '@handsin/api-node';
import React, {FC, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import dayjs from '../config/dayjsConfig';
import {useInitialiseGroupPaymentPublicDetails} from '../hooks/queries/group-payments';
import {useDateTime} from '../hooks/useDateTime';
import {handleExternalRedirect} from '../util';
import useCustomerId from '../hooks/useCustomerId';
import useMerchantId from '../hooks/useMerchantId';

const GroupPaymentRedirectWrapper: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const navigate = useNavigate();
  const {groupPaymentId} = useParams();
  const customerId = useCustomerId();
  const merchantId = useMerchantId();
  const {currentDateTime} = useDateTime();
  const {data: groupPayment, isInitialLoading: isGroupPaymentLoading} =
    useInitialiseGroupPaymentPublicDetails(groupPaymentId, merchantId, {
      refetchOnMount: 'always',
    });

  const isTimeExpired = groupPayment?.expirationDate
    ? dayjs(groupPayment.expirationDate).diff(
        currentDateTime,
        'seconds',
        true
      ) <= 0
    : false;

  useEffect(() => {
    if (!groupPayment) {
      return;
    }

    const queryOptions = {gid: groupPayment.id};
    // check whether to redirect them to a url specified in the pageOptions
    if (
      groupPayment.pageOptions?.approvedUrl &&
      groupPayment.status === 'APPROVED'
    ) {
      handleExternalRedirect(
        groupPayment.pageOptions.approvedUrl,
        queryOptions
      );
    } else if (
      groupPayment.pageOptions?.successUrl &&
      groupPayment.status === 'COMPLETED'
    ) {
      handleExternalRedirect(groupPayment.pageOptions.successUrl, queryOptions);
    } else if (
      groupPayment.pageOptions?.cancelUrl &&
      groupPayment.status === 'CANCELLED'
    ) {
      handleExternalRedirect(groupPayment.pageOptions.cancelUrl, queryOptions);
    }
    // if the status is already expired, then take straight to merchant expireurl
    else if (
      groupPayment.pageOptions?.expireUrl &&
      isTimeExpired &&
      groupPayment.status === 'EXPIRED'
    ) {
      handleExternalRedirect(groupPayment.pageOptions.expireUrl, queryOptions);
    }
    // pending expired group payment
    else if (
      groupPayment.pageOptions?.expireUrl &&
      isTimeExpired &&
      groupPayment.status === 'PENDING'
    ) {
      // Here we just need to navigate to a route in our app. this is because the entity is still pending. expired happens on a job much later and not at the exact time of the entitys expiration date
      const navigateLink = customerId
        ? `/g/${groupPayment.id}/expired?mid=${groupPayment.merchantId}&cid=${customerId}`
        : `/g/${groupPayment.id}/expired?mid=${groupPayment.merchantId}`;

      navigate(navigateLink, {replace: true});
    }
    // handle terminal cases when there is no URLs provided by the merchant
    else if (
      [
        GroupPaymentStatus.CANCELLED,
        GroupPaymentStatus.EXPIRED,
        GroupPaymentStatus.APPROVED,
        GroupPaymentStatus.COMPLETED,
      ].includes(groupPayment.status) ||
      isTimeExpired
    ) {
      // if no pageOption urls were provided, redirect to group terminal page if necessary
      const navigateLink = customerId
        ? `/g/${groupPayment.id}/t?mid=${groupPayment.merchantId}&cid=${customerId}`
        : `/g/${groupPayment.id}/t?mid=${groupPayment.merchantId}`;

      navigate(navigateLink, {replace: true});
    }
  }, [groupPayment, isTimeExpired]);

  if (isGroupPaymentLoading) {
    return null;
  }
  return <>{children}</>;
};

export default GroupPaymentRedirectWrapper;
