import {AxiosError} from 'axios';
import {UseQueryResult, useQuery} from '@tanstack/react-query';
import {useNavigate} from 'react-router-dom';
import type {GroupPaymentRecord} from '@local/backend/@types/updated-api-types/group-payments/GroupPaymentRecord';
import type {DefaultResponseError} from '@local/backend/@types/updated-api-types/DefaultResponseError';
import {GroupPaymentCustomerShareMap} from '../../../@types/updated-api-types/group-payments/GroupPaymentCustomerShareMap';
import {
  getGroupPayment,
  getGroupPaymentPublicDetails,
  getGroupPaymentCustomerShares,
} from '../../../libs/api/group-payments.actions';
import {QUERY_REFETCH_INTERVAL} from '../../../constants';
import {QueryOptions} from '../@types';

export const useInitialiseGroupPayment = (
  groupPaymentId: string | undefined,
  merchantId: string | undefined,
  customerId: string | undefined,
  queryOptions?: QueryOptions<
    GroupPaymentRecord | undefined,
    AxiosError<DefaultResponseError>
  >
): UseQueryResult<
  GroupPaymentRecord | undefined,
  AxiosError<DefaultResponseError>
> => {
  const navigate = useNavigate();
  return useQuery<
    GroupPaymentRecord | undefined,
    AxiosError<DefaultResponseError>
  >(
    ['groupPayment', groupPaymentId],
    () => (groupPaymentId ? getGroupPayment(groupPaymentId) : undefined),
    {
      enabled: !!groupPaymentId && !!merchantId,
      refetchInterval: (data) =>
        data?.status === 'PENDING' || data?.status === 'APPROVED'
          ? QUERY_REFETCH_INTERVAL
          : false,
      onError: (err: AxiosError<DefaultResponseError>) => {
        if (
          groupPaymentId &&
          merchantId &&
          err.response &&
          err.response.data?.name === 'ACCESS_TOKEN_EXPIRED'
        ) {
          const navigateLink = customerId
            ? `/g/${groupPaymentId}/t?mid=${merchantId}&cid=${customerId}`
            : `/g/${groupPaymentId}/t?mid=${merchantId}`;

          navigate(navigateLink, {replace: true});
        }
      },
      ...queryOptions,
    }
  );
};

export const useInitialiseGroupPaymentPublicDetails = (
  groupPaymentId: string | undefined,
  merchantId: string | undefined,
  queryOptions?: QueryOptions<
    GroupPaymentRecord | undefined,
    AxiosError<DefaultResponseError>
  >
): UseQueryResult<
  GroupPaymentRecord | undefined,
  AxiosError<DefaultResponseError>
> =>
  useQuery<GroupPaymentRecord | undefined, AxiosError<DefaultResponseError>>(
    ['groupPayment-public', groupPaymentId],
    () =>
      groupPaymentId && merchantId
        ? getGroupPaymentPublicDetails(groupPaymentId, merchantId)
        : undefined,
    {
      enabled: !!groupPaymentId && !!merchantId,
      refetchInterval: (data) =>
        data?.status === 'PENDING' || data?.status === 'APPROVED'
          ? QUERY_REFETCH_INTERVAL
          : false,
      ...queryOptions,
    }
  );

// use useCustomerSharePrice instead of this, to get a customers share price.
export const useGroupPaymentCustomerShares = (
  groupPaymentId: string | undefined
): UseQueryResult<
  GroupPaymentCustomerShareMap | undefined,
  AxiosError<DefaultResponseError>
> =>
  useQuery<
    GroupPaymentCustomerShareMap | undefined,
    AxiosError<DefaultResponseError>
  >(
    ['group-payment-shares', groupPaymentId],
    () =>
      groupPaymentId
        ? getGroupPaymentCustomerShares(groupPaymentId)
        : undefined,
    {
      enabled: !!groupPaymentId,
    }
  );
