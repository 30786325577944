import type {DialogProps} from '@mui/material/Dialog';
import JoinGroupModal from '@local/frontend/components/molecules/modals/JoinGroupModal/JoinGroupModal';
import AddGroupMemberModal from '@local/frontend/components/molecules/modals/AddGroupMemberModal';
import ItemInfoModal from '@local/frontend/components/molecules/modals/ItemInfoModal';
import JoinByItemModal from '@local/frontend/components/molecules/modals/join-by-item/JoinByItemModal';
import DashboardSettingsModal from '@local/frontend/components/molecules/modals/dashboard-settings/DashboardSettingsModal';
import ViewAllParticipantsModal from '@local/frontend/components/molecules/modals/ViewAllParticipantsModal';
import ShareModal from '@local/frontend/components/molecules/modals/ShareModal';
import CancelPaymentModal from '@local/frontend/components/molecules/modals/CancelPaymentModal';
import PaymentApprovedModal from '@local/frontend/components/molecules/modals/PaymentApprovedModal/PaymentApprovedModal';
import FeedbackModal from '@local/frontend/components/molecules/modals/feedbackModal';
import SbiModifyBasketModal from '@local/frontend/components/molecules/modals/split-by-item-modals/modify-basket-modal/SbiModifyBasketModal';
import RequestGroupInviteLinkModal from '@local/frontend/components/molecules/modals/RequestGroupInviteLinkModal';
import DynamicPricingJoinerInfoModal from '@local/frontend/components/molecules/modals/dynamic-pricing/DynamicPricingJoinerInfoModal';
import DynamicPricingConfirmationModal from '@local/frontend/components/molecules/modals/dynamic-pricing/dynamic-pricing-owner-modal/DynamicPricingConfirmationModal';
import DynamicPricingOptOutModal from '@local/frontend/components/molecules/modals/dynamic-pricing/dynamic-pricing-owner-modal/DynamicPricingOptOutModal';
import LeaveGroupModal from '@local/frontend/components/molecules/dialogs/LeaveGroupModal';
import LineItemSummaryModal from '@local/frontend/components/molecules/modals/LineItemSummaryModal/LineItemSummaryModal';
import SbiHowItWorksModal from '@local/frontend/components/molecules/modals/split-by-item-modals/SbiHowItWorksModal';
import EditMemberDetailsModal from '@local/frontend/components/molecules/modals/EditMemberDetailsModal';
import {ModalName} from './ModalName';

export interface ModalDefaultProps extends DialogProps {
  canClose?: boolean;
  fullScreen?: boolean;
}

const modalConfig = {
  [ModalName.JOIN_GROUP]: {
    name: ModalName.JOIN_GROUP,
    defaultProps: {
      maxWidth: 'xs',
      fullWidth: true,
      canClose: false,
    } as ModalDefaultProps,
    Element: JoinGroupModal,
  },
  [ModalName.JOIN_BY_ITEM_GROUP]: {
    name: ModalName.JOIN_BY_ITEM_GROUP,
    defaultProps: {
      maxWidth: 'sm',
      fullWidth: true,
    } as ModalDefaultProps,
    Element: JoinByItemModal,
  },
  [ModalName.LINEITEM_SUMMARY]: {
    name: ModalName.LINEITEM_SUMMARY,
    defaultProps: {
      maxWidth: 'sm',
      fullWidth: true,
    } as ModalDefaultProps,
    Element: LineItemSummaryModal,
  },
  [ModalName.LINE_ITEM_INFO]: {
    name: ModalName.LINE_ITEM_INFO,
    defaultProps: {
      maxWidth: 'sm',
      fullWidth: true,
    } as ModalDefaultProps,
    Element: ItemInfoModal,
  },
  [ModalName.VIEW_ALL_PARTICIPANTS]: {
    name: ModalName.VIEW_ALL_PARTICIPANTS,
    defaultProps: {
      maxWidth: 'sm',
      fullWidth: true,
    } as ModalDefaultProps,
    Element: ViewAllParticipantsModal,
  },
  [ModalName.SHARE_INVITE]: {
    name: ModalName.SHARE_INVITE,
    defaultProps: {
      maxWidth: 'sm',
      fullWidth: true,
    } as ModalDefaultProps,
    Element: ShareModal,
  },
  [ModalName.PAYMENT_APPROVED]: {
    name: ModalName.PAYMENT_APPROVED,
    defaultProps: {
      maxWidth: 'sm',
      fullWidth: true,
    } as ModalDefaultProps,
    Element: PaymentApprovedModal,
  },
  [ModalName.DYNAMIC_PRICING_OWNER_INFORMATION]: {
    name: ModalName.DYNAMIC_PRICING_OWNER_INFORMATION,
    defaultProps: {
      maxWidth: 'sm',
      fullWidth: true,
      canClose: false,
    } as ModalDefaultProps,
    Element: DynamicPricingConfirmationModal,
  },
  [ModalName.DYNAMIC_PRICING_JOINER_INFORMATION]: {
    name: ModalName.DYNAMIC_PRICING_JOINER_INFORMATION,
    defaultProps: {
      maxWidth: 'md',
      fullWidth: true,
    } as ModalDefaultProps,
    Element: DynamicPricingJoinerInfoModal,
  },
  [ModalName.SBI_MODIFY_BASKET]: {
    name: ModalName.SBI_MODIFY_BASKET,
    defaultProps: {
      maxWidth: 'sm',
      fullWidth: true,
      canClose: false,
    } as ModalDefaultProps,
    Element: SbiModifyBasketModal,
  },
  [ModalName.FEEDBACK_MODAL]: {
    name: ModalName.FEEDBACK_MODAL,
    defaultProps: {
      maxWidth: 'sm',
      fullWidth: true,
      fullScreen: false,
    } as ModalDefaultProps,
    Element: FeedbackModal,
  },
  [ModalName.ADD_GROUP_MEMBER]: {
    name: ModalName.ADD_GROUP_MEMBER,
    defaultProps: {
      maxWidth: 'xs',
      fullWidth: true,
    } as ModalDefaultProps,
    Element: AddGroupMemberModal,
  },
  [ModalName.REQUEST_GROUP_INVITE_MODAL]: {
    name: ModalName.REQUEST_GROUP_INVITE_MODAL,
    defaultProps: {
      maxWidth: 'xs',
      fullWidth: true,
    } as ModalDefaultProps,
    Element: RequestGroupInviteLinkModal,
  },
  [ModalName.DYNAMIC_PRICING_OWNER_OPT_OUT]: {
    name: ModalName.DYNAMIC_PRICING_OWNER_OPT_OUT,
    defaultProps: {
      maxWidth: 'sm',
      fullWidth: true,
    } as ModalDefaultProps,
    Element: DynamicPricingOptOutModal,
  },
  [ModalName.DASHBOARD_SETTINGS]: {
    name: ModalName.DASHBOARD_SETTINGS,
    defaultProps: {
      maxWidth: 'sm',
      fullWidth: true,
    } as ModalDefaultProps,
    Element: DashboardSettingsModal,
  },
  [ModalName.LEAVE_GROUP]: {
    name: ModalName.LEAVE_GROUP,
    defaultProps: {
      maxWidth: 'sm',
      fullWidth: true,
    } as ModalDefaultProps,
    Element: LeaveGroupModal,
  },
  [ModalName.CANCEL_PAYMENT]: {
    name: ModalName.CANCEL_PAYMENT,
    defaultProps: {
      maxWidth: 'xs',
      fullWidth: true,
    } as ModalDefaultProps,
    Element: CancelPaymentModal,
  },
  [ModalName.HOW_IT_WORKS]: {
    name: ModalName.HOW_IT_WORKS,
    defaultProps: {
      maxWidth: 'sm',
      fullWidth: true,
    } as ModalDefaultProps,
    Element: SbiHowItWorksModal,
  },
  [ModalName.EDIT_MEMBER_DETAILS]: {
    name: ModalName.EDIT_MEMBER_DETAILS,
    defaultProps: {
      maxWidth: 'xs',
      fullWidth: true,
    } as ModalDefaultProps,
    Element: EditMemberDetailsModal,
  },
};

export type ModalConfigType = typeof modalConfig;

export default modalConfig;
