import React, {type FC, type PropsWithChildren} from 'react';
import {
  IntercomProvider,
  type IntercomProviderProps,
  useIntercom,
} from 'react-use-intercom';
import useIntercomLauncher from '../hooks/useIntercomLauncher';

const IntercomDisplayControlWrapper: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const {isLauncherOpen, setHasBooted, hasBooted} = useIntercomLauncher();
  const {update} = useIntercom();

  React.useEffect(() => {
    if (isLauncherOpen) {
      update({hideDefaultLauncher: false});
    } else if (hasBooted && !isLauncherOpen) {
      update({hideDefaultLauncher: true});
      setHasBooted(false);
    }
  }, [isLauncherOpen]);

  return <>{children}</>;
};

const CustomIntercomProvider: FC<
  React.PropsWithChildren<PropsWithChildren<IntercomProviderProps>>
> = ({children, ...intercomProps}) => {
  const {setIsLauncherOpen} = useIntercomLauncher();

  const handleOnHide = () => {
    const launcherTimeout = setTimeout(() => {
      setIsLauncherOpen(false);
    }, 300); // 0.3 second delay to allow intercom's built-in hide animation to finish first

    return () => clearTimeout(launcherTimeout);
  };

  return (
    <IntercomProvider onHide={handleOnHide} {...intercomProps}>
      <IntercomDisplayControlWrapper>{children}</IntercomDisplayControlWrapper>
    </IntercomProvider>
  );
};

export default CustomIntercomProvider;
