import type {
  AutocompleteProps,
  ChipTypeMap,
  PopoverVirtualElement,
  PopperPlacementType,
  PopperProps,
  Theme,
} from '@mui/material';
import {
  Autocomplete,
  ClickAwayListener,
  IconButton,
  InputAdornment,
  InputBase,
  Popper,
  Stack,
  autocompleteClasses,
  styled,
  useMediaQuery,
} from '@mui/material';
import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

const StyledPopper = styled(Popper)(({theme}) => ({
  border: `1px solid ${theme.palette.grey[50]}`,
  boxShadow: theme.shadows[6],
  borderRadius: 6,
  width: '100%',
  height: '100%',
  zIndex: 99999,
  fontSize: 13,
  color: theme.palette.grey[700],
  backgroundColor: theme.palette.grey[50],
}));

const StyledInput = styled(InputBase)(({theme}) => ({
  padding: 6,
  borderRadius: 4,
  backgroundColor: theme.palette.primary.lighter,
  '& input': {
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontSize: 16,
  },
}));

const StyledAutocompletePopper = styled('div')(({theme}) => ({
  width: '100% !important',
  height: '100%',
  maxHeight: '100%',
  overflowY: 'scroll',
  [`& .${autocompleteClasses.paper}`]: {
    maxHeight: '100%',
    boxShadow: 'none',
    color: 'inherit',
    fontSize: 13,
    borderRadius: 0,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    maxHeight: '100%',
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      marginTop: 4,
      borderRadius: 4,
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: 0,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.getContrastText(theme.palette.primary.dark),
      },
      '&[aria-selected="true"]': {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.getContrastText(theme.palette.primary.dark),
      },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative',
  },
}));

const StyledPopperWrapper = (props: PopperProps) => {
  const {disablePortal, anchorEl, open, ...other} = props;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return <StyledAutocompletePopper {...other} />;
};

const SearchableMenuContent = <
  Value,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
>({
  onClose,
  ...props
}: Omit<
  AutocompleteProps<Value, Multiple, DisableClearable, FreeSolo, ChipComponent>,
  'renderInput'
>) => (
  <Stack direction="column" sx={{p: 1}} spacing={1} maxHeight="100%">
    <Autocomplete
      {...props}
      open
      disablePortal
      PopperComponent={StyledPopperWrapper}
      renderInput={(params) => (
        <Stack
          spacing={1}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <StyledInput
            {...params}
            ref={params.InputProps.ref}
            autoFocus
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon fontSize="inherit" />
              </InputAdornment>
            }
          />
          {onClose && (
            <IconButton
              size="small"
              onClick={(e) => {
                onClose?.(e, 'escape');
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </Stack>
      )}
    />
  </Stack>
);

export interface SearchableMenuProps<
  Value,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
> {
  searcherProps: Omit<
    AutocompleteProps<
      Value,
      Multiple,
      DisableClearable,
      FreeSolo,
      ChipComponent
    >,
    'renderInput'
  >;
  onClose: () => void;
  open: boolean;
  anchorEl?: Element | PopoverVirtualElement | null;
  placement?: PopperPlacementType;
}

const SearchableMenu = <
  Value,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
>({
  searcherProps,
  onClose,
  anchorEl,
  placement,
  open,
}: SearchableMenuProps<
  Value,
  Multiple,
  DisableClearable,
  FreeSolo,
  ChipComponent
>) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  return (
    <StyledPopper
      anchorEl={isMobile ? undefined : anchorEl}
      sx={{
        top: 0,
        left: 0,
        width: {xs: '100%', sm: 320},
        maxHeight: {xs: '100vh', sm: '40vh'},
      }}
      placement={placement ?? 'bottom-end'}
      open={open}
    >
      <ClickAwayListener onClickAway={onClose} touchEvent={false}>
        <div style={{width: '100%', height: '100%'}}>
          <SearchableMenuContent
            onClose={isMobile ? onClose : undefined}
            {...searcherProps}
          />
        </div>
      </ClickAwayListener>
    </StyledPopper>
  );
};

export default SearchableMenu;
