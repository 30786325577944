import {useQueryClient} from '@tanstack/react-query';
import {Customer} from '../../@types/updated-api-types/customer/Customer';

const useCustomer = <T extends string | undefined>(
  customerId: T
): T extends string ? Customer : undefined => {
  const queryClient = useQueryClient();
  const customerRecord = queryClient.getQueryData<Customer>([
    'customer',
    customerId,
  ]);
  return customerRecord as T extends string ? Customer : undefined;
};

export default useCustomer;
