import {useContext} from 'react';
import {UpdateGroupPaymentMutationContext} from '../providers/UpdateGroupPaymentMutationProvider';
import {UpdateGroupPaymentMutationContextType} from '../providers/@types/UpdateGroupPaymentMutationContextType';

const useUpdateGroupPaymentMutationContext =
  (): UpdateGroupPaymentMutationContextType => {
    const updateGroupPaymentMutationContext = useContext<
      UpdateGroupPaymentMutationContextType | undefined
    >(UpdateGroupPaymentMutationContext);

    if (!updateGroupPaymentMutationContext) {
      throw new Error(
        'No update group mutation context. hook must be used within UpdateGroupPaymentMutationContext provider'
      );
    }

    return updateGroupPaymentMutationContext;
  };

export default useUpdateGroupPaymentMutationContext;
