import type {AxiosError} from 'axios';
import {UseQueryResult, useQuery} from '@tanstack/react-query';
import type {MerchantDTO} from '@local/frontend/@types/updated-api-types/merchant/MerchantDTO';
import type {DefaultResponseError} from '@local/backend/@types/updated-api-types/DefaultResponseError';
import {getMerchant} from '../../../libs/api/merchants.actions';

export const useInitializeMerchant = (): UseQueryResult<
  MerchantDTO,
  AxiosError<DefaultResponseError>
> =>
  useQuery<MerchantDTO, AxiosError<DefaultResponseError>>(['merchant'], () =>
    getMerchant()
  );
