import {AvatarProps} from '@mui/material';
import React, {FC} from 'react';
import type {LineItem} from '@local/backend/@types/updated-api-types/items/LineItem';
import useMerchant from '@local/frontend/hooks/data/merchants';
import ItemAvatar from '../atoms/avatars/ItemAvatar';

export interface LineItemImageProps extends AvatarProps {
  lineItem: LineItem;
}

const LineItemImage: FC<React.PropsWithChildren<LineItemImageProps>> = ({
  lineItem,
  ...props
}) => {
  const merchant = useMerchant();

  return (
    <ItemAvatar
      lineItem={lineItem}
      merchant={merchant}
      alt={lineItem.item.name}
      {...props}
    />
  );
};

export default LineItemImage;
