import {Customer} from '@local/backend/@types/updated-api-types/customers/Customer';
import {useInitializeCustomers} from './queries/customers';

interface UseGetCustomerQueriesReturn {
  areCustomerQueriesLoading: boolean;
  allGroupMembers: Customer[];
}

const useGetCustomers = (
  allGroupMemberIds: string[]
): UseGetCustomerQueriesReturn => {
  const customerQueries = useInitializeCustomers(allGroupMemberIds, {
    refetchOnWindowFocus: false,
  });

  const areCustomerQueriesLoading = customerQueries.some(
    (customerQuery) => customerQuery.isLoading
  );

  const allGroupMembers = customerQueries
    .map((customerQuery) => customerQuery.data)
    .filter((maybeCustomer): maybeCustomer is Customer => !!maybeCustomer);

  return {areCustomerQueriesLoading, allGroupMembers};
};

export default useGetCustomers;
