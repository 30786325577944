import React, {createContext, useMemo, useState} from 'react';
import type {ItemAllocations} from '@local/backend/@types/updated-api-types/group-payments/ItemAllocations';
import {SplitByItemContext} from './@types/SplitByItemContext';
import useCachedGroupPayment from '../hooks/useCachedGroupPayment';

export const SbiContext = createContext<SplitByItemContext | undefined>(
  undefined
);

interface SbiProviderProps {
  children: React.ReactNode;
}

const SbiProvider = ({children}: SbiProviderProps): React.JSX.Element => {
  const groupPayment = useCachedGroupPayment();
  const [itemAlloc, setItemAlloc] = useState<ItemAllocations>(
    groupPayment.itemAllocation ?? {}
  );

  const sbiContextValues = useMemo(
    () => ({itemAlloc, setItemAlloc, lineItems: groupPayment.lineItems ?? []}),
    [itemAlloc, setItemAlloc, groupPayment.lineItems]
  );

  return (
    <SbiContext.Provider value={sbiContextValues}>
      {children}
    </SbiContext.Provider>
  );
};

export default SbiProvider;
