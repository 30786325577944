import React, {Suspense} from 'react';
import {createRoot} from 'react-dom/client';
import {
  init,
  reactRouterV6BrowserTracingIntegration,
  replayIntegration,
} from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import {App} from './App';

import './i18n.config';
import config from './config';
import CustomPosthogProvider from './providers/CustomPosthogProvider';

init({
  dsn: config.SENTRY_CLIENT_DSN,
  environment: config.ENV,
  replaysSessionSampleRate: config.ENV !== 'development' ? 1 : 0, // don't record sessions
  replaysOnErrorSampleRate: config.ENV !== 'development' ? 1 : 0, // only record staging/sandbox/production sessions with errors
  integrations: [
    reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
      instrumentNavigation: true,
      instrumentPageLoad: true,
      traceFetch: true,
      traceXHR: true,
    }),
    replayIntegration({
      block: ['iframe'],
      networkDetailAllowUrls: ['*'],
      networkCaptureBodies: true,
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
});

const el = document.getElementById('root');
if (!el) {
  throw new Error('Could not find root element');
}

const root = createRoot(el);
root.render(
  <CustomPosthogProvider>
    <React.StrictMode>
      <Suspense fallback={null}>
        <App />
      </Suspense>
    </React.StrictMode>
  </CustomPosthogProvider>
);
