import {AxiosResponse} from 'axios';
import {Checkout} from '../../@types/updated-api-types/checkouts/Checkout';
import axios from '../../config/backend';

// Get a checkout by id
export const getCheckoutById = async (
  checkoutId: string
): Promise<Checkout> => {
  const res: AxiosResponse<Checkout> = await axios.get(
    `/checkouts/${checkoutId}`
  );
  return res.data;
};

export const getCheckoutPublicDetails = async (
  checkoutId: string,
  merchantId: string
): Promise<Checkout> => {
  const res: AxiosResponse<Checkout> = await axios.get(
    `/checkouts/${checkoutId}/public?merchantId=${merchantId}`
  );
  return res.data;
};

export const cancelCheckout = async (checkoutId: string): Promise<Checkout> => {
  const res: AxiosResponse<Checkout> = await axios.post(
    `/checkouts/${checkoutId}/cancel`
  );
  return res.data;
};
