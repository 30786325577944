import useIntercomLauncher from '@local/frontend/hooks/useIntercomLauncher';
import IconButton from '@mui/material/IconButton';
import Typography, {TypographyProps} from '@mui/material/Typography';
import React, {type FC} from 'react';
import {useTranslation} from 'react-i18next';
import useChatSupport from '@local/frontend/hooks/useChatSupport';
import {OnlineSupportIcon} from '@local/frontend/icons';
import CustomTooltip from '../atoms/CustomTooltip';

interface GetHelpProps {
  typographyProps?: TypographyProps;
  variant?: 'text' | 'icon';
}

const GetHelp: FC<React.PropsWithChildren<GetHelpProps>> = ({
  typographyProps,
  variant = 'text',
}) => {
  const {t} = useTranslation(['get-help']);
  const {isLauncherOpen} = useIntercomLauncher();

  const handleDisplayChatSupportMutation = useChatSupport();

  const handleOnClick = () => handleDisplayChatSupportMutation.mutate();

  if (variant === 'icon') {
    return (
      <CustomTooltip
        title={t('icon-tooltip', {ns: 'get-help'})}
        enabled={!isLauncherOpen}
        componentsProps={{
          arrow: {
            sx: {
              color: 'primary.dark',
            },
          },
          tooltip: {
            sx: {
              border: 'none',
              backgroundColor: 'primary.dark',
              color: 'primary.contrastText',
            },
          },
        }}
      >
        <IconButton onClick={handleOnClick} disabled={isLauncherOpen}>
          <OnlineSupportIcon width="28" height="28" />
        </IconButton>
      </CustomTooltip>
    );
  }

  return (
    <Typography variant="caption" onClick={handleOnClick} {...typographyProps}>
      {t('text', {ns: 'get-help'})}
    </Typography>
  );
};

export default GetHelp;
