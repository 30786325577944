import Button from '@mui/material/Button';
import React, {FC} from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Popper, {PopperProps} from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import _ from 'lodash';
import {Country, Language} from '@handsin/api-node';
import {useCustomer} from '@local/frontend/hooks/queries/customers';
import {useTranslation} from 'react-i18next';
import {useUpdateCustomer} from '@local/frontend/hooks/mutations/customers';
import type {Customer} from '@local/backend/@types/updated-api-types/customers/Customer';
import {Skeleton} from '@mui/material';
import useCustomerCountry from '@local/frontend/hooks/useCustomerCountry';
import useCountry from '@local/frontend/hooks/useCountry';
import useCustomerId from '@local/frontend/hooks/useCustomerId';
import CountrySelect from '../inputs/CountrySelect';
import CountryFlag from '../CountryFlag';
import LanguageSelect from '../inputs/LanguageSelect/LanguageSelect';
import {getSupportedLanguages} from '../inputs/LanguageSelect/getSupportedLanguages';
import {useChangeLanguage} from '../inputs/LanguageSelect/useChangeLanguage';

interface LocalePopperProps extends PopperProps {
  handleClose: () => void;
  customer: Customer | undefined;
  country: Country;
  language: Language;
}

const LocalePopper: FC<React.PropsWithChildren<LocalePopperProps>> = ({
  open,
  anchorEl,
  handleClose,
  customer,
  country,
  language,
  ...props
}) => {
  const {t} = useTranslation(['country-language-popper']);
  const {setCurrentCountry} = useCountry();
  const supportedLanguagesMap = getSupportedLanguages();

  const {changeLanguage} = useChangeLanguage();

  const localeMetaDataByLanguage =
    Object.values(supportedLanguagesMap).find(
      (supportedLang) => supportedLang.locale === language
    ) ?? Object.values(supportedLanguagesMap).at(0);

  const updateCustomerMutation = useUpdateCustomer();

  const handleCountrySelectChange = (countryCode: Country) => {
    if (!customer) {
      return;
    }

    updateCustomerMutation.mutate({
      customerId: customer.id,
      customerUpdateParams: {
        address: {
          ...customer.address,
          country: countryCode,
        },
      },
    });
  };

  const handleLanguageSelectChange = (newLanguage: Language) => {
    if (!customer) {
      return;
    }

    updateCustomerMutation.mutate({
      customerId: customer.id,
      customerUpdateParams: {
        language: newLanguage,
      },
    });
  };

  if (!localeMetaDataByLanguage) {
    throw new Error('No supported languages found');
  }

  return (
    <Popper
      transition
      open={open}
      anchorEl={anchorEl}
      {...props}
      sx={{
        zIndex: 1300,
        width: 300,
        ...props.sx,
      }}
    >
      {({TransitionProps}) => (
        <ClickAwayListener onClickAway={handleClose}>
          <Fade {...TransitionProps} timeout={350}>
            <Box sx={{borderRadius: 2, p: 2, bgcolor: 'white'}}>
              <Stack spacing={2}>
                <CountrySelect
                  value={country}
                  onChange={(countryCode) => {
                    handleCountrySelectChange(countryCode);
                    setCurrentCountry(countryCode);
                  }}
                  label={t('countrySelect.label', {
                    ns: 'country-language-popper',
                  })}
                />
                {!_.isEmpty(supportedLanguagesMap) && (
                  <LanguageSelect
                    label={t('languageSelect.label', {
                      ns: 'country-language-popper',
                    })}
                    value={language}
                    onChange={(newLanguage) => {
                      changeLanguage(newLanguage);
                      handleLanguageSelectChange(newLanguage);
                    }}
                  />
                )}
              </Stack>
            </Box>
          </Fade>
        </ClickAwayListener>
      )}
    </Popper>
  );
};

const LocaleSelectButton: FC<React.PropsWithChildren<unknown>> = () => {
  const {i18n} = useTranslation();
  const customerId = useCustomerId();

  const {data: customer, isInitialLoading: isCustomerLoading} =
    useCustomer(customerId);

  const [popperAnchorEl, setPopperAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const handleTogglePopper = (event: React.MouseEvent<HTMLElement>) => {
    setPopperAnchorEl(popperAnchorEl ? null : event.currentTarget);
  };

  const currentCountry = useCustomerCountry(customerId);

  const currentLanguage: Language | undefined =
    customer?.language ??
    (i18n.resolvedLanguage as Language | undefined) ??
    Language.EN;

  return (
    <>
      {isCustomerLoading ? (
        <Skeleton variant="rectangular" width={50} height={25} />
      ) : (
        <Button
          variant="text"
          onClick={handleTogglePopper}
          endIcon={<ArrowDropDownIcon />}
          sx={{fontFamily: 'inherit'}}
        >
          <Stack spacing={1} direction="row" alignItems="center">
            {currentCountry && <CountryFlag country={currentCountry} />}
            <Typography fontSize="inherit" noWrap sx={{fontFamily: 'inherit'}}>
              {currentLanguage}
            </Typography>
          </Stack>
        </Button>
      )}

      {!isCustomerLoading && (
        <LocalePopper
          open={Boolean(popperAnchorEl)}
          anchorEl={popperAnchorEl}
          placement="top-end"
          handleClose={() => setPopperAnchorEl(null)}
          customer={customer}
          country={currentCountry}
          language={currentLanguage}
        />
      )}
    </>
  );
};

export default LocaleSelectButton;
