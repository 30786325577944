import React, {FC} from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import {useTranslation} from 'react-i18next';
import type {GroupPaymentRecord} from '@local/backend/@types/updated-api-types/group-payments/GroupPaymentRecord';
import {useInitialisePayment} from '../../../../hooks/queries/payments';
import {useInitializeCustomer} from '../../../../hooks/queries/customers';
import {formatFullName} from '../../../../util/stringFormatters';
import {useCurrentCustomerId} from '../../../../hooks/data/ids';

interface GroupMemberAlreadyPaidMessageProps {
  groupPayment: GroupPaymentRecord;
}

const GroupMemberAlreadyPaidMessage: FC<
  React.PropsWithChildren<GroupMemberAlreadyPaidMessageProps>
> = ({groupPayment}) => {
  const {t} = useTranslation(['modals']);
  const currentCustomerId = useCurrentCustomerId();
  const {data: payment, isLoading: isPaymentLoading} = useInitialisePayment(
    currentCustomerId
      ? groupPayment.memberPayments[currentCustomerId]
      : undefined
  );

  const {
    data: customerWhoMadePayment,
    isInitialLoading: isCustomerWhoMadePaymentLoading,
  } = useInitializeCustomer(payment?.customerId);

  const {data: customer, isInitialLoading: isCustomerLoading} =
    useInitializeCustomer(currentCustomerId);

  if (
    isPaymentLoading ||
    isCustomerWhoMadePaymentLoading ||
    isCustomerLoading
  ) {
    return <Skeleton variant="text" sx={{fontSize: '4rem'}} />;
  }

  return (
    <Grid container>
      <Typography sx={{fontSize: 14, fontWeight: 500}}>
        {customerWhoMadePayment
          ? t(
              'joinGroupModal.components.groupMemberAlreadyPaidMessage.withCustomerWhoMadePaymentName',
              {
                ns: 'modals',
                name: formatFullName(
                  customerWhoMadePayment.firstName,
                  customerWhoMadePayment.lastName
                ),
              }
            )
          : t(
              'joinGroupModal.components.groupMemberAlreadyPaidMessage.withoutCustomerWhoMadePaymentName',
              {
                ns: 'modals',
              }
            )}
        {customer
          ? t(
              'joinGroupModal.components.groupMemberAlreadyPaidMessage.withoutCustomerName',
              {
                ns: 'modals',
                name: formatFullName(customer.firstName, customer.lastName),
              }
            )
          : t(
              'joinGroupModal.components.groupMemberAlreadyPaidMessage.withoutCustomerName',
              {
                ns: 'modals',
              }
            )}
      </Typography>
    </Grid>
  );
};

export default GroupMemberAlreadyPaidMessage;
