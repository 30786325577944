import React, {FC} from 'react';
import {
  Box,
  Divider,
  ImageList,
  ImageListItem,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import _ from 'lodash';
import type {LineItem} from '@local/backend/@types/updated-api-types/items/LineItem';
import {ModalName} from '@local/frontend/libs/modals/ModalName';
import type {MerchantDTO} from '@local/backend/@types/updated-api-types/merchants/MerchantDTO';
import {MoneyUtils} from '@handsin/money';
import ModalHeader from './ModalHeader';
import ItemAvatar from '../../atoms/avatars/ItemAvatar';

interface ItemInfoModalProps {
  lineItem: LineItem;
  merchant: MerchantDTO;
}

const ItemInfoModal: FC<React.PropsWithChildren<ItemInfoModalProps>> = ({
  merchant,
  lineItem,
}) => {
  const {t} = useTranslation(['modals']);
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Stack spacing={2} alignItems="center">
      <ModalHeader
        title={lineItem.item.name}
        modalName={ModalName.LINE_ITEM_INFO}
      />
      <ItemAvatar
        sx={{width: 70, height: 70}}
        lineItem={lineItem}
        merchant={merchant}
      />
      <Typography variant="subtitle1" color="grey.600">
        {t('itemInfoModal.quantityAndTotal', {
          ns: 'modals',
          quantity: lineItem.quantity,
          amount: MoneyUtils.formatMoney(lineItem.item.amountMoney),
        })}
      </Typography>
      {lineItem.item.imageUrls && lineItem.item.imageUrls.length > 1 && (
        <Box sx={{width: '100%', maxHeight: 400, overflowY: 'scroll'}}>
          <ImageList variant="masonry" cols={matchDownMd ? 2 : 3} gap={5}>
            {lineItem.item.imageUrls.map((imageUrl, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <ImageListItem key={`${imageUrl}-${idx}`}>
                <img src={imageUrl} alt={lineItem.item.name} loading="lazy" />
              </ImageListItem>
            ))}
          </ImageList>
        </Box>
      )}
      <Divider sx={{width: '100%'}}>
        <Typography
          variant="body1"
          sx={{color: 'primary.main', fontSize: '16px'}}
        >
          {t('itemInfoModal.description', {ns: 'modals'})}
        </Typography>
      </Divider>
      <Typography variant="body2" component="h4" align="center">
        {lineItem.item.description ??
          t('itemInfoModal.noDescription', {ns: 'modals'})}
      </Typography>
      {!_.isEmpty(lineItem.item.attributes) && (
        <>
          <Divider sx={{width: '100%'}}>
            <Typography
              variant="body1"
              sx={{color: 'primary.main', fontSize: '16px'}}
            >
              {t('itemInfoModal.moreInfo', {ns: 'modals'})}
            </Typography>
          </Divider>
          <Stack sx={{width: '100%'}} spacing={1}>
            {Object.entries(lineItem.item.attributes).map(
              ([attributeName, value], index) => (
                <Box
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${attributeName}-${index}`}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                  }}
                >
                  <Typography variant="subtitle2" gutterBottom sx={{pr: 2}}>
                    {attributeName.charAt(0).toUpperCase() +
                      attributeName.substring(1, attributeName.length)}
                    :
                  </Typography>
                  <Typography
                    align="right"
                    sx={{fontWeight: 500, fontSize: '14px', lineHeight: 1.75}}
                  >
                    {value}
                  </Typography>
                </Box>
              )
            )}
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default ItemInfoModal;
