import type {AllocatedItem} from '@local/backend/@types/updated-api-types/group-payments/ItemAllocations';
import Button from '@mui/material/Button';
import {styled} from '@mui/material/styles';
import React, {FC} from 'react';
import Typography from '@mui/material/Typography';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import useCachedGroupPayment from '@local/frontend/hooks/useCachedGroupPayment';
import {useSplitByItemHelper} from '@local/frontend/hooks/queries/frontend/SBI/useSplitByItemHelper';
import Stack from '@mui/material/Stack';

const StyledButton = styled(Button)(({theme}) => ({
  width: '28px',
  height: '28px',
  minWidth: '28px',
  minHeight: '28px',
  borderRadius: '4px',
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.primary.contrastText,
  padding: 0,
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
}));

interface SbiMergedFlowQuantityButtonsProps {
  remainingQuantityAvaliable: number;
  selectedCustomerId: string;
  currentAllocatedItem?: AllocatedItem;
  lineItemIdToAssign?: string;
  disableQuantityButtons?: boolean;
}

const SbiMergedFlowQuantityButtons: FC<
  React.PropsWithChildren<SbiMergedFlowQuantityButtonsProps>
> = ({
  remainingQuantityAvaliable,
  selectedCustomerId,
  currentAllocatedItem,
  lineItemIdToAssign,
  disableQuantityButtons,
}) => {
  const groupPayment = useCachedGroupPayment();
  const sbiHelper = useSplitByItemHelper(groupPayment);

  const handleReduceQuantity = (
    customerId: string,
    allocatedItem: AllocatedItem
  ) => {
    // if there only 1 quantity or less allocated, then set undefined to tell deallocate() to just remove the allocation
    sbiHelper.deallocate(
      customerId,
      allocatedItem.itemId,
      allocatedItem.quantity > 1 ? allocatedItem.quantity - 1 : undefined
    );
  };

  const handleIncreaseQuantity = (
    customerId: string,
    allocatedItem: AllocatedItem
  ) => {
    sbiHelper.allocate(
      customerId,
      allocatedItem.itemId,
      allocatedItem.quantity + 1
    );
  };

  const handleAllocateNewItem = (
    customerId: string,
    newLineItemIdToAssign: string | undefined
  ) => {
    if (!newLineItemIdToAssign) {
      return;
    }
    sbiHelper.allocate(customerId, newLineItemIdToAssign, 1);
  };

  const isIncreasingQuantityDisabled =
    disableQuantityButtons || remainingQuantityAvaliable < 1;
  const isDecreasingQuantityDisabled =
    disableQuantityButtons ||
    (remainingQuantityAvaliable <= 0 && !currentAllocatedItem) ||
    (remainingQuantityAvaliable > 0 &&
      (!currentAllocatedItem || (currentAllocatedItem?.quantity ?? 0) <= 0));

  return (
    <Stack direction="row" alignItems="center" spacing={1} minWidth={100}>
      <StyledButton
        variant="contained"
        color="secondary"
        disabled={isDecreasingQuantityDisabled}
        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          e.stopPropagation();
          if (!currentAllocatedItem) {
            return;
          }
          handleReduceQuantity(selectedCustomerId, currentAllocatedItem);
        }}
      >
        <RemoveIcon fontSize="small" sx={{color: 'inherit'}} />
      </StyledButton>
      <Typography
        variant="subtitle1"
        align="center"
        sx={{
          fontWeight: 600,
          width: '100%',
          color: disableQuantityButtons
            ? (theme) => theme.palette.grey[500]
            : 'inherit',
        }}
      >
        {`x${currentAllocatedItem?.quantity ?? 0}`}
      </Typography>

      <StyledButton
        variant="contained"
        color="secondary"
        disabled={isIncreasingQuantityDisabled} // disable quantity increase if no more stock available
        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          e.stopPropagation();
          if (currentAllocatedItem) {
            handleIncreaseQuantity(selectedCustomerId, currentAllocatedItem);
          } else {
            handleAllocateNewItem(selectedCustomerId, lineItemIdToAssign);
          }
        }}
      >
        <AddIcon fontSize="small" sx={{color: 'inherit'}} />
      </StyledButton>
    </Stack>
  );
};

export default SbiMergedFlowQuantityButtons;
