import {Money} from '@handsin/api-node';
import type {GroupPaymentRecord} from '@local/backend/@types/updated-api-types/group-payments/GroupPaymentRecord';
import type {AllocatedItem} from '@local/backend/@types/updated-api-types/group-payments/ItemAllocations';
import {LineItem} from '@local/frontend/@types/updated-api-types/items/LineItem';

export const convertAmountPerPerson = (
  nOfPeople: number,
  amount: Money
): Money => {
  const res = Math.ceil(amount.amount / nOfPeople);
  return {amount: res, currency: amount.currency};
};

// calculates a customers allocated share amount. probably better to just pass the amount from the api
export const calculateAllocatedAmount = (
  groupPaymentLineItems: LineItem[],
  itemAllocation: AllocatedItem[]
) => {
  const allocatedItemIds = itemAllocation.map(
    (allocatedItem) => allocatedItem.itemId
  );

  const firstLineItem = groupPaymentLineItems.at(0);

  if (!firstLineItem) {
    throw new Error(
      'Could not calculated allocated amount. No lineitems in group payment'
    );
  }

  if (!allocatedItemIds) {
    const noMoney = {
      amount: 0,
      currency: firstLineItem.totalMoney.currency,
    };
    return {initialAmount: noMoney, totalMoney: noMoney};
  }

  return groupPaymentLineItems
    .filter((lineItem) => allocatedItemIds.includes(lineItem.item.id))
    .map((allocatedLineItem) => {
      const item = itemAllocation.find(
        (allocatedItem) => allocatedItem.itemId === allocatedLineItem.item.id
      );
      if (!item) {
        const noMoney = {
          amount: 0,
          currency: allocatedLineItem.item.amountMoney.currency,
        };

        return {initialAmount: noMoney, totalMoney: noMoney};
      }

      return {
        initialAmount: {
          amount: allocatedLineItem.item.amountMoney.amount * item.quantity,
          currency: allocatedLineItem.item.amountMoney.currency,
        },
        totalMoney: {
          amount: allocatedLineItem.item.amountMoney.amount * item.quantity,
          currency: allocatedLineItem.item.amountMoney.currency,
        },
      };
    })
    .reduce(
      (total, item) => ({
        initialAmount: {
          amount: total.initialAmount.amount + item.initialAmount.amount,
          currency: total.initialAmount.currency,
        },
        totalMoney: {
          amount: total.totalMoney.amount + item.totalMoney.amount,
          currency: total.totalMoney.currency,
        },
      }),
      {
        initialAmount: {
          amount: 0,
          currency: firstLineItem.totalMoney.currency,
        },
        totalMoney: {
          amount: 0,
          currency: firstLineItem.totalMoney.currency,
        },
      }
    );
};

// get a customers item allocations when using split by item
export const getAllocation = (
  customerId: string | undefined,
  groupPayment: GroupPaymentRecord
): AllocatedItem[] => {
  if (
    customerId &&
    groupPayment.splitType === 'BY_ITEM' &&
    groupPayment.itemAllocation &&
    groupPayment.itemAllocation[customerId]
  ) {
    return groupPayment.itemAllocation[customerId] ?? [];
  }
  return [];
};

export const calcSharePrice = (
  groupPayment: GroupPaymentRecord,
  customerId: string | undefined
) => {
  const {splitAllocation} = groupPayment;

  switch (groupPayment.splitType) {
    case 'EQUAL': {
      if (!splitAllocation) {
        return undefined;
      }

      const initialAmount = convertAmountPerPerson(
        splitAllocation,
        groupPayment.amountMoney
      );

      const totalMoney = convertAmountPerPerson(
        splitAllocation,
        groupPayment.totalMoney
      );

      return {
        initialAmount,
        totalMoney,
      };
    }

    case 'BY_ITEM':
      if (!groupPayment.lineItems || !customerId) {
        return undefined;
      }

      return calculateAllocatedAmount(
        groupPayment.lineItems,
        getAllocation(customerId, groupPayment)
      );

    default:
      return undefined;
  }
};
