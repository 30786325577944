import React, {FC, useEffect} from 'react';
import {QueryClient, useQueryClient} from '@tanstack/react-query';

interface PrefetchWrapperProps {
  prefetch: (queryClient: QueryClient) => Promise<void>;
}

const PrefetchWrapper: FC<React.PropsWithChildren<PrefetchWrapperProps>> = ({
  prefetch,
  children,
}) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    const promise = (async () => {
      await prefetch(queryClient);
    })();

    return () => {
      promise.catch((err) =>
        console.error('failed to prefetch functions', err)
      );
    };
  }, [queryClient]);

  return <>{children}</>;
};

export default PrefetchWrapper;
