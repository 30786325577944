import {useQueryClient} from '@tanstack/react-query';
import {Checkout} from '../../@types/updated-api-types/checkouts/Checkout';

const useCheckout = (checkoutId: string | undefined): Checkout | undefined => {
  const queryClient = useQueryClient();
  const checkoutRecord = queryClient.getQueryData<Checkout>([
    'checkout',
    checkoutId,
  ]);
  return checkoutRecord;
};

export const useCheckoutPublicDetails = (
  checkoutId: string | undefined
): Checkout | undefined => {
  const queryClient = useQueryClient();
  const checkoutPublicDetails = queryClient.getQueryData<Checkout>([
    'checkout-public',
    checkoutId,
  ]);
  return checkoutPublicDetails;
};

export default useCheckout;
