import Dialog, {DialogProps} from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import {TransitionProps} from '@mui/material/transitions';
import React, {FC} from 'react';

export interface AnimatedDialogProps extends DialogProps {
  open: boolean;
  handleClose?: () => void;
}

const SlideUp = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>
  ) => (
    <Slide direction="up" ref={ref} {...props}>
      {props.children}
    </Slide>
  )
);

SlideUp.displayName = 'Transition';

const AnimatedDialog: FC<React.PropsWithChildren<AnimatedDialogProps>> = ({
  open,
  handleClose,
  children,
  ...props
}) => (
  <Dialog
    open={open}
    TransitionComponent={SlideUp}
    onClose={handleClose}
    fullWidth
    {...props}
  >
    {children}
  </Dialog>
);

export default AnimatedDialog;
