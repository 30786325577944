import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, {FC} from 'react';

interface SplitTitleProps {
  title: string;
  subTitle: string;
}

const SplitTitle: FC<React.PropsWithChildren<SplitTitleProps>> = ({
  title,
  subTitle,
  children,
}) => (
  <Grid container>
    <Grid item xs={12}>
      <Grid container alignItems="flex-start" spacing={1}>
        <Grid item xs="auto">
          <Typography variant="h4">{title}</Typography>
        </Grid>
        <Grid item xs="auto">
          {children}
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12}>
      <Typography variant="subtitle1" sx={{fontWeight: 500}} gutterBottom>
        {subTitle}
      </Typography>
    </Grid>
  </Grid>
);

export default SplitTitle;
