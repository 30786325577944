import {useContext} from 'react';
import {
  MerchantStyleContext,
  MerchantStyleContextType,
} from '../providers/MerchantStyleProvider';

export const useMerchantStyle = (): MerchantStyleContextType | undefined => {
  const merchantStyleContext = useContext(MerchantStyleContext);

  return merchantStyleContext;
};
