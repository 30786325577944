import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import {MoneyUtils} from '@handsin/money';
import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import type {GroupPaymentRecord} from '@local/backend/@types/updated-api-types/group-payments/GroupPaymentRecord';
import {Customer} from '@local/frontend/@types/updated-api-types/customer/Customer';
import {useGroupPaymentCustomerShares} from '@local/frontend/hooks/queries/group-payments';
import useCustomerShareAmount from '@local/frontend/hooks/useCustomerShareAmount';
import ParticipantAvatar from '../../../atoms/avatars/ParticipantAvatar';

interface EqualMemberListItemProps {
  customer: Customer;
  groupPayment: GroupPaymentRecord;
  handleCustomerSelection: (customer: Customer) => void;
  isCustomerSelected: boolean;
}

const EqualMemberListItem: FC<
  React.PropsWithChildren<EqualMemberListItemProps>
> = ({customer, groupPayment, handleCustomerSelection, isCustomerSelected}) => {
  const {t} = useTranslation(['glossary']);
  const participantName = `${customer.firstName} ${customer.lastName ?? ''}`;

  const {data: groupPaymentCustomerSharesMap} = useGroupPaymentCustomerShares(
    groupPayment.id
  );

  const customerShareAmount = useCustomerShareAmount(
    customer.id,
    groupPaymentCustomerSharesMap
  );

  const sharePrice = customerShareAmount
    ? MoneyUtils.formatMoney(customerShareAmount)
    : undefined;

  return (
    <ListItem dense disableGutters>
      <ListItemButton
        onClick={() => handleCustomerSelection(customer)}
        selected={isCustomerSelected}
      >
        <ListItemAvatar sx={{mr: 1}}>
          <ParticipantAvatar
            firstName={customer.firstName}
            lastName={customer.lastName}
          />
        </ListItemAvatar>
        <ListItemText
          primary={participantName}
          secondary={
            sharePrice
              ? `${t('shareAmount', {ns: 'glossary'})}: ${sharePrice}`
              : undefined
          }
        />
      </ListItemButton>
    </ListItem>
  );
};

export default EqualMemberListItem;
