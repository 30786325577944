import Button from '@mui/material/Button';
import React, {FC} from 'react';
import {Controller, useFormContext, useWatch} from 'react-hook-form';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import Popper, {PopperProps} from '@mui/material/Popper';
import _ from 'lodash';
import {alpha, useTheme} from '@mui/material/styles';
import {useTranslation} from 'react-i18next';
import CountryFlag from '../../CountryFlag';
import CountrySelect from '../CountrySelect';
import {getSupportedLanguages} from '../LanguageSelect/getSupportedLanguages';
import LanguageSelect from '../LanguageSelect/LanguageSelect';

interface LocalePopperProps extends PopperProps {
  handleClose: () => void;
}

const LocalePopper: FC<React.PropsWithChildren<LocalePopperProps>> = ({
  open,
  anchorEl,
  handleClose,
  ...props
}) => {
  const supportedLanguagesMap = getSupportedLanguages();
  const formMethods = useFormContext();
  const {t} = useTranslation(['country-language-popper']);

  const phoneNumberCountry = useWatch({
    control: formMethods.control,
    name: 'phoneNumberCountry',
  });

  return (
    <Popper
      transition
      open={open}
      anchorEl={anchorEl}
      {...props}
      sx={{zIndex: 1300, width: 300, fontFamily: 'inherit', ...props.sx}}
    >
      {({TransitionProps}) => (
        <ClickAwayListener onClickAway={handleClose}>
          <Fade {...TransitionProps} timeout={350}>
            <Box sx={{borderRadius: 2, p: 2, bgcolor: 'white'}}>
              <Stack spacing={2}>
                <Controller
                  name="country"
                  control={formMethods.control}
                  render={({field}) => (
                    <CountrySelect
                      {...field}
                      defaultValue={phoneNumberCountry}
                      label={t('countrySelect.label', {
                        ns: 'country-language-popper',
                      })}
                    />
                  )}
                />
                {!_.isEmpty(supportedLanguagesMap) && (
                  <Controller
                    name="language"
                    control={formMethods.control}
                    render={({field}) => (
                      <LanguageSelect
                        {...field}
                        label={t('languageSelect.label', {
                          ns: 'country-language-popper',
                        })}
                      />
                    )}
                  />
                )}
              </Stack>
            </Box>
          </Fade>
        </ClickAwayListener>
      )}
    </Popper>
  );
};

const LocaleSelect: FC = () => {
  const formMethods = useFormContext();
  const theme = useTheme();

  const currentLanguage = useWatch({
    control: formMethods.control,
    name: 'language',
  });

  const currentCountry = useWatch({
    control: formMethods.control,
    name: 'country',
  });

  const [popperAnchorEl, setPopperAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const handleTogglePopper = (event: React.MouseEvent<HTMLElement>) => {
    setPopperAnchorEl(popperAnchorEl ? null : event.currentTarget);
  };

  return (
    <>
      <Button
        variant="text"
        onClick={handleTogglePopper}
        endIcon={<ArrowDropDownIcon />}
        sx={{
          backgroundColor: alpha(theme.palette.grey[500], 0.12),
          fontFamily: 'inherit',
        }}
      >
        <Stack spacing={1} direction="row" alignItems="center">
          {currentCountry && <CountryFlag country={currentCountry} />}
          <Typography fontSize="inherit" noWrap>
            {currentLanguage}
          </Typography>
        </Stack>
      </Button>

      <LocalePopper
        open={Boolean(popperAnchorEl)}
        anchorEl={popperAnchorEl}
        placement="top-end"
        handleClose={() => setPopperAnchorEl(null)}
      />
    </>
  );
};

export default LocaleSelect;
