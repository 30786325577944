import type {GroupPaymentRecord} from '@local/backend/@types/updated-api-types/group-payments/GroupPaymentRecord';
import {useQueryClient} from '@tanstack/react-query';

export const useGroupPaymentPublicDetails = (
  groupPaymentId: string | undefined
): GroupPaymentRecord | undefined => {
  const queryClient = useQueryClient();
  const groupPaymentPublicDetails =
    queryClient.getQueryData<GroupPaymentRecord>([
      'groupPayment-public',
      groupPaymentId,
    ]);
  return groupPaymentPublicDetails;
};

const useGroupPayment = (
  groupPaymentId: string | undefined
): GroupPaymentRecord | undefined => {
  const queryClient = useQueryClient();
  const groupPaymentRecord = queryClient.getQueryData<GroupPaymentRecord>([
    'groupPayment',
    groupPaymentId,
  ]);
  return groupPaymentRecord;
};

export default useGroupPayment;
