import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import {useQuery} from '@tanstack/react-query';
import React, {FC} from 'react';
import {NotificationQueryData} from '../helpers/notifications/@types';
import {useNotification} from '../hooks/useNotification';

const NotificationProvider: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const {data: alert} = useQuery<NotificationQueryData>(
    ['notificationAlert'],
    () => ({
      isShowing: false,
    }),
    {
      refetchOnWindowFocus: false,
    }
  );

  const {close: closeNotification} = useNotification();

  return (
    <>
      {children}
      <Snackbar
        open={Boolean(alert?.isShowing && alert?.message)}
        autoHideDuration={alert?.timeToShow}
        onClose={(_e, reason) => {
          if (reason !== 'clickaway') {
            closeNotification();
          }
        }}
        sx={{zIndex: 10000, boxShadow: 24}}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
      >
        <Alert
          onClose={closeNotification}
          severity={alert?.severity}
          sx={{width: '100%'}}
        >
          {alert?.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default NotificationProvider;
