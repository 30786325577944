import {useContext} from 'react';
import {SbiContext} from '../providers/SbiProvider';
import {SplitByItemContext} from '../providers/@types/SplitByItemContext';

const useSbiContext = (): SplitByItemContext => {
  const sbiContext = useContext<SplitByItemContext | undefined>(SbiContext);

  if (!sbiContext) {
    throw new Error(
      'No SBI context. hook must be used within SBI context provider'
    );
  }

  return sbiContext;
};

export default useSbiContext;
