import axios from 'axios';

export const BACKEND_CONFIG = {
  HOST: process.env.REACT_APP_BACKEND_HOST ?? 'http://localhost:5000',
  URL:
    process.env.REACT_APP_BACKEND_URL ??
    (process.env.NODE_ENV === 'production' ? '/api' : '/proxy/api'),
};

const backendClient = axios.create({baseURL: BACKEND_CONFIG.URL});

export default backendClient;
