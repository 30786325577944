import React, {FC} from 'react';
import Grid from '@mui/material/Grid';
import {Controller, useForm} from 'react-hook-form';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import {styled} from '@mui/material/styles';
import DoneIcon from '@mui/icons-material/Done';
import {yupResolver} from '@hookform/resolvers/yup';
import {useTranslation} from 'react-i18next';
import LoadingButton from '@local/frontend/components/atoms/buttons/LoadingButton';
import {useNotification} from '@local/frontend/hooks/useNotification';
import getCustomerFullNameSchema from '@local/frontend/validators/customer-full-name-schema';
import * as Yup from 'yup';
import {theme} from '@hands-in/theme';
import {AddCustomerFormValues} from './@types/AddCustomerFormValues';

const StyledEditButton = styled(LoadingButton)({
  backgroundColor: `${theme.palette.grey[300]}`,
  padding: '0px',
  minWidth: '30px',
  color: `${theme.palette.primary.main}`,
  height: '100%',
  '&:hover': {
    color: '#FFF',
  },
});

interface EditNameProps {
  handleDiscard: () => void;
  handleApply: (formValues: AddCustomerFormValues) => Promise<void>;
  firstName: string | undefined;
  lastName: string | undefined;
  isLoading?: boolean;
}

const EditName: FC<React.PropsWithChildren<EditNameProps>> = ({
  handleDiscard,
  handleApply,
  firstName,
  lastName,
  isLoading,
}) => {
  const {t} = useTranslation(['common']);
  const customerFullNameSchema = getCustomerFullNameSchema(t);
  const {open: openNotification} = useNotification();
  const {
    formState: {isDirty, isValid},
    ...formMethods
  } = useForm<Yup.InferType<typeof customerFullNameSchema>>({
    mode: 'all',
    resolver: yupResolver(customerFullNameSchema),
    defaultValues: {
      firstName: firstName ?? '',
      lastName: lastName ?? '',
    },
  });

  const handleSubmit = async (formValues: AddCustomerFormValues) => {
    await handleApply(formValues).catch((err) =>
      openNotification({
        message:
          err.message ?? 'Sorry, something went wrong when trying to do this',
        severity: 'error',
      })
    );
  };

  return (
    <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Controller
            name="firstName"
            control={formMethods.control}
            render={({field}) => (
              <TextField
                {...field}
                label={t('form.firstName.label', {ns: 'common'})}
                placeholder={t('form.firstName.label', {ns: 'common'})}
                required
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="lastName"
            control={formMethods.control}
            render={({field}) => (
              <TextField
                {...field}
                label={t('form.lastName.label', {ns: 'common'})}
                placeholder={t('form.lastName.label', {ns: 'common'})}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item xs="auto">
              <StyledEditButton
                variant="contained"
                onClick={handleDiscard}
                progressProps={{size: 12}}
              >
                <CloseIcon sx={{fontSize: '18px'}} />
              </StyledEditButton>
            </Grid>
            <Grid item xs="auto">
              <StyledEditButton
                variant="contained"
                type="submit"
                loading={isLoading}
                disabled={!isValid || !isDirty}
                progressProps={{size: 12}}
              >
                <DoneIcon sx={{fontSize: '18px'}} />
              </StyledEditButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default EditName;
