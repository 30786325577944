import React, {FC} from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ListItemText from '@mui/material/ListItemText';
import {ListItem, Stack} from '@mui/material';
import Badge from '@mui/material/Badge';
import {MoneyUtils} from '@handsin/money';
import {useTranslation} from 'react-i18next';
import {useCustomModals} from '@local/frontend/libs/modals/useCustomModals';
import {ModalName} from '@local/frontend/libs/modals/ModalName';
import {LineItem} from '../../../@types/updated-api-types/items/LineItem';
import ItemAvatar from '../../atoms/avatars/ItemAvatar';
import useMerchant from '../../../hooks/data/merchants';

interface LineItemSummaryProps {
  lineItem: LineItem;
}

const LineItemSummary: FC<React.PropsWithChildren<LineItemSummaryProps>> = ({
  lineItem,
}) => {
  const {t} = useTranslation(['line-item-summary', 'glossary']);
  const {openModal} = useCustomModals();
  const merchant = useMerchant();

  const handleOpenItemInfo = () => {
    openModal(ModalName.LINE_ITEM_INFO, {lineItem, merchant});
  };

  return (
    <ListItem
      disableGutters
      onClick={() => {
        handleOpenItemInfo();
      }}
      sx={{
        borderRadius: 2,
        p: 1,
        cursor: 'pointer',
        '&:hover': {
          background: '#E7EAF3',
        },
      }}
    >
      <Stack direction="row" spacing={2} sx={{pt: 1}}>
        <Badge badgeContent={lineItem.quantity} color="primary">
          <ItemAvatar lineItem={lineItem} merchant={merchant} />
        </Badge>
        <Grid container>
          <Grid item xs={12}>
            <ListItemText
              sx={{mt: 0}}
              primary={
                <Typography variant="body2">{lineItem.item.name}</Typography>
              }
              secondary={
                <Typography variant="body2" sx={{fontWeight: 500}}>
                  {MoneyUtils.formatMoney(lineItem.totalMoney)}{' '}
                  {lineItem.quantity > 1 && (
                    <Typography variant="caption" sx={{fontWeight: 300}}>
                      ({MoneyUtils.formatMoney(lineItem.item.amountMoney)}{' '}
                      {t('each', {ns: 'glossary'})})
                    </Typography>
                  )}
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="caption"
              sx={{fontWeight: 400, color: 'primary.dark', mr: 1}}
            >
              {t('quantity', {ns: 'glossary'})}:
            </Typography>
            <Typography variant="caption" sx={{fontWeight: 500}}>
              {t('quantityAmount', {
                ns: 'line-item-summary',
                count: lineItem.quantity,
              })}
            </Typography>
          </Grid>
        </Grid>
      </Stack>
    </ListItem>
  );
};

export default LineItemSummary;
