import {useMutation, useQueryClient} from '@tanstack/react-query';
import {Customer} from '../../../@types/updated-api-types/customer/Customer';
import {
  createCustomer,
  updateCustomer,
} from '../../../libs/api/customer.actions';

export const useCreateCustomer = () => useMutation(createCustomer);

export const useUpdateCustomer = () => {
  const queryClient = useQueryClient();
  return useMutation(updateCustomer, {
    onMutate: async ({customerId}) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(['customer', customerId]);
      // Snapshot the previous value
      const previousCustomer = queryClient.getQueryData<Customer>([
        'customer',
        customerId,
      ]);
      // Return a context object with the snapshotted value
      return {previousCustomer};
    },
    onSuccess: async (customer) => {
      await queryClient.invalidateQueries(['customer', customer.id]);
    },
  });
};
