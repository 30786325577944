import Grid from '@mui/material/Grid';
import React, {FC} from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import NoticeAlert from '../components/atoms/NoticeAlert';
import LocaleSelectButton from '../components/atoms/buttons/LocaleSelectButton';
import CountryContextProvider from '../providers/CountryContextProvider';

interface ErrorPageProps {
  errorMessage: string;
}

const ErrorPage: FC<React.PropsWithChildren<ErrorPageProps>> = ({
  errorMessage,
}) => (
  <CountryContextProvider>
    <Container maxWidth="xl">
      <Box sx={{display: 'flex', justifyContent: 'flex-end', pt: 2}}>
        <LocaleSelectButton />
      </Box>
      <Grid container alignItems="center" justifyContent="center">
        <NoticeAlert message={errorMessage} severity="error" />
      </Grid>
    </Container>
  </CountryContextProvider>
);

export default ErrorPage;
