import {useEffect, useState} from 'react';

interface UseDateTimeReturnType {
  currentDateTime: Date;
}

export const useDateTime = (): UseDateTimeReturnType => {
  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 500);

    return () => clearInterval(timer);
  }, [setCurrentDateTime]);

  return {currentDateTime};
};
