import {AvatarProps} from '@mui/material';
import React, {FC} from 'react';
import Avatar from '@mui/material/Avatar';
import {StyledImageBackground} from '../../../sections/groupCheckout/SplitSection/ItemSplit/styles';

const MerchantAvatar: FC<React.PropsWithChildren<AvatarProps>> = ({
  sx,
  src,
  ...props
}) => (
  <Avatar
    sx={{
      borderRadius: 2,
      width: '85px',
      height: '85px',
      position: 'relative',
      ...sx,
    }}
    {...props}
  >
    <StyledImageBackground src={src} />
    <img
      src={src}
      style={{
        width: '100%',
        height: '100%',
        objectFit: 'contain',
        zIndex: 1,
      }}
      alt={props.alt}
    />
  </Avatar>
);

export default MerchantAvatar;
