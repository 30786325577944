import {QueryClient} from '@tanstack/react-query';
import {AlertColor} from '@mui/material/Alert';
import {NotifcationQueryParams} from './@types';

const getDefaultTimeToShow = (severity: AlertColor): number => {
  switch (severity) {
    case 'error':
      return 8000;
    case 'success':
    case 'info':
    case 'warning':
    default:
      return 5000;
  }
};

export const closeNotification = (queryClient: QueryClient) => {
  queryClient.setQueryData(['notificationAlert'], {
    isShowing: false,
  });
};

export const openNotification = (
  queryClient: QueryClient,
  {
    message,
    severity,
    timeToShow = getDefaultTimeToShow(severity),
  }: NotifcationQueryParams
): void => {
  queryClient.setQueryData(['notificationAlert'], {
    isShowing: true,
    message,
    severity,
    timeToShow,
  });
};
