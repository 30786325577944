import Button from '@mui/material/Button';
import React, {Dispatch, FC, SetStateAction, useState} from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {useTranslation} from 'react-i18next';
import ItemsSummaryMenu from '../../menus/ItemsSummaryMenu';
import {Customer} from '../../../../@types/updated-api-types/customer/Customer';

interface LineItemSummarySelectorProps {
  selectedCustomerId: string | undefined;
  setSelectedCustomerId: Dispatch<SetStateAction<string | undefined>>;
  chosenCustomer: Customer | undefined;
  currentUserId: string | undefined;
}

const LineItemSummarySelector: FC<
  React.PropsWithChildren<LineItemSummarySelectorProps>
> = ({
  selectedCustomerId,
  setSelectedCustomerId,
  chosenCustomer,
  currentUserId,
}) => {
  const {t} = useTranslation(['glossary']);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isSummaryMenuOpen = Boolean(anchorEl);

  const handleOpenSummaryMenu = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseSummaryMenu = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Button
        variant="contained"
        endIcon={<KeyboardArrowDownIcon />}
        sx={{ml: 1}}
        onClick={handleOpenSummaryMenu}
      >
        {chosenCustomer
          ? `${chosenCustomer.firstName}'s ${t('item_other', {ns: 'glossary'})}`
          : t('groupOrder', {ns: 'glossary'})}
      </Button>
      <ItemsSummaryMenu
        isSummaryMenuOpen={isSummaryMenuOpen}
        anchorEl={anchorEl}
        selectedCustomerId={selectedCustomerId}
        handleCloseSummaryMenu={handleCloseSummaryMenu}
        setSelectedCustomerId={setSelectedCustomerId}
        currentUserId={currentUserId}
      />
    </>
  );
};

export default LineItemSummarySelector;
