import {QueryKey, useQueryClient} from '@tanstack/react-query';
import {PaymentRecord} from '../../@types/updated-api-types/payments/PaymentRecord';

export const usePayment = (paymentId: string | undefined) => {
  const queryClient = useQueryClient();
  const paymentRecord = queryClient.getQueryData<PaymentRecord | undefined>([
    'payment',
    paymentId,
  ]);
  return paymentRecord;
};

export const usePayments = (
  paymentIds: string[]
): [QueryKey, PaymentRecord | undefined][] => {
  const queryClient = useQueryClient();
  const paymentRecords = paymentIds
    .map((paymentId) =>
      queryClient.getQueriesData<PaymentRecord>(['payment', paymentId])
    )
    .flat();
  return paymentRecords;
};
