import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import React, {FC} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import Rating from '@mui/material/Rating';
import {useSearchParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import {useInitialiseGroupPaymentPublicDetails} from '@local/frontend/hooks/queries/group-payments';
import {useNotification} from '@local/frontend/hooks/useNotification';
import {useCustomModals} from '@local/frontend/libs/modals/useCustomModals';
import {ModalName} from '@local/frontend/libs/modals/ModalName';
import * as Yup from 'yup';
import {FeedbackService} from '../../../hooks/mutations/@types/feedback';
import {useCreateFeedback} from '../../../hooks/mutations/feedback';
import getFeedbackSchema from '../../../validators/feedbackSchema';
import CustomTooltip from '../../atoms/CustomTooltip';
import LoadingButton from '../../atoms/buttons/LoadingButton';
import {Customer} from '../../../@types/updated-api-types/customer/Customer';
import {formatFullName} from '../../../util/stringFormatters';
import {useRelevantCheckoutIdsMap} from '../../../hooks/data/ids';
import useGroupPayment from '../../../hooks/data/group-payments';

interface FeedbackFormProps {
  customer: Customer;
}

const FeedbackForm: FC<React.PropsWithChildren<FeedbackFormProps>> = ({
  customer,
}) => {
  const {t} = useTranslation(['feedback-form']);
  const [searchParams] = useSearchParams();
  const merchantId = searchParams.get('mid') ?? undefined;
  const {open: openNotification} = useNotification();
  const {closeModal, getModal} = useCustomModals();
  const relevantCheckoutIds = useRelevantCheckoutIdsMap();
  const groupPayment = useGroupPayment(relevantCheckoutIds?.groupPaymentId);
  const {
    data: groupPaymentPublicDetails,
    isInitialLoading: isGroupPaymentPublicDetailsLoading,
  } = useInitialiseGroupPaymentPublicDetails(
    relevantCheckoutIds?.groupPaymentId,
    merchantId
  );
  const feedbackMutation = useCreateFeedback();

  const feedbackSchema = getFeedbackSchema(t);

  const {
    formState: {isValid, isDirty},
    ...formMethods
  } = useForm<Yup.InferType<typeof feedbackSchema>>({
    mode: 'all',
    resolver: yupResolver(feedbackSchema),
    defaultValues: {
      satisfactionRating: undefined,
      review: '',
    },
  });

  const isFormValid = isDirty && isValid;

  const handleFeedbackSubmit = (
    feedbackFormValues: Yup.InferType<typeof feedbackSchema>
  ) => {
    const {satisfactionRating, review} = feedbackFormValues;
    if (!satisfactionRating || !isFormValid) {
      throw new Error('feedback form is not valid');
    }

    const groupPaymentId = groupPayment?.id || groupPaymentPublicDetails?.id;

    if (!groupPaymentId) {
      openNotification({
        message: t('errors.noGroup', {ns: 'feedback-form'}),
        severity: 'error',
      });
      throw new Error('No group payment found to submit feedback for');
    }

    feedbackMutation.mutate(
      {
        referenceId: `FHC_${JSON.stringify(relevantCheckoutIds)}`,
        satisfactionRating,
        review,
        customerId: customer.id,
        source: FeedbackService.GROUP_PAYMENTS,
        email: customer.email ?? '',
        name: formatFullName(customer.firstName, customer.lastName),
      },
      {
        onSuccess: () => {
          const feedbackModal = getModal(ModalName.FEEDBACK_MODAL);
          if (feedbackModal && feedbackModal.props.open) {
            closeModal(ModalName.FEEDBACK_MODAL);
          }

          openNotification({
            message: t('alerts.submit', {ns: 'feedback-form'}),
            severity: 'success',
          });
        },
        onError: () => {
          openNotification({
            message: t('errors.failedToSubmit', {ns: 'feedback-form'}),
            severity: 'error',
          });
        },
      }
    );
  };

  if (isGroupPaymentPublicDetailsLoading) {
    return (
      <Box sx={{minHeight: '120px', display: 'flex', alignItems: 'center'}}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <form
      onSubmit={formMethods.handleSubmit(handleFeedbackSubmit)}
      style={{width: '100%'}}
    >
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={10} md={8}>
          <Grid container justifyContent="center">
            <Controller
              name="satisfactionRating"
              control={formMethods.control}
              render={({field}) => (
                <Rating
                  {...field}
                  size="large"
                  onChange={(_, value) => field.onChange(value)}
                  precision={0.5}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={10} md={8}>
          <Controller
            name="review"
            control={formMethods.control}
            render={({field}) => (
              <TextField
                {...field}
                placeholder={t('body.additionalComments', {
                  ns: 'feedback-form',
                })}
                multiline
                hiddenLabel
                minRows={4}
                maxRows={10}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={8} sm={6} md={5}>
          <CustomTooltip
            enabled={!isFormValid}
            title={t('body.tooltipTitle', {ns: 'feedback-form'})}
            style={{width: '100%'}}
          >
            <LoadingButton
              type="submit"
              variant="contained"
              disabled={!isFormValid || feedbackMutation.isLoading}
              loading={feedbackMutation.isLoading}
              fullWidth
            >
              {t('button.text', {ns: 'feedback-form'})}
            </LoadingButton>
          </CustomTooltip>
        </Grid>
      </Grid>
    </form>
  );
};

export default FeedbackForm;
