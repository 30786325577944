import {AxiosResponse} from 'axios';
import {CreateFeedbackMutationParams} from '../../hooks/mutations/@types/feedback';
import axios from '../../config/backend';
import {FeedbackRecord} from '../../@types/updated-api-types/feedback/FeedbackRecord';

export const getFeedback = async (referenceId: string, customerId: string) => {
  const res: AxiosResponse<FeedbackRecord> = await axios.get(
    `/feedback/${referenceId}/${customerId}`
  );
  return res.data;
};

export const createFeedback = async (
  feedbackCreationParams: CreateFeedbackMutationParams
) => {
  const res: AxiosResponse<FeedbackRecord> = await axios.post(
    '/feedback',
    feedbackCreationParams
  );
  return res.data;
};
